.catalog-product-view{
  .in-the-box{
    .in-the-box-inner{
      .box-detail{
        font-family:Oswald-Light;
      }
      .box-detail-links{
        margin-top:1rem;
        
        li{
          font-family:Oswald-Light;
        }
      }
    }
  }
}
