.contacts-index-index{
    #re_captcha{
        margin-left: 240px;
    }
}
@include breakpoint($bp_medium_legacy){
    .contacts-index-index{
        #re_captcha{
            margin-left: 0;
        }
    }

}
