$cart-label-font-size: .9rem;

.checkout-cart-index{
  .col1-layout{
    
    .col-main{
      .cart{
        .messages li.error-msg ul{text-align:center;} 
        .page-title h1{font-family:Oswald-Light;}
        .cart-collaterals h2{font-family:Oswald-Light;}
        .form-list .input-box{float:none;}
        
        .totals{
          width:25%;
          box-sizing:border-box;
          -moz-box-sizing:border-box;
          padding-right:0;
          padding-left:0;
        }
        .cart-collaterals{
          width:75%;
          box-sizing:border-box;
          -moz-box-sizing:border-box;
          
          .deals{
            width:50%;
            margin:0;
            
            .input-text{
              height:18px;
              font-size:$cart-label-font-size;
            }
          }
          .shipping{
            width:50%;
            margin:0;
            
            p{
              font-size:$cart-label-font-size;
            }
            select{
              font-size:$cart-label-font-size;
            }
            .validation-advice{
              font-size:$cart-label-font-size;
            }
            
            #shipping-zip-form{
              label{padding-left:20px;}
              .input-box{
                background-position:left top;
                padding-right:0;
                padding-left:20px;
                
                input{
                  font-size:$cart-label-font-size;
                }
              }
            }
          }
        }
        
      }
    }
  }
  
  .page{
    .cart{
      button.button{
        height: 30px;
        line-height:30px !important;
        background-color: #00ccff;
        text-shadow: none !important;
        border-radius:0;
        padding: 0 1.5rem;
        text-decoration: none;
        font-weight: normal;
    
        @include transition(background-color .3s linear);
        
        &:hover{
          background-color:#00A0C8;
        }
      
        > span{
          padding: 0;
          height: inherit;
          background-color: transparent;
          display:block;
          line-height:inherit;
          text-decoration:inherit;
          font-weight:inherit;
          
          > span{
            padding: 0;
            height: inherit;
            font-family: Oswald-Light;
            font-size:1rem;
            letter-spacing: 1px;
            background-color: transparent;
            display:block;
            line-height:inherit;
            color: #fff;
            text-decoration:inherit;
            font-weight:inherit;
            text-transform:uppercase;
          }
        }
      }
      button.button.btn-continue,
      button.button.btn-update{
        background-color:transparent;
          > span{
            > span{
              font-size:.8rem;
              font-family:Oswald;
              text-decoration:underline;
              color:#333;
              
              @include transition(color .3s linear);
              
              &:hover{
                color:#00A0C8;
              }
            }
          }
      }
      button.button.btn-proceed-checkout{
        padding:.5rem 3rem;
        height:auto;
        
          > span{
            > span{
              font-size:1.3rem;
            }
          }
      }
      #discount-coupon-form,#giftcard-form{
        label{
          font-size:$cart-label-font-size;
        }
        button.button{
          height: 30px;
          line-height:30px;
          padding:0 1.1rem;
        
          > span{
            > span{
              font-size:.9rem;
            }
          }
        }
        
        button.button.check-gc-status{
          margin-top:1rem;
          background-color:transparent;
          padding:0;
  
          > span{
            > span{
              text-transform:uppercase;
              color:#333;
              text-decoration:underline;
              font-family:Oswald;
              
              @include transition(color .3s linear);
              
              &:hover{
                color:#00A0C8;
              }
            }
          }
        }
      }
    }
  }
}

@include breakpoint($bp_largest){
  .checkout-cart-index{
    .page .cart #giftcard-form button.button.check-gc-status{float:left;}
    .col1-layout{
      padding-left: .8rem;
      padding-right: .8rem;
      width: 100%;
      
      .col-main{
        .cart{
          
          .totals{
            width:50%;
            float:right;
          }
          .cart-collaterals{
            width:50%;
            float:left;
            
            .deals{
              width:100%;
            }
            .shipping{
              width:100%;
            }
          }
          
        }
      }
    }
  }
}
@include breakpoint($bp_medium_legacy){
  .checkout-cart-index{
    .cart .title-buttons .checkout-types{
      text-align:center;
      
      > li{float:none;}
    }
    .col1-layout .col-main .cart .page-title h1{text-align:center;float:none;}
    #shopping-cart-table input.qty{text-align:center;}
    .data-table .item-options dt{list-style:none;background-image:none;}
    .cart .data-table td{text-align:center !important;}
    #shopping-cart-table{
      width:100%;
    }
    
    .col1-layout{
      .col-main{
        .cart{
          .data-table tfoot{
            text-align:center;
            
            button{
              float: none;
              display: block;
              margin: 1rem auto;
              
            }
          }
          .totals{
              width:100%;
              padding: .7rem;
              
              .checkout-types li{
                text-align:center;
              }
          }
          .cart-collaterals{
            width:100%;
          }
        }
      }
    }
  }
}
@include breakpoint($bp_smallest){
  .checkout-cart-index{
    .page{
      .col-main{
        .cart{
          
          button.button.btn-update{
              padding:.2rem .5rem;
              height: auto;
              
              > span{
                > span{
                  font-size:.7rem;
                }
              }
          }
          
          button.button.btn-proceed-checkout{
            padding:.5rem 1rem;
          
            > span{
              > span{
                font-size:1rem;
              }
            }
          }
          
          #giftcard-form{
            button.button.check-gc-status{
    
              > span{
                > span{
                  font-size:.6rem;
                }
              }
            }
          }
          
        }
      }
    }
  }
}