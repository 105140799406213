/**
 * Jam Audio CSS
 *
 * @package     enterprise_default
 */
/* Reset ================================================================================= */
* { margin:0; padding:0; }

body          { background:#fff; font-family: 'Open Sans', sans-serif; font-weight: 300; color:#333;width:100%; }

img           { border:0; vertical-align:top; }

a             { color: #00ADEF; text-decoration: none; transition: color 125ms ease-in 0s; }
a:hover       { text-decoration: underline; transition: color 225ms ease-out 0s; }
:focus        { outline: 0; }
.container    { width: 960px; margin: 0 auto; }

/* Headings */
h1            { font-size:2.5em; font-weight:300; margin-bottom: .5em; line-height: 1.1em;  font-family: Oswald-light; }
h2            { font-size:2.0em; font-weight:300; margin-bottom: .5em;}
h3            { font-size:36px; line-height: 1.25em; font-weight:300; margin-bottom: .3em; color: #00adef; }
h4            { font-size:1.1em; font-weight:bold; margin-bottom: .3em; }
h5            { font-size:1em; font-weight:bold; }
h6            { font-size:1em; font-weight:bold; }
.cms-page-view h2 { font-size: 22px; }

/* Forms */
form          { display:inline; }
fieldset      { border:0; }
legend        { display:none; }

/* Table */
table         { border:0; border-collapse:collapse; border-spacing:0; empty-cells:show; font-size:100%; }
caption,th,td { vertical-align:top; text-align:left; font-weight:normal; }

/* Content */
address       { font-style:normal; }
cite          { font-style:normal; }
q,
blockquote    { quotes:none; }
q:before,
q:after       { content:''; }
small         { font-size:10px; }
big           { font-size:1.2em; }

/* Lists */
ul,ol         { list-style:none; }

/* Tools */
.hidden       { display:block !important; border:0 !important; margin:0 !important; padding:0 !important; font-size:0 !important; line-height:0 !important; width:0 !important; height:0 !important; overflow:hidden !important; }
.nobr         { white-space:nowrap !important; }
.wrap         { white-space:normal !important; }
.a-left       { text-align:left !important; }
.a-center     { text-align:center !important; }
.a-right      { text-align:right !important; }
.v-top        { vertical-align:top; }
.v-middle     { vertical-align:middle; }
.f-left,
.left         { float:left !important; }
.f-right,
.right        { float:right !important; }
.f-none       { float:none !important; }
.f-fix        { float:left; width:100%; }
.no-display   { display:none !important; }
.no-margin    { margin:0 !important; }
.no-padding   { padding:0 !important; }
.no-bg        { background:none !important; }
/* ======================================================================================= */

/* Layout ================================================================================ */
.wrapper { background:#fff; text-align:left; }
.page { width: 100%; margin:0 auto; }
.page-print { background:#fff;  }
.page-popup { padding:20px; text-align:left; @include borderbox(); }
.catalog-product-compare-index.page-popup{overflow-x:auto;overflow:auto;width:100%;}
.page-empty { background:#fff; padding:20px; text-align:left; }
.main { min-height:350px;  position: relative; }
.sidebar { min-height:350px; }

/* Base Columns */
.col-left { float:left; width:24%; padding: 0 0 2em 0; margin: 0; }
.col-main { float:left; width:74%; padding: 0 0 2em 0; margin: 0; }
.col-right { float:right; width: 24%; padding: 0 0 2em 0; margin: 0; }

/* 1 Column Layout */
.col1-layout { width: 975px; margin: 0 auto; }
.col1-layout .col-main { float:none; width: 100%; }
.col1-layout .breadcrumbs {  }

/* 2 Columns Layout */
.col2-left-layout { width: 975px; margin: 0 auto; }
.col2-left-layout .col-main { float:right; }
.col2-right-layout { width: 975px; margin: 0 auto; }
.col2-right-layout .col-main { }

/* 3 Columns Layout */
.col3-layout { width: 975px; margin: 0 auto; }
.col3-layout .col-main { width:70%; margin-left:1px; display:inline; padding-bottom: 3em; }
.col3-layout .col-wrapper { width: 75%; float:left;  }
.col3-layout .col-wrapper .col-main { float:right; }

/* Content Columns */
.col2-set .col-1 { float:left; width:49%; }
.col2-set .col-2 { float:right; width:49%; }
.col2-set .narrow { width:33%; }
.col2-set .wide { width:65%; }

.col3-set .col-1 { float:left; width:32%; }
.col3-set .col-2 { float:left; width:32%; margin-left:2%; }
.col3-set .col-3 { float:right; width:32%; }

.col4-set .col-1 { float:left; width:23.5%; }
.col4-set .col-2 { float:left; width:23.5%; margin:0 2%; }
.col4-set .col-3 { float:left; width:23.5%; }
.col4-set .col-4 { float:right; width:23.5%; }

/* Page sections - Custom for Jam */
.cms-index-index .col1-layout, .cms-jam-home-vday .col1-layout { width: inherit; margin: 0; }
.catalog-category-view .col1-layout { width: inherit; margin: 0; }
.catalog-product-view .col1-layout { width: inherit; margin: 0; }



.section-container { width: 100%; position: relative; overflow: hidden; padding: 4em 0; clear: both; z-index: 999;   }
.section-content { width: 975px; margin: 0 auto; min-height: 300px; }
.section-content .one-half { width: 50%; }
.section-content .one-third { width: 33.33%; }
.section-content .two-thirds { width: 66.66%; }
.on-light { color: #444; }
.on-dark { color: #fff; }
.outline { outline: 1px solid #ccc; }

/* ======================================================================================= */


/* Global Styles ========================================================================= */

/* Form Elements */
input,select,textarea,button { /* font:12px Arial, Helvetica, sans-serif; */ vertical-align:middle; color:#444; }
input.input-text,select,textarea { background:#fff; border:1px solid #c7c7c7; background:#fff url(../images/bkg_input.gif) 0 0 repeat-x; }
input.input-text,textarea { padding:5px; background:#fff url(../images/bkg_input.gif) 0 0 repeat-x; }
select { padding:1px; }
select option { padding-right:10px; }
select.multiselect option { padding:2px 2px; border-bottom:1px solid #bbb; }
select.multiselect option:last-child { border-top:0; }
textarea { overflow:auto; }
input.radio,
input.checkbox { margin-right:3px; margin-top:4px; vertical-align:top; }
input.radio { }
input.qty { width:2.5em; }
button.button::-moz-focus-inner { padding:0; border:0; } /* FF Fix */
button.button { -webkit-border-fit:lines; } /* <- Safari & Google Chrome Fix */
button.button { border:0; padding:0; margin:0; background:transparent; overflow:visible; cursor:pointer; vertical-align:middle; }

.catalog-product-compare-index button.button{
  display:inline-block;padding:10px;height:auto;background-color:#00ADEF;line-height:0;border-radius:0;
  span{
    display:block;height:auto;padding:0;margin:0;background-color:transparent;line-height:0;
    span{
      display:block;height:auto;padding:0;margin:0;background-color:transparent;line-height:20px;text-shadow:none;font-family:$font1_light;font-size:1.1rem;font-weight:normal;letter-spacing:0;
    }
  }
}

.catalog-product-compare-index.page-popup tr .price-box{
  display:block;
  p.old-price, p.special-price{position:relative;padding:0;height:auto;}
  p.old-price{}
  p.special-price{}
}

a.button,
button.button span,
a.button span,
button.button span span { background-color:$jam_lightblue; background-image: none; background-repeat:no-repeat; }

a.button,
button.button span { border:0; padding:0 0 0 10px; margin:0; cursor:pointer; background-position:0 0; display:-moz-inline-stack; display:inline-block; color:#c73615; height:21px; text-decoration:none; }
a.button span,
button.button span span { padding:3px 15px 1px 5px; color:#fff; background-position:100% 0; display:-moz-inline-block; display:inline-block; white-space:nowrap; /* font:bold 11px/20px Arial, Helvetica, sans-serif; */ height:20px; }
a.button { line-height:21px; }
a.button { line-height:20px; }
.button:active { bottom:-1px; right:-1px; position:relative; z-index:2; }
button.button:active span span { margin:0; }
button.btn-small span { font-size:11px; line-height:14px; padding:1px 9px 0; }
button.disabled {}
button.disabled span {}

/* Form Validation */
.validation-advice { clear:both; font-size:11px !important; padding:3px 0 0 !important; color:#d91a00 !important; }
.product-options .validation-error .options-list,
.validation-failed { border:1px solid #f47d4e !important; background:#fff7f5 !important; }
input.input-text.validation-failed,
textarea.validation-failed { background:#fff7f5 url(../images/bkg_input-failed.gif) 0 0 repeat-x !important; }
span.validation-error,
div.validation-error { background:url(../images/icon_error.gif) 100% 2px no-repeat; padding-right:20px; }
.required em { color:#f15c22; margin-right:4px; }
p.required { margin:0; padding:5px 0 7px; text-align:right; width:620px; font-size:11px; color:#f02800; }
.form-list .validation-advice { width:250px; margin-right:-250px; line-height: 1.5em; }
/* Expiration date and CVV number validation fix */
.v-fix { float:left; }
.v-fix .validation-advice { display:block; width:12em; margin-right:-12em; position:relative; }

/* Fieldset */
.fieldset { font-size:.9em; margin:0 0 16px; }
.fieldset p { margin:0 0 1.5em; }
.fieldset h2.legend { margin:0 0 1.5em; color:#000; }

/* Form lists */
.form-list {}

.form-list li { margin:0 0 9px; vertical-align:top; }
.form-list label { float:left; width:225px; padding:2px 15px 0 0; font-weight:bold; color:#444; text-align:right; }
.form-list .value { float:left; padding:2px 0 0; }
.form-list .note { display:inline-block; padding-left: 110px; font-size:13px; }
.form-list .buttons-set { display:inline-block; padding-left: 10px;  }
.form-list span.label { font-weight:normal; }
.form-list .input-box { float:left; /* width:256px; */ }
.form-list .input-box a { font-size:11px; margin-top:4px; }
.form-list input.input-text,
.form-list textarea { float:left; width:350px; vertical-align:top; font-size: 1rem; }
.form-list textarea { width:350px; height:13em; }
.form-list select { float:left; width:363px; vertical-align:top; font-size:1rem; }
.form-list p { margin:0; padding:0 0 0 240px; }
ul.add-row,
.form-list li.add-row { background:url(../images/bkg_dots.gif) repeat-x 0 0; margin-top:15px; }
ul.add-row .btn-remove,
.form-list li.add-row .btn-remove { font-size:0; line-height:0; padding:5px 5px 0 0; margin:0; text-align:right; }
.form-list .range input.input-text { float:none; width:64px; vertical-align:middle; }

.form-list .add-field .input-box { margin-left:240px }
.form-list .control { position:relative; vertical-align:top; z-index:1; } /* row with checkbox or radio button */
.form-list .control label { line-height:16px; }
.form-list .control .input-box { position:absolute; left:0; margin-left:240px; line-height:16px; padding-top:1px; }
.form-list .control input.radio { margin-top:3px; }

.form-alt label { float:none; display:inline; width:auto; }
.form-alt li { margin:0 0 5px; line-height:16px; }
.form-alt select { width:496px; }
.form-alt .input-box { width:496px; margin-top:5px; padding-bottom:11px; }
.form-alt .input-text { width:480px; }

.inner-form { margin:10px 0 5px; padding:10px 0 0; border:1px dashed #d1d1d1; border-width:1px; background:#f7f7f7; }
.inner-form .form-alt label { padding-left:5px; }

/* Date Of Birth */
.form-list .customer-dob label { float:none; width:auto; padding:0; font-size:11px; font-weight:normal; color:#777; }
.form-list .customer-dob .input-box { float:left; width:auto; margin-right:8px; }
.form-list .customer-dob .input-box .validation-advice { display:none !important; }
.form-list .customer-dob input.input-text { float:none; display:block; margin-bottom:2px; }
.form-list .customer-dob .dob-day,
.form-list .customer-dob .dob-month,
.form-list .customer-dob .dob-year { float:left; margin-right:8px; padding-right:0 !important; background:none !important; }
.form-list .customer-dob .dob-day .validation-advice,
.form-list .customer-dob .dob-month .validation-advice,
.form-list .customer-dob .dob-year .validation-advice { display:none; }
.form-list .customer-dob .dob-day input.input-text { width:104px; }
.form-list .customer-dob .dob-month input.input-text { width:104px; }
.form-list .customer-dob .dob-year { margin-right:0; }
.form-list .customer-dob .dob-year input.input-text { width:138px; }

/* Combination of fields */
.form-list li.fields { margin:0; }
.form-list li.fields .field { clear:left; padding:0 0 9px; }

/* Buttons */
.buttons-set { clear:both; margin:15px 0; } /* general for button containing block */
.buttons-set p.back-link { float:left; padding:2px 15px 0 0; text-align:right; margin:0; }
a.back-link { color:#999; }
.buttons-set p.back-link a { color:#999; }
.buttons-set a.button,
.buttons-set button.button { float:right; margin-left:7px; }
.catalog-product-compare-index .buttons-set button.button{float:left; margin-right:7px;margin-left:0;}

.catalog-product-compare-index table#product_comparison tbody:last-child{display:none;}

.buttons-set2 { clear:both; margin:5px 0 0; padding:5px; background-color:#ededed; text-align:right; }

.form-buttons { margin:0; width:620px; } /* for button containing block under the general form */
.form-buttons .button { float:right; }
.form-buttons .button { margin-left:15px; }
.form-buttons p.back-link { float:left; width:225px; padding:2px 15px 0 0; text-align:right; }
.btn-only { /* padding-left:240px; */ }

/* Overrides for 3columns */
.col3-layout .form-list .note { padding-left:165px; width:280px; }
.col3-layout .form-buttons p.back-link,
.col3-layout .form-list label { width:150px; }
.col3-layout .form-list input.input-text,
.col3-layout .form-list textarea { width:274px; }
.col3-layout .form-list select { width:280px; }
.col3-layout .form-list .control .input-box { margin-left:165px; }
.col3-layout .form-list .customer-dob .dob-day input.input-text { width:74px; }
.col3-layout .form-list .customer-dob .dob-month input.input-text { width:74px; }
.col3-layout .form-list .customer-dob .dob-year { margin-right:0; }
.col3-layout .form-list .customer-dob .dob-year input.input-text { width:98px; }
.col3-layout p.required,
.col3-layout .form-buttons { width:445px; }


/* std class reset */
.std .messages { margin-top:0; }
.std .messages { width:auto; list-style:none !important; padding:0 !important; margin-bottom:0 !important; }
.std .messages ul { list-style:none !important; padding-left:17px !important; margin:0 0 -11px !important; }

/* BreadCrumbs */
.breadcrumbs { margin:0 auto; padding:5px 0; text-align:left; width: auto; border-bottom: 1px solid #ccc; }
.breadcrumbs li { display:inline; }
.breadcrumbs li span,
.breadcrumbs a { color:#888; text-decoration:none; }
.breadcrumbs a:hover {  }

/* Page Heading */
.page-title { padding:2em 0 1em; }
.page-title .link-rss { float:right; margin-top:8px; }
.page-title h1,
.page-title h2 { }

.catalog-product-compare-index .page-title{padding-top:0;}

.title-buttons { text-align:right; }
.title-buttons h1,
.title-buttons h2,
.title-buttons h3,
.title-buttons h4,
.title-buttons h5,
.title-buttons h6 { float:left; }
.title-buttons .link-print { float:right; margin-top:4px; }

.subtitle { clear:both; padding:10px 0; font-size:18px; color:#d12c01; }

.page-popup .page-title,
.col-main .page-title {  }
.col1-layout .col-main .page-title {  }

.sub-title { font-size:1.3em; font-weight:normal; color:#000; line-height:19px; margin:0 0 1em;font-family: Oswald-Light; }
.sub-title .separator { font-weight:normal; }
.sub-title a { font-size:1rem; font-weight:normal; text-transform:lowercase; }
.section-title { font-size:14px; font-weight:normal; color:#151515; margin:0 0 5px; }

/* Pager */
.pager { margin: 5px 0; font-size: .9em; padding:7px 20px; background:#f5f5f5 url(../images/bkg_pager.gif) repeat-x 0 0; border:1px solid #ddd; color:#333; }
.pager p { margin:0 !important; }
.pager select { padding:0; margin-bottom:1px; }
.pager .amount { float:left; line-height:20px; }
.pager .amount strong { font-weight:normal; }
.pager .limiter { float:right; line-height:20px; }
.pager .pages { margin:0 135px; text-align:center; color:#333; line-height:20px; }
.pager .pages a { color:#8c8c8c; text-decoration:underline; }
.pager .pages a:hover { color:#8c8c8c; }
.pager .pages ol,
.pager .pages li { display:inline; padding:0 2px; }
.pager .pages .current { font-weight:bold; }
.pager .pages .i-previous,
.pager .pages .i-next { text-decoration:none !important; vertical-align:middle; }
.pager-only { clear:both; }
.pager-only .pages { margin:0; }

/* Sorter */
.sorter { font-size:11px; margin:0 0 0; padding:3px 20px; background:#f5f5f5 url(../images/bkg_pager.gif) repeat-x 0 0; border-top:1px solid #ddd; color:#333; }
.sorter p { margin:0; }
.sorter select { padding:0; margin-bottom:1px; }
.sorter .view-mode { float:left; line-height:20px; white-space:nowrap; }
.sorter .view-mode label { float:left; margin:0 5px 0 0; }
.sorter .view-mode a.list-fix,
.sorter .view-mode a.list,
.sorter .view-mode strong.list-fix,
.sorter .view-mode strong.list,
.sorter .view-mode a.grid-fix,
.sorter .view-mode a.grid,
.sorter .view-mode strong.grid-fix,
.sorter .view-mode strong.grid { background:url(../images/bkg_viewmode.gif) no-repeat 0 0; float:left; width:24px; height:17px; text-align:left; text-indent:-999em; overflow:hidden; margin:2px 0 0; }
.sorter .view-mode a.list { background-position:-24px 0; }
.sorter .view-mode strong.list { background-position:-24px -29px; }
.sorter .view-mode a.grid { background-position:0 0; }
.sorter .view-mode strong.grid { background-position:0 -29px; }

.sorter .view-mode a.list-fix { background-position:0 -58px; }
.sorter .view-mode strong.list-fix { background-position:0 -87px; }
.sorter .view-mode a.grid-fix { background-position:-24px -58px; }
.sorter .view-mode strong.grid-fix { background-position:-24px -87px;; }

.sorter .sort-by { float:right; line-height:20px; }
.sorter .link-feed {}

/* Toolbar */
.toolbar { padding:0; margin: 0 auto; width: 975px; }
.toolbar .pager { margin:0 0 0; border-width:1px 0; }
.toolbar-bottom { display:none; }

/* Data Table */
.table-caption { border:1px solid #ddd; border-bottom:0; margin:-1px 0 0; background:#fff url(../images/bkg_block-title.gif) 0 100% repeat-x; font:14px/1.25 Arial, Helvetica, sans-serif; padding:3px 7px 4px; color:#444; }
.table-caption a { font-size:11px; text-transform:lowercase; }
.data-table { border:1px solid #d9d9d9; border-bottom:0; width:100%; border-collapse:separate; }
.data-table th { padding:5px 12px; color:#2d2d2d; border-bottom:1px solid #d9d9d9; font-weight:normal; text-transform:uppercase; white-space:nowrap; }
.data-table td { padding:5px 12px; }
.data-table thead { background:#e8e8e8 url(../images/bkg_thead.gif) repeat-x 0 0; font-size:11px; }
.data-table tbody {}
.data-table tfoot { background-color:#ededed; }
.data-table tfoot td { padding:5px 12px; }
.data-table tfoot th { border:none; text-transform:none; }
.data-table tr.first {}
.data-table tr.last {}
.data-table tr.last th,
.data-table tr.last td { border-bottom:1px solid #d9d9d9; }
.data-table tr.odd { background-color:#fff; }
.data-table tr.even { background-color:#fafafa; }
.data-table tbody.odd {}
.data-table tbody.odd td { border-width:0 1px; }
.data-table tbody.even { background-color:#f5f5f5; }
.data-table tbody.even td { border-width:0 1px; }
.data-table tbody.odd tr.border td,
.data-table tbody.even tr.border td { border-bottom-width:1px; }
.data-table th .tax-flag { white-space:nowrap; font-weight:normal; }
.data-table td.label,
.data-table th.label { font-weight:bold; text-transform:none; border-color:#fff; background-color:#f7f7f7; }
.data-table td.value { float:none !important; }
.data-table .link-rss { display:block; }

.page-popup .data-table td,
.page-popup .data-table th { border-bottom:1px solid #d9d9d9; }
.page-popup .buttons-set .button { float:right; }
.catalog-product-compare-index.page-popup .buttons-set .button { float:left; }

.catalog-product-compare-index.page-popup .data-table td{position:relative;padding-bottom:60px;}
.catalog-product-compare-index.page-popup tr.last p,
.catalog-product-compare-index.page-popup tr.first p { margin:0;display:block;padding:10px 0 10px 12px;position:absolute;left:0;right:0;bottom:0;margin:auto;height:40px;text-align:left;}

/* Shopping cart total summary row expandable to details */
tr.summary-total { cursor:pointer; }
tr.summary-total td {}
tr.summary-total .summary-collapse { float:right; text-align:right; padding-left:20px; background:url(../images/bkg_collapse.gif) 0 4px no-repeat; cursor:pointer; }
tr.show-details .summary-collapse { background-position:0 -53px; }
tr.show-details td {}
tr.summary-details td { background-color:#ede9e6; font-size:12px; padding:5px !important; }
tr.summary-details-first td {}
tr.summary-details-excluded { font-style:italic; }

/* Shopping cart tax info */
.cart-tax-info,
.cart-tax-info .cart-price { display:block; padding-right:20px; }
.cart-tax-total { display:block; padding-right:20px; background:url(../images/bkg_collapse.gif) 100% 3px no-repeat; cursor:pointer; }
.cart-tax-total-expanded { background-position:100% -54px; }

/* Links */
ul.links,
ul.links li { float: right; }
ul.links li .separator { margin:0 2px 0 5px; font-size:10px; color:#8f8f8f; }
ul.links li.last .separator { display:none; }

/* Reward Points */
.rewards { margin:-10px 0 20px; }
.reward-message { display:block; margin:5px -15px 5px -15px; background:#d55721 url(../images/bkg_rewards.gif) 5px 3px no-repeat; padding:3px 5px 3px 32px; font:normal 12px/20px Arial, Helvetica, sans-serif; color:#fff; }
.reward-message p { display:inline; margin:0 !important; }
.reward-message .limit { display:block; font-size:11px; line-height:1.15; }
.reward-message a { color:#ffe6dc; text-decoration:underline; }
.reward-message a:hover { color:#ffe6dc; }
.reward-message .price { font-size:1em; font-weight:normal; }
/* Reward Points types rewrites */
.reward-review,
.reward-tag,
.reward-checkout { margin:0; }

/* Class: std - styles for admin-controlled content */
.std ul,
.std ol,
.std dl,
.std p,
.std address,
.std blockquote,
.std table { margin-bottom:1em; }
.std ul { list-style:disc outside; padding-left:1.5em; }
.std ol { list-style:decimal outside; padding-left:1.5em; }
.std ul ul { list-style-type:circle; }
.std ul ul,
.std ol ol,
.std ul ol,
.std ol ul { margin:.5em 0; }
.std dt { font-weight:bold; }
.std dd { padding:0 0 0 1.5em; }
.std blockquote { font-style:italic; padding:0 0 0 1.5em; }
.std address { font-style:normal; }
.std b,
.std strong { font-weight:bold; }
.std i,
.std em { font-style:italic; }
.std hr { margin:1.5em 0; color:#d9d9d9; }
.std table { width:100%; }
.std table thead { background:#e8e8e8; }
.std table th { background:#e8e8e8; font-weight:bold; }
.std table th,
.std table td { padding:5px 10px; border:1px solid #d9d9d9; }

ul.disc li { padding-left:10px; background:url(../images/bkg_bulletsm.gif) no-repeat 0 0.5em; }
.std ul.disc li { padding-left:0; background:none; }

/* Misc */
.link-rss { background:url(../images/icon_rss.gif) 100% 1px no-repeat; padding-right:18px; white-space:nowrap; }
.link-rss:hover { color:#f58550; }
.link-print { background:url(../images/icon_printer.gif) 0 1px no-repeat; padding:2px 0 2px 25px; white-space:nowrap; }
.link-cart { font-size:11px; font-weight:bold; color:#c73615; text-decoration:underline; }
.link-cart:hover { color:#c73615; text-decoration:none; }

.data-table .btn-remove { display:block; margin:0 auto; width:13px; height:12px; font-size:0; line-height:0; background:url(../images/btn_remove.gif) 0 0 no-repeat; text-indent:-999em; overflow:hidden; }
.data-table .btn-remove2 {}

.divider { margin:16px 0; background:#e7e7e7; font-size:0; line-height:0; height:1px; overflow:hidden; }

/* Noscript Notice */
.noscript { border:1px solid #cfcfcf; border-width:0 0 1px; background:#ffff90; font-size:12px; line-height:1.25; text-align:center; color:#2f2f2f; }
.noscript .noscript-inner { width:940px; margin:0 auto; padding:12px 0 12px; background:url(../images/i_notice.gif) 20px 50% no-repeat; }
.noscript p { margin:0; }

/* Demo Notice */
.demo-notice { margin:0; padding:6px 10px; background:#d75f07; font-size:12px; line-height:1.15; text-align:center; color:#fff; }
/* ======================================================================================= */


/* Header ================================================================================ */
.branding {  height:56px; width: 200px; float: left; padding-top: 15px; }
.logo { float:left; }

.header-container { margin:0; text-align:left; font-size: 80%; background: #000; }
.header { padding:8px 0 9px; position:relative; z-index:5; width:960px; margin: 0 auto; }
.header .logo { text-decoration:none !important; }
.header .logo strong { position:absolute; top:-999em; left:-999em; width:0; height:0; font-size:0; line-height:0; text-indent:-999em; overflow:hidden; }
.header h1.logo {}
.header .logo img { height: 46px; }
.header .quick-access { width:60px; float:right;  }
.header .quick-access .links { float:right; white-space:nowrap; font-weight: normal; margin-top: -10px; }
.header .quick-access .links li { padding:0 15px 0 0; background:url(../images/bkg_sep.gif) no-repeat 100% 4px; }
.header .quick-access a { color:#fff; text-transform:uppercase; transition: color 125ms ease-in 0s; }
.header .quick-access a:hover { color: $jam_lightblue; transition: color 225ms ease-out 0s; }

.header .quick-access .checkout-link li { font-weight:bold; padding:0 0 0 12px; background-position:6px 4px; }
.header .quick-access .cart-link li { padding:0 0 0 0px; background:none; font-family: Oswald; }

.header .quick-access #mini-cart a { color:#333; text-transform:uppercase; transition: color 125ms ease-in 0s; font-family: Oswald; font-weight: normal;}
.header .quick-access #mini-cart a:hover { color : #00CCFB; transition: color 225ms ease-out 0s; }

#social_sidebar { background: $jam_lightblue; width: 55px; height: 170px; position: absolute; right: 0; z-index: 9; }
#social_sidebar ul { list-style-type: none; padding: 10px 0 0 10px; }
#social_sidebar ul li { margin-bottom: 15px; }

.topActions { /*width: 290px;*/ float: right; overflow: visible; padding-top: 6px; }
ul.links li a { color: #fff; float: left; margin-right: 15px; }
ul.links li a:hover { color: #00ADEF; text-decoration: none; }


#invitation-send-link { background:url(../images/bkg_sep.gif) no-repeat 100% 50%; padding:0 6px 0 0; margin:0 4px 0 0; font-weight:bold; }
#invitation-send-link a { background:url(../images/bkg_invitation-link.gif) no-repeat 3px 6px; padding-left:23px; display:inline-block; }

#invitation-send-link a, x:-moz-any-link { background-position:3px -2px; }/* FireFox 2 fix */
#invitation-send-link a, x:-moz-any-link, x:default { background-position:3px 6px; }/* FireFox 3 restore */

.header-panel { z-index:994; background:#fff url(../images/bkg_header-panel-bg.gif) repeat-x 0 100%; padding:5px 0; }
.header-panel-contents { width: 975px; margin: 0 auto; }
.header-panel .welcome-msg { display:inline; float:right; font-weight: bold; font-weight: 400; /* background: url(../images/bkg_box.png) no-repeat center; */}
.header-panel .welcome-msg a { color: #3F3F3F; text-decoration: underline; font-weight: 300;  transition: color 125ms ease-in 0s; }
.header-panel .welcome-msg a:hover { color : #A9E900; transition: color 225ms ease-out 0s; }

.header-panel .links { float:left; margin-right:11px; white-space:nowrap; }
.header-panel .links li a { color:#1e75b4; }
.header-panel .links li a:hover { color:#d33911; }

img.desktopLogo { display: block !important; }
img.responsiveLogo { display: none !important; }

.switches { float:right; background:#fff url(../images/bkg_header-panel-bg.gif) repeat-x 0 100%; padding:0 0 1px 0; }

.switch { line-height:28px; margin-left:-1px; margin-right:0; display:inline; float:left; background:url(../images/bkg_header-panel.gif) no-repeat 0 -29px; padding-left:13px; }
.switch label { font-weight:normal; margin-right:0; float:left; }
.switch .switch-wrapper { float:left; position:relative; z-index:1; padding-left:7px; padding-right:11px; }
.switch strong.current { font-weight:normal; }
.switch .separator { margin:0 2px; font-size:10px; color:#8f8f8f; }
.switch .switcher { color:#1e75b4; cursor:pointer; background:url(../images/bkg_header-panel.gif) no-repeat 100% -73px; padding:0 10px 0 0; margin:0 3px 0 1px; }
.switch ul { position:absolute; min-width:100%; text-align:left; top:0; left:0; float:left; line-height:16px; padding:0 0 0 0; border-top:0; }
.switch ul li { vertical-align:top; display:block; white-space:nowrap; border:1px solid #c8c8c8; border-top:0; }
.switch ul li a { white-space:nowrap; display:block; background:$jam_lightgray; padding:4px 6px; color:#1e75b4; text-decoration:none; }
.switch ul li a:hover { color:#d33911; background:#f8f8f8; }
.switch ul .current { line-height:28px; border:0; cursor:default; padding:0 7px; visibility:hidden; /* background:url(../images/bkg_header-panel.gif) repeat-x 0 0; */ }
.switch ul .current span { padding-right:20px; background:url(../images/bkg_close.gif) no-repeat 100% 1px; }
.switch .faded { color:#8f8f8f; }
.switch .faded li { border-color:#e6e6e6; background-color:#f6f6f6; }
.switch .faded a { color:#9ac1dd; }

.switch ul, x:-moz-any-link { width:100%; }/* FireFox 2 fix */
.switch ul, x:-moz-any-link, x:default { width:auto; }/* FireFox 3 restore */

.list-opened { background-position:100% -129px !important; color:#d33911 !important; }


/* Block: Events */
.event { background-color:#fbf4de; border-bottom:1px solid #e2d4c7; width:182px; position:static; height:144px; text-align:center; }
.event .status { font-weight:normal; font-size:16px; display:block; padding:19px 0 0; margin:0 0 0; text-align:center; color:#cf3a00; }
.event .event-ticker { color:#cf3a00; height:55px; }
.event .event-ticker span { font-size:32px; line-height:40px; height:40px; padding-bottom:10px; background-image:url(../images/bkg_ticker.gif); background-repeat:no-repeat; display:inline-block; }

.event .event-ticker span, x:-moz-any-link { padding-bottom:13px; }/* FireFox 2 fix */
.event .event-ticker span, x:-moz-any-link, x:default { padding-bottom:10px; }/* FireFox 3 restore */

.event .event-ticker span.days { background-position:50% 3px; }
.event .event-ticker span.hour { background-position:50% -56px; }
.event .event-ticker span.minute { background-position:50% -115px; }
.event .event-ticker span.second { background-position:50% -174px; }
.event .event-ticker span.delimiter { background:none; }
.event .event-dates { font-size:14px; color:#555; height:51px; padding-top:4px; }
.event .event-dates .date-part { font-weight:bold; }
.event .event-dates .time-part { font-size:12px; }

.col-main .event .event-button { background:url(../images/bkg_btn-co.gif) 0 50% no-repeat; line-height:27px !important; height:27px !important; padding:0 0 0 13px !important; vertical-align:middle; }
.col-main .event .event-button span { width:121px; text-align:center; background:url(../images/bkg_btn-co.gif) 100% 50% no-repeat; padding:0 13px 0 0 !important; color:#fff; font:bold 15px/27px Arial, Helvetica, sans-serif !important; height:27px !important; text-transform:none; }

.event p a { color:#666; font-size:11px; }

.event .event-bottom { background:url(../images/bkg_slider-events.gif) no-repeat 50% 0; position:absolute; z-index:2; height:12px; width:23px; left:50%; margin-left:-11px; bottom:-12px; }

.open .event-button span {}

.upcoming { background:#f6f6f6; }
.upcoming .status { color:#333; }
.closed .event-button,
.col-main .closed .event-button,
.col-main .upcoming .event-button,
.upcoming .event-button,
.closed .event-button span,
.col-main .closed .event-button span,
.col-main .upcoming .event-button span,
.upcoming .event-button span { line-height:27px !important; font-size:12px !important; color:#7c7b7b !important; background:none !important; padding:0 !important; width:auto !important; display:inline !important; text-transform:capitalize; }
.upcoming .event-button { text-decoration:underline !important; }
.upcoming .event-button:hover { text-decoration:none !important; }
.upcoming p a { color:#666; }
.upcoming .event-bottom { background-position:50% -187px; }

.closed { background:#494949; }
.closed .status,
.closed .event-dates { color:#e2d4c7 }
.closed .event-button {}

.event-container { clear:both; border:1px solid #d0af83; position:relative; z-index:2; border-width:1px 1px; margin-top:7px; margin-bottom:-5px; height:59px; }
.event-container .event { background-color:transparent; background-repeat:no-repeat; background-position:50% 100%; padding-bottom:18px; margin-bottom:-18px; padding-top:5px; height:52px; line-height:50px; text-align:center; width:auto; position:relative; z-index:2; border-bottom:0 !important; }
.event-container .container { display:inline; margin:0; padding:0; background:none; }
.event-container .status { display:inline; margin:0 10px 0 0; background:none; font-size:22px; }
.event-container .event-ticker { display:inline; font-size:28px; height:auto; }
.event-container .event-ticker span { line-height:50px; height:50px; padding:0; }

.event-container .event-ticker span , x:-moz-any-link { padding:5px 0 5px; }/* FireFox 2 fix */
.event-container .event-ticker span , x:-moz-any-link, x:default { padding:0; }/* FireFox 3 restore */

.event-container .event .event-ticker span.days { background-position:50% 4px; }
.event-container .event .event-ticker span.hour { background-position:50% -55px; }
.event-container .event .event-ticker span.minute { background-position:50% -114px; }
.event-container .event .event-ticker span.second { background-position:50% -173px; }

.event-container .event-dates { display:inline; font-size:22px; height:auto; }
.event-container .event-dates .time-part { font-size:14px; }
.event-container .event-dates div,
.event-container .event-dates p { display:inline; }

.container-open { background:#f7d4b6 url(../images/bkg_event-container-open.gif) repeat-x 0 0; }
.container-open .open { background-image:url(../images/bkg_event-container-open.gif); padding-top:3px; height:54px; }

.container-upcoming { border-color:#d0d0d0; background:#eeeeee url(../images/bkg_event-container-upcoming.gif) repeat-x 0 0; }
.container-upcoming .upcoming { background-image:url(../images/bkg_event-container-upcoming.gif); }

.container-closed { background:#9f958c }

/* Block: Events Lister */
.col3-layout .col-main .widget-events { margin:0 -20px 1px; position:relative; z-index:1; }
.widget-events { padding:11px; background:#f0eae3; }
.col1-layout .col-main .widget-events { padding:12px; }
.events-slider { position:relative; clear:both; margin:0 0 0; border:1px solid #d0c2b6; z-index:2; }
.events-slider .slider-panel,
.events-slider ul.slider li.slider-item,
.events-slider .slide-button { float:left; display:inline; }
.events-slider .event { position:relative; z-index:1; }

.events-slider .slider-panel { position:relative; overflow:hidden; background:#d0c2b6; z-index:1; width:0; }
.events-slider ul.slider { width:200000px; -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=1)" !important; }
.events-slider ul.slider li.slider-item { overflow:hidden; width:182px; height:386px; border-right:1px solid #d0c2b6; background:#f5f5f5 url(../images/bkg_block.gif) repeat-x 0 0; padding-left:0; }
.events-slider ul.slider li.slider-item .category-name { font-size:16px; font-weight:normal; color:#454343; display:block; padding:80px 10px 0; }
.events-slider ul.slider li.slider-item .category-show { width:182px; height:241px; background:#fff url(../images/bkg_category-info.gif) repeat-x 0 100%; overflow:hidden; text-align:center; display:block; text-decoration:none; }
.events-slider ul.slider li.slider-item .no-event { height:401px; }

.events-slider .slide-button { background:url(../images/bkg_slide-button.gif) no-repeat 0 0; position:absolute; text-indent:-999em; cursor:pointer; top:17px; width:28px; height:28px; z-index:2; overflow:hidden; }
.events-slider .backward { left:-11px; }
.events-slider .forward { right:-11px; background-position:0 -40px; }
.events-slider .inactive { cursor:default; display:none; }

.sidebar .widget-events { background:none; padding:0; }
.sidebar .events-slider { border:1px solid #cfcfcf; margin:-1px -1px 0; background:#fff; padding:0; }
.sidebar .events-slider .event { height:115px; }
.sidebar .events-slider ul.slider li.slider-item { width:182px; height:300px; margin:0; border-color:#cfcfcf; border-width:0 1px 0 0; }
.sidebar .events-slider ul.slider .status { padding-top:10px; padding-bottom:5px; }
.sidebar .events-slider ul.slider .event-dates { height:40px; }
.sidebar .events-slider ul.slider li.slider-item .category-name { font-size:16px; font-weight:normal; color:#454343; display:block; padding:50px 10px 0; }
.sidebar .events-slider ul.slider li.slider-item .category-show { height:241px; background:#fff url(../images/bkg_category-info.gif) repeat-x 0 100%; }

.sidebar .events-slider { margin-top:-1px; }
.sidebar .events-slider .backward { left:3px; top:6px; }
.sidebar .events-slider .forward { right:3px; top:6px; }

.sidebar .events-slider .event-ticker { height:45px; }
.sidebar .events-slider .event-ticker span { font-size:22px; line-height:28px; height:28px; padding-bottom:10px; background-image:url(../images/bkg_ticker1.gif); background-repeat:no-repeat; display:inline-block; }
.sidebar .events-slider .event-ticker span.days { background-position:50% -10px; }
.sidebar .events-slider .event-ticker span.hour { background-position:50% -69px; }
.sidebar .events-slider .event-ticker span.minute { background-position:50% -128px; }
.sidebar .events-slider .event-ticker span.second { background-position:50% -185px; }

/* Sidebar =============================================================================== */
.sidebar .callout { display:block; margin:10px 0; }

/* Generic Block */
.sidebar .block { border-bottom:1px solid #cfcfcf; background:#f5f5f5 url(../images/bkg_block.gif) repeat-x 0 0; margin-bottom: 2em; }
.sidebar .block .block-title { padding:17px 17px 0 17px; }
.sidebar .block .block-title strong { display:block; font:14px/1.25 Arial, Helvetica, sans-serif; padding:0; color:#222; }
.sidebar .block .block-title strong span {}
.sidebar .block .block-title strong small { font-size:12px; color:#777; }
.sidebar .block .block-subtitle,
.sidebar .block h3 { font-size:1em; font-weight:bold; color:#555; }
.sidebar .block .block-content { padding:10px 17px 17px; color:#555; }
.sidebar .block .btn-remove { color:#222; margin-right:3px; }
.sidebar .block .btn-remove a { color:#222 !important; text-decoration:none !important; }
.sidebar .block .actions { margin:8px 0 0; text-align:right; }
.sidebar .block .actions a { float:left; color:#999; margin-top:3px; }
.sidebar .block .actions button.button { float:right; }

.sidebar .block li a.btn-remove { float:right; width:13px; height:0; padding-top:12px; margin-top:3px; overflow:hidden; background:url(../images/btn_remove.gif) no-repeat 0 0; }

.col-main .block { background:#fff; }
.col-main .block .block-title { background:#e7e7e7 url(../images/bkg_block-title.gif) repeat-x 0 0; border:1px solid #e1e1e1; padding:5px 20px 5px; margin:0 -1px; }
.col-main .block .block-title h2,
.col-main .block .block-title h3 { font-size:14px; line-height:1.2; color:#000333; font-weight:bold; }
.col-main .block .block-content { padding:20px; }

/* Generic box */
.info-box { border:1px solid #cfcfcf; margin-top:-1px; background:#f5f5f5 url(../images/bkg_block.gif) repeat-x 0 0; position:relative; z-index:1; }
.info-box .box-title { margin:17px 17px 0 17px; font:14px/1.25 Arial, Helvetica, sans-serif; padding:0; color:#222; }
.info-box .box-title a { font-size:1rem; text-transform:lowercase; font-family:Oswald-Light; }
.info-box .box-content { padding:10px 17px 17px; color:#555; }

.info-table th { font-weight:bold; padding:2px 15px 2px 0; }
.info-table td { padding:2px 0; }

/* Block: Account */
.sidebar .block-account { background:#f5f5f5 url(../images/bkg_block.gif) repeat-x 0 0; }
.sidebar .block-account .block-title { padding:26px 17px 12px; border-bottom:1px solid #d1d1d1; }
.sidebar .block-account .block-title strong { font-size:18px; line-height:23px; color:#111;  font-family: Oswald; }
.sidebar .block-account .block-content { padding:0; font-family:Oswald-Light; }
.sidebar .block-account li { background:url(../images/bkg_dots.gif) repeat-x 0 100%; font-size:14px; padding-bottom:1px; }
.sidebar .block-account li a { color:#666; text-decoration:none; display:block; padding:5px 13px 4px; }
.sidebar .block-account li a:hover { color:#d63200; background-color:#ddd; color:#000; text-decoration:none; }
.sidebar .block-account li.current { font-family:Oswald; font-weight:bold; background:#fff; border:1px solid #d1d1d1; border-width:1px 0; color:#000; padding:5px 13px 4px 14px; margin:-1px -1px 0 0; }
.sidebar .block-account li.last { border-bottom:0; background-image:none; }

/* Block: Layered Navigation */
.sidebar .block-layered-nav { background:none; }
.sidebar .block-layered-nav .block-title { padding:26px 17px 12px; /* border-bottom:1px solid #d1d1d1; */ }
.sidebar .block-layered-nav .block-title h2 { font-size:18px; color:#111; }
.sidebar .block-layered-nav .block-subtitle { display:none; }
.sidebar .block-layered-nav dt { margin:2px 0 0; font-weight:bold; font-size:12px; color:#222; }
.sidebar .block-layered-nav dd { margin:0 0 13px; color:#666; }
.sidebar .block-layered-nav dd.last { margin:0; }
.sidebar .block-layered-nav dd li { margin:2px 0; }
.sidebar .block-layered-nav dd a { color:#222; text-decoration:none; }
.sidebar .block-layered-nav dd a:hover { text-decoration:underline; }
.sidebar .block-layered-nav .currently { border:1px solid #d1d1d1; border-width:1px 0; padding:10px 10px 10px 17px; margin-bottom:-1px; background:#f4f4f4 url(../images/bkg_currently.gif) repeat-x 0 -20px; }
.sidebar .block-layered-nav .currently .block-subtitle { display:block; }
.sidebar .block-layered-nav .currently li { padding:5px 0; color:#444; }
.sidebar .block-layered-nav .currently .label { font-weight:bold; color:#d33911; font-size:12px; }
.sidebar .block-layered-nav .actions { text-align:left; padding-right:7px; display:none; }

/* Block: Cart */
.sidebar .block-cart .block-title { background:#363636; padding:7px 17px; }
.sidebar .block-cart .block-title strong { font-weight:bold; color:#fff; }
.sidebar .block-cart .summary { margin:0 -17px; padding:0 12px 5px; background:#363636; color:#fff; text-align:center; position:relative; z-index:1; }
.sidebar .block-cart .summary a { font-weight:bold; color:#fff; }
.sidebar .block-cart .summary .subtotal { margin:3px 0 0; }
.sidebar .block-cart .summary .subtotal .price { font-weight:bold; }
.sidebar .block-cart .actions { margin:8px 0 13px; text-align:center; }
.sidebar .block-cart .actions button.button { float:none; }
.sidebar .block-cart .block-content { padding-top:0; }
.sidebar .block-cart .block-content table { margin:5px 0 0 0; }
.sidebar .block-cart .block-content table th { padding:1px 5px 1px 0; color:#8f8f8f; text-align:right; }
.sidebar .block-cart .block-content table td { text-align:left; padding:1px 0; }
.sidebar .block-cart  li a.btn-remove { float:none; background:none; margin:0; padding:0; width:auto; height:auto; overflow:visible; }

.mini-products-list li.item { padding:5px 0; }
.mini-products-list li.last { border:none; }
.mini-products-list .product-image { float:left; width:180px;  }
.mini-products-list .product-details { margin-left:65px; }
.sidebar .mini-products-list .product-details { margin-left:57px; }
.mini-products-list .product-details .product-name { font-size:11px; }
.mini-products-list .product-details .price-box { margin:5px 0; }
.mini-products-list .product-details h4 {}
.mini-products-list .product-details .btn-remove { font-size:11px; }

.sidebar .mini-products-images-list li.item { float:left; padding:5px; text-align:center; }

.block-products .mini-products-list .product-details { margin-left:65px; }



/* Block: Wishlist */
.block-wishlist {}

/* Block: Compare Products */
.block-compare li {}

/* Block: List */
.block-list .block-content ol { margin:-5px -13px; }
.block-list li.item { padding:5px 10px 5px 13px; }
.block-list .actions { padding:5px 0 0; }
.sidebar .block-list .product-name { font:normal 11px/1.25 Arial, Helvetica, sans-serif; color:#444; }
.sidebar .block-list .product-name a { color:#444; }

/* Block: Poll */
.block-poll li { margin:5px 0; }
.block-poll input.radio { float:left; margin:1px -18px 0 0; vertical-align:top; }
.block-poll .label { display:block; margin-left:18px; }
.block-poll .answer {}
.block-poll .votes { float:right; font-weight:bold; margin-left:10px; }

/* Block: Tags */
.block-tags ul,
.block-tags li { display:inline; }

/* Block: Subscribe */
.block-subscribe {}

/* Block: Reorder */
.block-reorder li { padding:5px 0; }
.block-reorder input.checkbox { float:left; margin:3px -20px 0 0; }
.block-reorder .product-name { margin-left:20px; font-size:11px; font-weight:normal; }

/* Block: Banner */
.block-banner { border:0; margin:10px 0; }
.block-banner .block-content { border-top:0; padding:0; background:#cbcbcb; text-align:center; }
.top-container .block-banner { background:#d0d0d0; margin:0; }

/* Paypal */
.sidebar .paypal-logo { display:block; margin:10px 0; text-align:center; }
/* ======================================================================================= */




/* View Type: List */
.products-list { padding:0; margin: 20px auto 0; width: 975px; position: relative; z-index: 999; }
.products-list .link-learn { background: #A9E900;
padding: 5px 15px;
display: block;
margin-top: 10px;
width: 110px;
font-size: 16px;
font-weight: bold;
text-transform: uppercase;
color: #fff;  }
.products-list .link-learn:hover { color: #333; }
.products-list li.item { margin:0 0 20px 0; padding:0 20px 20px 20px; border-bottom: 1px solid #eee; }
.products-list li.last { background-image:none; }
.products-list .product-image { float:left; width: 27%; text-decoration:none !important; position:relative; }
.products-list .f-fix { float: right; width: 69%; padding-top: 25px; }
.products-list .product-shop {  }
.products-list .product-name { margin-bottom:5px; font-size: 24px; }
.products-list .price-box { margin:5px 0 10px; }
.products-list .desc { margin:5px 0 10px; font-size:14px; }
.products-list .desc .link-learn { white-space:nowrap; }

.products-grid .special-price,
.products-list .special-price { font-size:12px; }
/* ======================================================================================= */


/* feature icons */
.catalog-product-view .tab-content.features ul li.bluetooth { background: url('../images/icons/bluetooth.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.hugeSound { background: url('../images/icons/sound.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.rechargeable { background: url('../images/icons/recharge.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.portable { background: url('../images/icons/portable.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.speakerphone { background: url('../images/icons/speaker.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.voice { background: url('../images/icons/voice.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.stereo { background: url('../images/icons/dual.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.rugged { background: url('../images/icons/tough.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.carabiner { background: url('../images/icons/carabiner.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.adventure { background: url('../images/icons/adventure.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.wifi { background: url('../images/icons/wireless.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.alarm { background: url('../images/icons/wakeup.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.charge { background: url('../images/icons/usb.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.dock { background: url('../images/icons/universal.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.water { background: url('../images/icons/water.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.attach { background: url('../images/icons/attach.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.linein { background: url('../images/icons/linein.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.hooks { background: url('../images/icons/earhooks.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.magnetic { background: url('../images/icons/attach.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.charged { background: url('../images/icons/precharged.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.controls { background: url('../images/icons/controls.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.storm { background: url('../images/icons/storm.png') no-repeat center left; }

.catalog-product-view .tab-content.features ul li.shower { background: url('../images/icons/Neutron-Shower.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.suction { background: url('../images/icons/Neutron-Suction.png') no-repeat center left; }
.catalog-product-view .tab-content.features ul li.weatherproof { background: url('../images/icons/Hangtime-Weatherproof.png') no-repeat center left; }





.catalog-product-view .tab-content.specs ul { list-style-type: disc; margin-left: 25px; }

/* product page tabs */
.product-tabs { width: 170px; float: left; margin-top: 35px; }
.product-tabs ul li { margin-bottom: 10px; font-weight: 100; text-align: right; padding-right: 40px; }
.product-tabs ul li a { color: #767676; text-transform: uppercase; font-size: 28px; }
.product-tabs ul li a:hover { color: #00adef; text-decoration: none; }
.product-tabs ul li.active { background: url('../images/grayArrow.png') no-repeat right center; }
.tab-content { width: 765px; float: right; background: #767676; color: #fff !important; min-height: 300px; padding: 20px }
.catalog-product-view .tab-content { display: none; }
.catalog-category-view .tab-content { display: none; }
.catalog-product-view .tab-content.active, .catalog-category-view .tab-content.active { display: block; }

/* reviews tab */
.reviews .grid-left { width: 320px; float: left; margin-right: 50px; }
.reviews .grid-right { float: left; width: 350px; }
.reviews.tab-content { background: #fff; border: 10px solid #767676; width: 745px; overflow-y: scroll; color: #292929 !important; font-size: 90%; height: 700px; }
.reviews .box-collateral { background: none; }
.reviews p.no-reviews { margin-bottom: 16px; }
.reviews .box-title { display: none; }
.reviews .box-content h4 { font-weight: normal; }
.reviews .box-content h4 a { color: #292929; }
.reviews .review-form .data-table.review-summary-table { margin-left: -24px; padding: 12px 0px; }
.reviews .data-table thead { background: none; }
.reviews .data-table tr.last th, .reviews .data-table tr.last td { border-bottom: 0; }
.reviews .rating-box { background: none; }
.reviews .rating-box .rating { float: none; margin: 0 auto; display: block; }
.reviews .form-buttons { width: auto; }
.reviews p.required { width: auto; }
.reviews .form-list input.input-text, .reviews .form-list textarea { width: 300px; }
.reviews .form-list label { width: auto; font-size: 80%; text-transform: uppercase; }
.reviews .form-list th.label { padding-left: 24px; font-size: 110%; }
.reviews button.button span, .reviews button.button span span { background-color: $jam_lightblue !important; }
.reviews .recaptchatable a, .reviews .recaptchatable a:hover { color: #aaa; }
.reviews .box-reviews dt { font-weight: bold; font-style: normal !important; margin: 0px !important; }
.reviews .box-collateral.box-reviews .box-content { padding-top: 0px; }
.reviews .starlabel { text-transform: uppercase; font-size: 70%; letter-spacing: 1px; }
.reviews #product-reviews-list .rating-box { margin: 0px; }
.reviews #product-reviews-list .data-table { width: 50%; }
.reviews .box-reviews dd p { font-size: 94% !important; line-height: 1.8em; }
.reviews .box-reviews dd { background: none !important; margin-top: 4px !important; }
.product-view .product-shop .product-main-info .ratings {  width: 180px !important; text-align: right; float: left; }
.product-view .product-shop .product-main-info .ratings.no-rating { text-align: left; }
.product-view .product-shop .product-main-info .rating-box { margin-top: 3px; }
.product-view .ratings .rating-links a#review-link { text-decoration: underline; color: #000; }


/* Product Images */
.product-view .product-img-box { float:left; width: 60%; }
.product-view .product-img-box .product-image img {  } /*IE8 PNG Fix */
.product-view .product-img-box p.notice { text-align:center; padding:5px 0; font-size:11px; }
.product-view .product-img-box .product-image { position:relative; overflow:hidden; z-index:3; }
.product-view .product-img-box .zoom-notice { text-align:center; }
.product-view .product-img-box .zoom { position:relative; z-index:9; height:18px; margin:0 auto 13px; padding:0 28px; background:url(../images/slider_bg.gif) 50% 50% no-repeat; cursor:pointer; }
.product-view .product-img-box .zoom #track { position:relative; height:18px; }
.product-view .product-img-box .zoom #handle { position:absolute; left:0; top:-1px; width:9px; height:22px; background:url(../images/magnifier_handle.gif) 0 0 no-repeat; }
.product-view .product-img-box .zoom .btn-zoom-out { position:absolute; left:2px; top:0; }
.product-view .product-img-box .zoom .btn-zoom-in { position:absolute; right:2px; top:0; }
.product-view .product-img-box .more-views { position:relative; padding: .5em 0; text-align: center; }
.product-view .product-img-box .more-views h2 { display:none; }
.product-view .product-img-box .more-views ul { display: inline-block; text-align: left; }
.product-view .product-img-box .more-views li { float:left; margin:0 6px 6px 0; }
.product-view .product-img-box .more-views li a img { max-width: 56px; height: auto; }

.product-image-popup { margin:0 auto; }
.product-image-popup .buttons-set { clear:none; line-height:28px; margin:0 0 10px; float:right; width:150px; text-align:right; }
.product-image-popup .buttons-set .button { vertical-align:middle; float:none; }
.product-image-popup .image { display:block; clear:both; margin:15px auto 15px; }
.product-image-popup .image-label { font-size:14px; text-align:left; clear:both; font-weight:normal; color:#000; line-height:19px; margin:0 0 10px; }

.product-image-popup .nav { text-align:center; margin:0 auto; width:120px; }
.product-image-popup .nav .prev,
.product-image-popup .nav .next { margin:0 15px; display:inline; background:url(../images/bkg_slide-button.gif) no-repeat 0 0; text-indent:-999em; cursor:pointer; width:28px; height:28px; z-index:2; overflow:hidden; float:left; }
.product-image-popup .nav .prev { left:-19px; float:left; }
.product-image-popup .nav .next { right:-19px; float:right; background-position:0 -40px; }


/* Colorbox popup styles for video player */
#colorbox #cboxTopLeft,
#colorbox #cboxTopCenter,
#colorbox #cboxTopRight,
#colorbox #cboxMiddleLeft,
#colorbox #cboxMiddleRight,
#colorbox #cboxBottomLeft,
#colorbox #cboxBottomCenter,
#colorbox #cboxBottomRight { background: #fff; }
#colorbox #cboxContent { position: static; }
#colorbox #cboxLoadedContent { overflow: hidden !important; }
#colorbox #cboxTitle { top: 40px; }
#colorbox #cboxCurrent { left: 80px; bottom: 6px; }
#colorbox #cboxNext, #colorbox #cboxPrevious { left: 24px; bottom: 2px; }
#colorbox #cboxNext { left: 50px; }
#colorbox #cboxClose { right: 0; top: 0; width: auto; height: auto; padding: 10px; font-weight: bold; color: white; text-indent: 0; background: $jam_lightblue; }


/* jQzoom */
div.jqZoomTitle { z-index:5000; text-align:center; position:absolute; top:0; left:0; width:100%; color:#fff; background:#999; }
.jqZoomPup { overflow:hidden; background-color:#fff; z-index:10; border-color:#fff; border-style:solid; cursor:crosshair !important; /* -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=60)"; -moz-opacity:0.6; opacity:0.6; */ }
a.product-image { cursor:pointer !important; }
p.product-image { cursor:default !important; }
.zoom-activated {}
.jqZoomPup img { border:0; }
.preload { text-decoration:none; border:1px solid #ccc; padding:8px; text-align:center; background:#fff url(../images/zoomloader.gif) no-repeat 43px 30px; width:90px; height:43px; z-index:10; position:absolute; top:3px; left:3px; -moz-opacity:0.8; opacity:0.8; filter:alpha(opacity=80); }
.jqZoomWindow { border:1px solid #ccc; background-color:#fff; }

/* Rating */
.rating-box { width:85px; height:25px; font-size:0; line-height:0; /* background:url(../images/bkg_rating.gif) 0 100% repeat-x; */ overflow:hidden; margin-top: 5px; }
.rating-box .rating { float:left; height:25px; background:url(../images/star.svg) repeat-x; background-size:17px; background-position:top left; }
.catalog-product-compare-index.page-popup .rating-box .rating {background-position:left center;}
.ratings {  }
.ratings .rating-box { float:left; margin-right:5px; }
.catalog-product-compare-index.page-popup .ratings .rating-box{float:none;display:block;margin-right:0;}
.ratings .amount { ffont-size:.9em; }
.ratings .rating-links { margin:0; font-size:.9em; }
.ratings .rating-links a { }
.ratings .rating-links a:hover { text-decoration:none; }
.ratings .rating-links .separator { margin:0 3px; }
.ratings dt {}
.ratings dd {}

/* Availability */
.availability { font-weight:bold; }
.availability.in-stock { color:#1b6800; }
.availability.out-of-stock { color:#cf3a00; }

.availability-only { margin:10px 0 7px; line-height:16px; background:url(../images/i_availability_only.gif) 0 50% no-repeat; padding-left:15px; }
.availability-only span,
.availability-only a { border-bottom:1px dashed #751d02; color:#000; }
.availability-only a { background:url(../images/i_availability_only_arrow.gif) 100% 0 no-repeat ; cursor:pointer; padding-right:15px; text-decoration:none; }
.availability-only .expanded { background-position:100% -15px; }
.availability-only strong { color:#d12c01; }

.availability-only-details { margin:0 0 7px; border:1px solid #d9d9d9; }
.availability-only-details thead { background:url(../images/bkg_thead.gif) repeat-x scroll 0 0 #e8e8e8; }
.availability-only-details th,
.availability-only-details td { padding:2px 8px; }
.availability-only-details th { border-bottom:1px solid #d9d9d9; font-size:10px; text-transform:uppercase; }
.availability-only-details td { background:#fff; font-size:11px; }
.availability-only-details tr.odd td.last { color:#d95e00; font-weight:bold; }

/********** < Product Prices */
.price { white-space:nowrap !important; }

.price-box {}
.price-box .price { font-weight:bold; }

.price-box-bundle {}

/* Regular price */
.regular-price {}
.regular-price .price { font-weight:bold; }

/* Old price */
.old-price { text-decoration:line-through; color:#989696; }
.old-price .price-label { white-space:nowrap; color:#989696;display:none; }
.old-price .price { font-weight:normal; text-decoration:line-through; color:#989696; }
.sidebar .old-price .price-label { display:none !important; }

/* Special price */
.special-price {}
.special-price .price-label { white-space:nowrap; color:#c73615; font-weight:bold; }
.special-price .price { font-weight:bold; color:#c73615; }
.sidebar .special-price .price-label { display:none !important; }

/* Minimal price (as low as) */
.minimal-price {}
.minimal-price .price-label { white-space:nowrap; }

.minimal-price-link { display:block; margin:5px 0; text-decoration:none; }
a.minimal-price-link:hover { text-decoration:underline; }
.minimal-price-link .label { font-weight:bold; }
.minimal-price-link .price { font-weight:bold; }

/* Excluding tax */
.price-excluding-tax { display:block; }
.price-excluding-tax .label { white-space:nowrap; }
.price-excluding-tax .price { font-weight:normal; }

/* Including tax */
.price-including-tax { display:block; }
.price-including-tax .label { white-space:nowrap; }
.price-including-tax .price { font-weight:bold; }

/* FPT */
.weee { display:block; font-size:11px; }
.weee .price { font-size:11px; font-weight:bold; }

/* Excl tax (for order tables) */
.price-excl-tax { display:block; }
.price-excl-tax .label { display:block; white-space:nowrap; }
.price-excl-tax .price { display:block; }

/* Incl tax (for order tables) */
.price-incl-tax { display:block; }
.price-incl-tax .label { display:block; white-space:nowrap; }
.price-incl-tax .price { display:block; font-weight:bold; }

/* Price range */
.price-from {}
.price-from .price-label { white-space:nowrap; }
.price-from .price { white-space:nowrap; }
.price-to {}
.price-to .price { white-space:nowrap; }
.price-to .price-label { white-space:nowrap; }

/* Price as configured */
.price-as-configured {}
.price-as-configured .price-label {white-space:nowrap; }
.price-as-configured .price { font-size:14px; color:#e55100; }

/* Price notice next to the options */
.price-notice { padding-left:10px; }
.price-notice .price { font-weight:bold; }
/********** Product Prices > */

/* Tier Prices */
.product-pricing { background:#fbf4de; border:1px solid #e2d4c7; padding:8px; margin:20px 0; }
.product-pricing li { line-height:1.35; margin:2px 0; }
.product-pricing li .price { font-weight:bold; }
.product-pricing li .benefit { font-weight:bold; }

/* Add to Links */
.add-to-links { margin:5px 0 0; font-size:11px; }
.add-to-links li { margin:2px 0; }
.add-to-links a { color:#999; }
.add-to-links a:hover {}
.add-to-links .separator { display:none; }

/* Add to Cart */
.add-to-cart {}
.add-to-cart label { font-size:12px; vertical-align:middle; font-weight:bold; margin-right:5px; text-transform:capitalize; }
.add-to-cart .qty { vertical-align:middle; text-align: center; margin-right:5px; height: 30px; width: 35px; padding: 2px; }
.add-to-cart .paypal-logo { clear:both; text-align:center; }
.add-to-cart .paypal-logo .paypal-or { clear:both; display:block; margin:10px; }

button.btn-cart span {}

/* On product view */
.add-to-cart button.btn-cart span { padding: 6px 0 6px 5px; height:30px; background-color: $jam_lightblue; }
.add-to-cart button.btn-cart span span { border:0; padding:5px 15px 0 15px; color:#fff; font-size: 1.5em; text-transform:uppercase; }
.add-to-cart button.btn-gr span span { font-size:14px; padding:1px 18px 0 10px; }

/* Videos page */
iframe.mobile { display: none; }

/* Add to Links + Add to Cart */
.add-to-box {}
.add-to-box .add-to-cart { }
.add-to-box .add-to-links { }
.add-to-box .add-to-links li { }

/* Product Options */
.product-options {  }

.product-options dt .qty-holder { float:right; }
.product-options dt .qty-holder label { vertical-align:middle; }
.product-options dt .qty-disabled { background:none; border:0; padding:3px; color:#000; }
.product-options dd {  }
.product-options dl.last dd.last {}
.product-options dd .input-box { display:block; }
.product-options dd input.input-text { width:98%; }
.product-options dd input.datetime-picker { width:150px; }
.product-options dd .time-picker { display:-moz-inline-box; display:inline-block; padding:2px 0; vertical-align:middle; }
.product-options dd .time-picker select { vertical-align:middle; }
.product-options dd textarea { width:98%; height:8em; }
.product-options dd select { width:205px; }
.product-options .options-list {}
.product-options .options-list li { vertical-align:middle; margin:0; padding:2px 0; line-height:1.315; }
.product-options .options-list input.radio { float:left; margin:2px -18px 0 0; }
.product-options .options-list input.checkbox { float:left; margin:2px -20px 0 0; }
.product-options .options-list .label { display:block; margin-left:20px; }
.product-options .validation-error .options-list,
.product-options ul.validation-failed { padding:2px 1px !important; position:relative; z-index:2; top:-3px; left:-2px; margin-right:-2px; }
.options-container-small .product-options .validation-error .options-list,
.options-container-small .product-options ul.validation-failed { padding:0 1px !important; position:relative; z-index:2; top:-1px; left:-2px; margin-right:-2px; }
.product-options li { margin:3px 0; }

.product-view .product-options-bottom .product-pricing { clear:both; }
.product-view .product-options-bottom { padding:0; margin:0; }
.product-view .product-options-bottom .add-to-cart { padding: .5em 0 0 0; }
.product-view .product-options-bottom .price-box { margin:0 !important; float:right; }
.product-view .product-options-bottom p.required { display: none; }

/* Options in product info column */
.options-container-small { float: right;  }
.options-container-small .product-options-bottom {  }
.options-container-small .product-pricing { display:none; }
.options-container-small .price-box { display:none; }
.options-container-small .add-to-cart {  }
.options-container-small .product-options-bottom p.required { text-align:center; padding: .5em 0; }
.options-container-small dt { float:left; padding: 0 1em 0 0; }
.options-container-small dd {  }
.options-container-small .product-options { margin:0 0 0; padding: 10px 0px; }

/* Options after product info column */
.options-container-big { clear:both; background:#fff; padding:20px; border-top:1px solid #cfcfcf; border-width:1px 0; }

/* Bundle product - alternative view */
#customizeTitle { margin:0; }
.bundleProduct {}
.product-options .bundle-summary { font-size:11px; }
.bundleProduct .product-options { font-size:12px; margin-top:0; }
.bundleProduct .product-options-bottom { padding:0; font-size:12px; margin:0 0 5px; line-height:27px; }
.bundleProduct .product-options-bottom .add-to-cart { float:right; margin:0 0 0 20px; }
.bundleProduct .product-options-bottom .price-box { margin:0; float:right; }
.bundleProduct .product-options-bottom p.required { padding:5px 0 0; width:auto; clear:both; text-align:right; }
.with-bundle { position:relative; z-index:1; background:#fff url(../images/bkg_cols.gif) 460px 0 repeat-y; }

/**/
#bundle-product-wrapper { margin:-1px -20px -20px; border-top:1px solid #cfcfcf; float:left; width:938px; overflow:hidden; position:relative; z-index:1; }
#bundleProduct { width:1877px; background:#fff url(../images/bkg_cols.gif) repeat-y 279px 0; z-index:1; position:relative; }
#productView { margin:0; position:static; border:0; }
#options-container { min-height:510px; width:938px; position:relative; z-index:2; float:right; border-left:1px solid #cfcfcf; background:#fff url(../images/bkg_cols.gif) repeat-y 460px 0; left:-1px; }
#options-container h1 { text-align:left; }

/* Bundle Product 2 column design */
.col2-left-layout  #bundle-product-wrapper,
.col2-right-layout #bundle-product-wrapper { width:755px; }
.col2-left-layout  #bundleProduct,
.col2-right-layout #bundleProduct { background-position:574px 0; width:1511px; }
.col2-left-layout  #options-container,
.col2-right-layout #options-container { background-position:277px 0; left:-1px; width:755px; }

.col2-left-layout  #options-container .product-options,
.col2-left-layout  #options-container .product-options-bottom,
.col2-right-layout #options-container .product-options,
.col2-right-layout #options-container .product-options-bottom { width:420px; }

.col2-left-layout  .bundle-summary,
.col2-right-layout .bundle-summary { left:460px; }

/* Bundle Product 3 column design */
.col3-layout #bundle-product-wrapper,
.col3-layout #options-container { width:572px; }
.col3-layout #bundleProduct { background-position:390px 0; width:1144px; }
.col3-layout #options-container { background-position:94px 0; }
.col3-layout #productView { margin-right:-10px; }

.col3-layout #options-container .product-options,
.col3-layout #options-container .product-options-bottom { width:237px; }
.col3-layout #options-container .product-options-bottom .price-box { float:none; }

.col3-layout .bundle-summary { left:277px; }

#options-container .product-options { font-size:12px; margin-top:0; font-size:11px; z-index:1; margin:0; width:600px; padding:20px 20px 0; clear:both; position:relative; z-index:1; }
#options-container .product-options-bottom { padding:0 20px 20px; width:600px; font-size:12px; margin:0 0 5px; line-height:27px; }
#options-container .product-options-bottom .add-to-cart { float:right; margin:0 0 0 20px; }
#options-container .product-options-bottom .price-box { margin:0; float:right; }
#options-container .product-options-bottom p.required { padding:5px 0 0; width:auto; clear:both; text-align:right; }
.moving-now {}

.bundle-summary { position:absolute; z-index:2; left:643px; margin-top:-1px; background:#fff; width:296px; }
.bundle-summary .product { background:#f6f6f6 url(../images/bkg_page-title.gif) repeat-x 0 0; border-bottom:1px solid #ddd; padding:14px 20px; }
.bundle-summary .product-image { border:1px solid #d6d6d6; float:left; margin:0 -60px 0 0; }
.bundle-summary .product-details {margin-left:60px; padding:0 0 0 13px; }
.bundle-summary .product-details p.availability { margin:5px 0; font-weight:bold; font-size:12px; }
.bundle-summary .product-name { font-size:16px; color:#111; }
.bundle-summary .summary h4 { font-size:14px; color:#333; }
.bundle-summary .summary ul { margin:6px 0 10px; }
.bundle-summary .summary li { background:url(../images/bkg_dots.gif) repeat-x 0 1px; margin:0; padding:11px 0 7px; }

/* Grouped Product */
.groped-product-type { position:relative; z-index:2; margin:20px -30px -20px; background:#fff; border-top:1px solid #cfcfcf; padding:20px 30px 0; }
.grouped-items td { vertical-align:middle; padding:5px 10px; }

/* Gift Card product */
.giftcard-form {}
.giftcard-amount-form { margin:0 0 10px; }
.giftcard-amount-form label { width:120px; text-align:left; padding-right:5px; }
.giftcard-amount-form .form-list .input-text { width:70px; }
.giftcard-amount-form .form-list select { width:150px; }
.giftcard-amount-form .form-list .input-box { padding-right:20px; margin-right:9px; }
.giftcard-amount-form .form-list .input-box .v-fix { white-space:nowrap; }
.giftcard-amount-form .form-list .input-box p.notice { float:left; padding:0; clear:left; width:55px; margin:5px -100px 0 0; white-space:nowrap; font-size:11px; position:relative; z-index:1; }
.giftcard-amount-form .form-list .input-box p.notice span { margin-right:5px; display:block; }
.giftcard-send-form { background:#fff; z-index:2; position:relative; border-top:1px solid #ddd; padding:30px 30px 10px; margin:0 -30px -30px; }
.giftcard-send-form label { width:120px; text-align:left; padding-right:5px; padding-bottom:5px; }
.giftcard-send-form .field label { padding-bottom:0; }
.giftcard-send-form .field .input-text { width:249px !important; }

/* Block: Related */
.product-view .box-related { font-size:11px; }
.product-view .box-related p { margin:0; }
.product-view .box-related .product-name { font-size:12px; }
.product-view .box-related .price-box { margin:8px 0; }
.product-view .box-related .note { padding:5px 20px; margin:0; font-size:12px; background:#f7edda; }
.product-view .box-related .note,
.product-view .box-related .note a { color:#d02600; }
.product-view .box-related input.checkbox { position:absolute; margin:3px 0 0 105px; }
.product-view .box-related ol { padding:20px 0 20px 20px !important; }
.product-view .box-related li.item .item-info { margin-right:20px; }
.product-view .box-related li.item { width:31.5%; float:left; margin-right:1%; }
.product-view .box-related li.item p a { color:#999; }
.product-view .box-related li.item .product-image { float:left; margin-right:-100px; width:100px; }
.product-view .box-related li.item .product-details { margin-left:125px; }

/* 3 Column Block: Related */
.col3-layout .product-view .box-related li.item .product-image { display:block; float:none; margin:0 auto; }
.col3-layout .product-view .box-related li.item .product-details { margin-left:0; }
.col3-layout .product-view .box-related input.checkbox { margin-left:135px; }

/* Block: Upsell */
.product-view .box-up-sell  { float: none; margin-top: 3em; }
.product-view .box-up-sell .products-grid { padding:30px 0 10px 0; margin:0; border-top: 1px solid #000; }
.product-view .box-up-sell .products-grid li { width:176px; margin-right:40px; display:inline; }
.box-up-sell .box-title { text-align: left; }
.product-view .box-up-sell .product-name a { color: #fff; }
.product-view .box-up-sell .product-name a:hover { color: #00ADEF; }

/* 2 Column Block: Upsell */
.col2-left-layout  .product-view .box-up-sell .products-grid,
.col2-right-layout .product-view .box-up-sell .products-grid { padding:30px 0 10px 30px; margin-right:0; }
.col2-left-layout  .product-view .box-up-sell .products-grid li,
.col2-right-layout .product-view .box-up-sell .products-grid li { display:inline; width:140px; }

/* 3 Column Block: Upsell */
.col3-layout .product-view .box-up-sell .products-grid { padding:30px 0 10px 15px; margin:0; }
.col3-layout .product-view .box-up-sell .products-grid li { width:135px; margin-right:2px; display:inline; }

/* Block: Tags */
.product-view .box-tags {}
.product-view .box-tags h3 { font-size:12px; font-weight:normal; }
.product-view .box-tags .form-add-tags { padding:5px 0 0 0; }
.product-view .box-tags .form-add-tags label { margin:0; font-weight:bold; font-size:12px; padding:2px 15px 0 0; height:4em; float:left; }
.product-view .box-tags .form-add-tags .input-box { margin:0; display:inline-block; }
.product-view .box-tags .form-add-tags div.validation-passed,
.product-view .box-tags .form-add-tags div.validation-error { padding-right:17px; }
.product-view .box-tags .form-add-tags input.input-text { width:300px; }
.product-view .box-tags .form-add-tags button.button { margin-left:5px; vertical-align:top; }
.product-view .box-tags .form-add-tags p { padding:5px 0; font-size:11px; }
.product-view .box-tags .tags-list { margin:15px 0; line-height:1.5em; }
.product-view .box-tags .tags-list li { display:inline; font-size:10px; margin:0 5px 0 0; }
.product-view .box-tags .tags-list li a { text-decoration:none; font-size:12px; margin:0; padding:3px; background:#fffcf0; }
.product-view .box-tags .tags-list li a:hover { text-decoration:underline; }

.product-view .box-tags .form-add-tags .input-box , x:-moz-any-link { float:left; }/* FireFox 2 fix */
.product-view .box-tags .form-add-tags .button , x:-moz-any-link { margin-bottom:15px; }/* FireFox 2 fix */
.product-view .box-tags .form-add-tags .input-box , x:-moz-any-link, x:default { float:none; }/* FireFox 3 restore */
.product-view .box-tags .form-add-tags .button , x:-moz-any-link, x:default { margin-bottom:0; }/* FireFox 3 restore */

/* Block: Reviews */
.product-view .product-main-info .review-summary-table { margin:10px 0; }
.review-summary-table { border: 0; }
.review-summary-table th,
.review-summary-table td { padding:2px; }
.review-summary-table tr.last td { border:0; text-align: center; }
.review-summary-table th.label,
.review-summary-table td.label { width:1px; white-space:nowrap; padding:0 10px 0 0; background:none; }

.product-view .box-reviews .pager { border-width:1px 0; margin:0; }
.product-view .box-reviews dt { margin:10px 0; font-style:italic; }
.product-view .box-reviews dt a { font-weight:bold; font-style:normal; margin-right:10px; }
.product-view .box-reviews dd { margin:10px 0 20px; padding:0 0 10px; background:url(../images/bkg_dots.gif) repeat-x 0 100%; }
.product-view .box-reviews dd p { margin:0 0 10px; }
.product-view .box-reviews dd .date { margin:0 0 10px; font-style:italic; }
.product-view .box-reviews dd.last { background:none; margin-bottom:0; }
/* Add Review Form */
.product-view .box-reviews .product-name { font-weight:normal; margin-bottom:20px; }
.product-view .box-reviews .product-name span { font-weight:bold; }
.product-view .box-reviews .form-list { margin:15px 0; }
.product-view .box-reviews .form-list .input-box {}

.product-view .box-reviews .form-list .review-summary-table thead { background:none; }
.product-view .box-reviews .form-list .review-summary-table thead th { background:none; border:0; }
.product-view .box-reviews .form-list .review-summary-table .value { text-align:center; }
/* ======================================================================================= */


/* Product Review ======================================================================== */
.review-product-list .col-main { padding-top:0; }

.product-review {}
.product-review .product-img-box { float:left; width: 33%; }
.product-review .product-img-box .product-image { display:block; margin:0 0 15px; }

.product-review .review-summary { width: 25%;  float: right; }
.product-review .box-reviews .box-title { text-align : left; }
.product-review .box-reviews .box-title h2 { font-size: 1.75em; color: #333; font-weight: normal; }

.product-review .review-title { font-size: 1.1em; color: #000; font-weight: bold; }
.product-review .reviewer { font-size: 1em; font-weight: bold; float: right; margin: 0 0 10px 20px; }
.product-review .date { font-size: .9em; color: #555; text-align: right; font-style: italic; }

.product-review .details { float:right; width:400px; }

.product-review .box-reviews { width: 66%; float: right; }
.product-review .review-form { clear: both; padding-top: 3em; }
.product-review .review-form h2 { border-bottom: 1px solid #ccc; padding-bottom: 7px; }
.product-review .review-form .form-list { padding-top: 3em; }
.product-review .review-form .form-list label { font-size: 1em; font-weight: normal; }
.product-review .review-form .note { width: 66%; }

.product-review .details .product-name { font-size:15px; font-weight:bold; color:#151515; line-height:19px; margin:0 0 10px; }
.product-review .details p.date { font-size:12px; font-style:italic; }
.product-review .details p { margin:10px 0; }
.product-review .details .review-summary { width: 33%;  float: right; }
.product-review .details .review-summary-table {   }
.product-review .buttons-set { clear:both; padding:25px 0 0; }

.review-product-view .col-main { background:#fff url(../images/bkg_cols.gif) repeat-y -430px 0; padding:0; }
.review-product-list .product-name-box { margin-top: 2em; }
.review-product-view .col-main .page-title { margin-left:0; margin-right:0; }
.review-product-view .product-review .product-img-box { padding:0 0 20px 20px; }
.review-product-view .product-review .details { width:570px; padding:0 20px 20px 0; }
.review-product-view .product-review .buttons-set { border-top:1px solid #d1d1d1; background:#fff; margin:0; padding:15px 20px; }
/* ======================================================================================= */


/* CMS ============================================================================= */
.cms-home .col1-layout,
.cms-index-index .col1-layout { border:0; }
.cms-home .top-callout,
.cms-index-index .top-callout { margin-bottom:6px; }
.cms-home .col1-layout .col-main,
.cms-index-index .col1-layout .col-main { padding:0; }

.cms-index-index .col2-set {}
.cms-home .col2-set .col-1,
.cms-index-index .col2-set .col-1 { width:705px; }
.cms-home .col2-set .col-2,
.cms-index-index .col2-set .col-2 { width:244px; border:1px solid #d9d7ca; border-left:0; margin-left:-244px; }
.cms-home .col2-set .col-2 img,
.cms-index-index .col2-set .col-2 img { display:block; margin:6px auto; }

.cms-home .col1-layout .category-view,
.cms-index-index .col1-layout .category-view { margin:0 0 20px; }
.cms-home .col1-layout .page-title,
.cms-index-index .col1-layout .page-title { margin:3px 0 0; }
.cms-home .col1-layout .products-grid,
.cms-index-index .col1-layout .products-grid { margin:0; border:1px solid #cbc0b2; padding-top:20px; padding-bottom:20px; }
.cms-home .col1-layout .products-list,
.cms-index-index .col1-layout .products-list { margin:0; border:1px solid #cbc0b2; padding-top:20px; }

.cms-hp-touchsmart-iq506-has-arrived .col-main { padding:0; }
.cms-hp-touchsmart-iq506-has-arrived .main { margin-top:0; }
.cms-hp-touchsmart-iq506-has-arrived .breadcrumbs { display:none; }
.new-product-spotlight { border:1px solid #cfcfcf; border-top:0; background:url(../images/callouts/electronics/new_computer_bg.jpg) no-repeat; height:519px; position:relative; }
.new-product-spotlight .link { position:absolute; top:95px; left:318px; }
.new-product-spotlight .more-new-positioning { position:absolute; top:42px; right:70px; width:207px; }
.new-product-spotlight .more-new-positioning h3 { margin-bottom:5px; font-size:11px; text-transform:uppercase; font-weight:bold; }
.new-product-spotlight .more-new { position:relative; height:335px; }
.new-product-spotlight .more-new .arrow-up { position:absolute; top:0; left:0; height:20px; width:207px; background:url(../images/callouts/electronics/more_new_arrow.gif) no-repeat; text-indent:-999em; }
.new-product-spotlight .more-new .arrow-down { position:absolute; bottom:0; left:0; height:20px; width:207px; background:url(../images/callouts/electronics/more_new_arrow.gif) no-repeat 0 100%; text-indent:-999em; }
.new-product-spotlight .more-new .content { position:absolute; top:20px; left:0; width:205px; height:295px; border-width:0 1px; border-style:solid; border-color:#dcdcdc; background:#fff; }
.new-product-spotlight .more-new ol { margin:10px 15px; padding-left:0; list-style-type:none; }
.new-product-spotlight .more-new .item { padding:10px 0 10px 85px; border-bottom:1px solid #d2d2d2; }
.new-product-spotlight .more-new .item.top {padding-top:0; }
.new-product-spotlight .more-new .item.last { border-bottom:0; padding-bottom:0; }
.new-product-spotlight .more-new .item img { float:left; margin-left:-85px; position:relative; }
.new-product-spotlight .more-new a { font-weight:bold; }

.cms-menu { padding:15px 0; border-bottom:1px solid #d1d1d1; font-size:1em; list-style-position:inside; }
.col-main .cms-menu { float:left; width:240px; border:1px solid #d1d1d1; margin:0 10px 5px 0; }
.cms-menu li { color:#999; margin:3px 0; line-height: 1.2em; padding-top: 1em;}
.cms-menu li.parent > a,
.cms-menu li.parent > strong { padding-right:13px; font-size: 1.1em; }
.cms-menu li strong { font-weight:bold; color:#333; }
.cms-menu li ul,
.cms-menu li ol { margin-bottom:7px; list-style-position:inside; }
/* CMS Menu Types */
ol.cms-menu.type-1 { list-style-type:decimal; }
ol.cms-menu.type-1 ol { list-style-type:decimal; }
ol.cms-menu.type-a { list-style-type:lower-alpha; }
ol.cms-menu.type-a ol { list-style-type:lower-alpha; }
ol.cms-menu.type-A { list-style-type:upper-alpha; }
ol.cms-menu.type-A ol { list-style-type:upper-alpha; }
ol.cms-menu.type-i { list-style-type:lower-roman; }
ol.cms-menu.type-i ol { list-style-type:lower-roman; }
ol.cms-menu.type-I { list-style-type:upper-roman; }
ol.cms-menu.type-I ol { list-style-type:upper-roman; }

ul.cms-menu.type-circle { list-style-type:circle; }
ul.cms-menu.type-circle ul { list-style-type:circle; }
ul.cms-menu.type-disc { list-style-type:disc; }
ul.cms-menu.type-disc ul { list-style-type:disc; }
ul.cms-menu.type-square { list-style-type:square; }
ul.cms-menu.type-square ul { list-style-type:square; }
/* ======================================================================================= */


/* Content Styles ================================================================= */
.product-name { font-size:12px; font-weight:bold; color:#333; }
.product-name a { color:#333; text-decoration:none; text-transform: uppercase; }
.product-name a:hover { color:#00adef; text-decoration:none; }

/* Product Tags */
.tags-list { margin:5px 0; }
.tags-list li { display:inline; font-size:12px; }
.tags-list li a { text-decoration:none; margin:0 2px 0 0; }
.tags-list li a:hover { text-decoration:underline; }

/* Advanced Search */
.search-summary { padding:10px 10px 0; margin:10px 0 0; font-size:12px; background:url(../images/bkg_dots.gif) repeat-x 0 0; }
.search-summary li { margin:2px 0; }

/* Sitemap */
.page-sitemap .links { display:block; text-align:right; margin:0 20px -36px 0; }
.page-sitemap .links a { position:relative; }
.page-sitemap .sitemap { padding:0 20px; margin:15px 0; font-size:12px; }
.page-sitemap .sitemap li { margin:3px 0; }
/* ======================================================================================= */


/* Shopping Cart ========================================================================= */
.checkout-cart-index .col-main { padding:0; }
.checkout-cart-index .col-main .page-title { margin:0; }
.cart-empty { background:#fff; }
.cart {  }
.cart .title-buttons { padding-right:0; }
.cart .messages,
.cart .messages li { margin: 5px auto; width: 75%;  }
.cart .checkout-types { float:right; text-align:center; }
.cart .checkout-types .paypal-or { margin:0 8px; line-height:2.3; }
.cart .checkout-types fieldset { display:inline; }
.cart .checkout-types .btn-proceed-checkout span {/*  background-image:url(../images/bkg_btn-co.gif); */ height:27px; padding:0 0 0 13px; }
.cart .checkout-types .btn-proceed-checkout span span { border:0; /* background-image:url(../images/bkg_btn-co.gif); */ padding:1px 32px 0 19px; color:#fff; font:bold 15px/26px Arial, Helvetica, sans-serif; height:26px; text-transform:none; margin:0; }
.cart .title-buttons .checkout-types li { float:left; margin:0 0 5px 5px; padding-top:5px;}
.cart .title-buttons .checkout-types li:last-of-type{padding-top:0;}

/* Shopping Cart Table */
.cart-table { border-width:1px 0; }
.cart-table .item-msg { font-size:10px; }
.cart-table thead th { vertical-align:bottom; }
.cart-table tbody td { padding:12px; }
.cart-table .btn-continue { float:left; background:none; border:none; }
.cart-table .btn-continue span { background:none; border:none; text-transform:none; color:#444; text-decoration:underline; font-weight:normal; padding:0; }
.cart-table .btn-update { float:right; }

/* Shopping Cart Collateral boxes */
.cart .cart-collaterals { width:630px; padding:20px 0 10px; }
.cart .cross-inside { background:url(../images/bkg_crosssell.gif) repeat-y 0 0; }
.cart .cart-collaterals h2 { color:#000; font-weight:normal; margin:0 0 5px; }
.cart .deals { float:left; width:280px; display:inline; margin:0 0 0 30px; font-size:12px; }
.cart .deals p,
.cart .deals label { margin:0 0 7px; display:block; }
.cart .deals .input-text { width:140px; margin:0 5px 3px 0; float:left; }
.cart .giftcard { position:relative; z-index:1; }
.cart .giftcard button.check-gc-status { clear:both; }
.cart .giftcard button.check-gc-status span { padding:0; background:none; line-height:1.3; color:#444; height:auto; font-weight:normal; text-transform:none; }
.cart .giftcard button.check-gc-status span span { text-decoration:underline; font-size:12px; }
.cart .giftcard,
.cart .discount { margin:0 0 20px 0; }
.cart .cancel-btn { margin:5px 30px 0 0; clear:left; }
.cart .shipping { float:left; margin:0 0 0 30px; display:inline; font-size:12px; width:280px; }
.cart .shipping select { width:256px; }
.cart .shipping .input-text { width:250px; }
.cart .shipping p { margin:0 0 15px; }
.cart .shipping .buttons-set { width:256px; }
.cart .shipping .form-list label { float:none; width:auto; text-align:left; padding:0; }
.cart .shipping .form-list .input-text,
.cart .shipping .form-list select { display:block; float:none; }

.cart .crosssell { float:left; min-height:400px; width:260px; padding:0 20px 15px 30px; border:1px solid #d9d9d9; border-width:0 1px 0 0; background:#fbf3f0; }
.cart .crosssell h2 { font-size:11px; color:#cd3800; font-weight:bold; text-transform:uppercase; }
.cart .crosssell .item { padding:17px 0 0 ; }
.cart .crosssell .product-image { float:left; margin:3px -90px 0 0; }
.cart .crosssell .product-shop { margin-left:90px; }
.cart .crosssell .product-name,
.cart .crosssell .price-box,
.cart .crosssell p,
.cart .crosssell .add-to-links { margin:0 0 5px; }
.cart .crosssell .add-to-links li { margin:0; }

/* Shopping Cart Totals */
.cart .totals { float:right; width:275px; padding:23px 15px 0 15px; font-size:14px; }
.cart .totals table { width:100%; }
.cart .totals table th,
.cart .totals table td { padding:0 5px 5px; }
.cart .totals table tfoot strong { font-weight:normal; font-size:18px; color:#cd3800; display:block; margin:10px 0 15px; }
.cart .totals table tfoot .summary-total td { padding:0 0 20px; }
.cart .totals table tfoot th strong { margin:0; }
.cart .totals table tfoot td .price { font-weight:bold; }
.cart .totals .btn-remove { vertical-align:middle; }
.cart .totals .btn-remove img { vertical-align:baseline; }
.cart .totals .checkout-types li { margin:0 0 15px; }
.cart .totals .checkout-types .paypal-or { clear:both; display:block; padding-top:8px; line-height:1.0; font-size:11px; }

/* Options Tool Tip */
.item-options p { font-size:11px; font-weight:bold; text-transform:uppercase; padding:10px 12px 0 12px; }
.item-options dt { font-weight:bold; padding:7px 0 7px ; }
.item-options dd { padding-bottom:5px; }
.truncated { cursor:help; margin:5px 0 0; position:relative; z-index:1; display: none; }
.truncated a.dots { cursor:help; }
.truncated a.details { cursor:help; text-decoration:none; background:url(../images/bkg_dots.gif) repeat-x 0 100%; color:#514f4f; }
.truncated .truncated_full_value {}
.truncated .truncated_full_value .item-options { position:absolute; top:-99999em; left:-99999em; z-index:999; padding:0; width:190px; border:1px solid #c8c8c8; border-bottom:1px solid #919191; background-color:#fff; }
.truncated .truncated_full_value .item-options dl { margin-right:-9px; position:relative; z-index:9999; padding:0px 21px 10px 12px; background:url(../images/bkg_truncated.gif) no-repeat 100% 38px; }
.truncated .show .item-options { top:-20px; left:50%; }
.col-left .truncated .show .item-options { left:30px; top:7px; }
.col-right .truncated .show .item-options { left:-240px; top:7px; }
.top-cart .truncated .show .item-options { top:-60px; left:-206px; }
.block-cart .truncated .show .item-options { top:-60px; left:-206px; }

/* Item options in the cart */
.data-table .item-options dt { padding:3px 0 0 10px; background:url(../images/bkg_bulletsm.gif) 0 .8em no-repeat; }
.data-table .item-options dd { padding:0 0 3px 10px; }
.data-table .item-options dd .price { font-weight:bold; margin-left:5px; }
/* ======================================================================================= */


/* Checkout ============================================================================== */
/* One Page */
.checkout-onepage-index #social_sidebar { display: none; }

.opc-wrapper {  }
.opc-wrapper .page-title { margin:0; position:relative; z-index:1; }
.opc-wrapper .page-title .callout { position:absolute; top:7px; right:20px; z-index:1; }

/* Block: Checkout Progress */
.opc-progress-container { float:left; width: 23%; }
.opc-progress-container .opc-block-progress { background:#fff; }
.opc-progress-container .opc-block-progress .block-title { padding:5px 0 0; background:none; border:0; border-bottom:1px solid #cfcfcf; }
.opc-progress-container .opc-block-progress .block-title strong { padding:0; color:#111; }
.opc-progress-container .opc-block-progress .block-content { padding:10px 0; }
.opc-progress-container .opc-block-progress dt { font-weight:bold; margin:2px 0 8px; }
.opc-progress-container .opc-block-progress dt.complete { font-weight:bold; margin:2px 0 4px; }
.opc-progress-container .opc-block-progress dt.complete a { font-weight:normal; text-transform:lowercase; font-size: .8em; }
.opc-progress-container .opc-block-progress dd.complete { padding-bottom: 15px; font-size: .8em; line-height: 1.5em;}

.opc { float: right; width: 75%;  margin-bottom: 3em; }
.opc li.section { position:relative; }
.opc .step-title { margin:0 0 1px; }
.opc .step-title .number { display:none; }
.opc .step-title h2 { background-color:#e6e6e6; color:#686868; font-size:14px; line-height:28px; padding:0 14px; font-weight:bold; text-transform:uppercase; }
.opc .allow .step-title h2 { background-color:#000; color:#fff; cursor:pointer; }
.opc .active .step-title { margin:0 0 0; padding:0 0 0 0; }
.opc .active .step-title h2 { background:$jam_lightblue; color:#fff; text-transform:capitalize; font-weight:normal; border-bottom:1px solid #cfcfcf; cursor:default; font-size:16px; padding:5px 20px 2px; line-height:24px; height:24px; }
.opc .step { padding:20px; min-height:300px; font-size:1em; background:#fcfcfc; border-top:0; }
.opc .step .tool-tip { right:10px; }

/* Steps */
.opc .form-alt .input-box,
.opc .form-alt .input-box select { width:95%; }

#checkout-step-login { height:auto !important; min-height:0 !important; padding:0; }
#checkout-step-login .col2-set { padding:20px 20px 30px; background:#fcfcfc; height:auto !important; min-height:0 !important; }
#checkout-step-login .login-only { background-image:none; }
#checkout-step-login h4 { font-size:16px; color:#000; font-weight:normal; margin:0 0 15px; }
#checkout-step-login .col2-set .col-1 {}
#checkout-step-login .col2-set .col-2 .radio { margin-right:5px; }
#checkout-step-login .col2-set .col-2 li { padding-left:10px; background:url(../images/bkg_bulletsm.gif) no-repeat 0 0.5em; }
#checkout-step-login .col2-set .col-2 .form-list li { background:none; padding-left:0; }
#checkout-step-login .form-list label,
#checkout-step-login .form-list .label { width:95px; font-size: .8em; }
#checkout-step-login .btn-only { padding:0 0 0 105px; text-align:left; width:214px; }
#checkout-step-login .btn-only a { font-size: .8em; }
#checkout-step-login .btn-only .button { float:right; }
#checkout-step-login p { margin:0 0 15px; }
#checkout-step-login .form-list p { padding-left:105px; margin:0; }
#checkout-step-login .form-list .input-box { padding-right: 20px;  }
#checkout-step-login .form-list .input-text { width: 185px;  }

$checkoutform-offsetright: 10px;
.opc .buttons-set
.opc .form-buttons { width:auto; text-align:right; }
.opc .buttons-set p.back-link { text-align:left; }
.opc .form-buttons { width:auto; text-align:right; }
.opc .buttons-set .button,
.opc .form-buttons .button { float:right; margin:0; margin-right:$checkoutform-offsetright;}
.opc p.required { text-align:right; width:auto; padding-left:0; margin-right: $checkoutform-offsetright;}

#opc-billing .address-note,
#opc-shipping .address-note { clear: both; font-size: .9em; color: #0e7b9a; width: auto; line-height: 1.5em; }
.checkout-tip { color:#555; font-size: .9em; position:relative; z-index:1; }
.checkout-tip p { line-height: 13px; margin-top: 15px; }
.checkout-tip .checkout-help { position:absolute; visibility:hidden; top: 20px; left: 0px; padding: 5px 10px; background: #EAF6FF; color: #000; border: 1px solid #7BA7C9; }
.checkout-tip .checkout-help th { font-size: .9em; border-bottom: 1px dotted grey; }
.checkout-tip .checkout-help td { font-size: .9em; border-bottom: 1px dotted grey; }
.checkout-tip:hover .checkout-help { visibility:visible; cursor:pointer; z-index:99; }

.checkout-onepage-payment-additional-customerbalance { margin-bottom:5px; }
.checkout-onepage-payment-additional-customerbalance .checkbox { margin-left:0; }

#checkout-step-review { padding:0; }
#checkout-step-review .data-table { border:0; }
#checkout-step-review .data-table h3 { font-size: 1.05em; }
#checkout-step-review .data-table .item-options {  }
#checkout-step-review .data-table thead th { vertical-align:bottom;  }
#checkout-step-review .data-table .product-name {  }
#checkout-step-review .data-table tfoot tr.last strong {  }
#checkout-step-review .data-table .btn-remove { display:-moz-inline-stack; display:inline-block; vertical-align:middle; }

#checkout-step-review .buttons-set { padding:15px 20px; margin:0; }

.opc .checkout-agreements { margin:20px; }

/* Multiple Addresses Checkout */
.checkout-progress,
.checkout-progress li.first span,
.checkout-progress li.last span { background-image:url(../images/bkg_crumbs.gif); background-repeat:no-repeat; }
.checkout-progress { padding:0 0 0 0; border-bottom:1px solid #cfcfcf; background-color:#ededed; text-align:center; margin:-21px -21px 5px; font-size:11px; font-weight:bold; text-transform:uppercase; width:940px; position:relative; z-index:2; }
.checkout-progress li { float:left; width:20%; text-align:center; color:#888; line-height:20px; }
.checkout-progress li span { display:block; text-align:center; border-right:1px solid #cfcfcf; }
.checkout-progress li.first span { background-position:0 0; }
.checkout-progress li.last span { background-position:100% 0; border:0; }
.checkout-progress li.active span { color:#fff; background-color:#d12c01; }

.multiple-checkout { position:relative; z-index:1; }
.multiple-checkout .data-table thead th { vertical-align:bottom; }
.multiple-checkout .col3-set .actions { border:1px solid #e4e4e4; padding:3px 9px 4px; float:left; }
.multiple-checkout .col3-set address { padding:10px; clear:both; }
.multiple-checkout .col3-set,
.multiple-checkout .col2-set { margin:0 0 15px; }
.multiple-checkout .buttons-set { padding:15px 0; clear:both; width:auto; }
.multiple-checkout .buttons-set .button { float:right; }
.multiple-checkout .tool-tip { top:50%; margin-top:-120px; right:10px; }
.multiple-checkout .grand-total { font-weight:normal; font-size:18px; color:#cd3800; text-align:right; display:block; margin:10px 0 15px; }
.multiple-checkout .grand-total .price-label { font-weight:normal; }
.multiple-checkout .grand-total .price { font-weight:bold; }
.multiple-checkout .sp-methods { font-size:12px; }
.multiple-checkout .checkout-multishipping-payment-customerbalance { font-size:12px; margin-bottom:7px; }

/* Step 4 */
.checkout-multishipping-overview .data-table .btn-remove { display:-moz-inline-stack; display:inline-block; vertical-align:middle; }

/********** < Common Checkout Styles */
/* Shipping and Payment methods */
.sp-methods dt { font-weight:bold; margin-bottom:5px; }
.sp-methods dd { margin:0 0 7px; }
.sp-methods dd li { margin:5px 0; }
.sp-methods .price { font-weight:bold; padding-left: 20px; }
.sp-methods .form-list { padding-left:20px; margin:15px 0; }
.sp-methods .form-list .input-text { width: 200px; margin-right: 10px; margin-top: -20px;}
.sp-methods .form-list select { width: 250px; margin-top: -20px; }
.sp-methods .form-list label { width: 225px; font-size: .9em; }

.sp-methods .form-list#payment_form_paypal_direct .input-text { margin-top: 0px; }
.sp-methods .form-list#payment_form_paypal_direct select { margin-top: 0px; }

.sp-methods select.month { width:150px; margin-right:10px; }
.sp-methods select.year { width:96px; }
.sp-methods input.cvv { width:3em !important; }
.sp-methods .input-box { width: 510px;  }
.sp-methods .radio,
.sp-methods .checkbox {}
.sp-methods .v-fix .validation-advice { margin-right:-250px; position:relative; z-index:1; width:250px; }
.sp-methods .adv-container { padding-left:170px; }
.sp-methods .centinel-logos a { margin-right:3px; }
.sp-methods .centinel-logos img { vertical-align:middle; }

#cybersource_soap_expiration { float: none;  }
#cybersource_soap_expiration_yr { float: none; }

select#cybersource_soap_cc_type, input#cybersource_soap_cc_number, #cybersource_soap_cc_cid, #cybersource_soap_expiration, #cybersource_soap_expiration_yr { margin-top: 0; }
#checkout-payment-method-load .form-list label { float: none; }

#checkout-payment-method-load .form-list#payment_form_paypal_direct label { float: left; text-align: left; margin-top: 6px; margin-bottom: 6px; }


.checkmo-list li { margin:0 0 4px; }
.checkmo-list label { padding-top:0; }
.checkmo-list address { float:left; }

.please-wait { position:absolute; z-index:99; top:50%; left:50%; margin:-34px 0 0 -90px; border:5px solid #f3b66f; font-size:12px; color:#f18200; font-weight:bold; background:#fff; padding:20px; white-space:nowrap; }
.cvv-what-is-this { cursor:help; margin-left:5px; }

/* Payment Bridge */
.pbridge-reload { display:block; text-align:right; }
.pbridge-reload a { color:#1e75b4; font-size:11px; }

/* Tooltip */
.tool-tip { border:1px solid #ddd; background-color:#f6f6f6; padding:5px; position:absolute; z-index:9999; }
.tool-tip .btn-close { text-align:right; }
.tool-tip .tool-tip-content { padding:5px; }

/* Gift Messages */
.add-gift-message { padding:20px 0; }
.add-gift-message h3 { font-size:16px; font-weight:normal; color:#000; line-height:19px; margin:0 0 10px; }
.add-gift-message p { margin:10px 0; }

.gift-message-form { border:1px solid #d1d1d1; background:#f5f5f5 url(../images/bkg_block.gif) repeat-x 0 0; }
.gift-message-form .fieldset { padding:10px 0 10px; }
.gift-message-form .fieldset .input-text,
.gift-message-form .fieldset textarea { width:200px; }
.gift-message-form .fieldset textarea { width:200px; height:8em; }
.gift-message-form .inner-box { padding:0; height:300px; overflow:auto; }
.gift-message-form .inner-box h5,
.gift-message-form .inner-box h4 { font:14px/1.25 Arial, Helvetica, sans-serif; padding:0 20px; color:#222; margin:17px 0; }
.gift-message-form .inner-box p { margin:10px 0; padding:0 20px; }
.gift-message-form .gift-item { padding:5px 0 0; background:url(../images/bkg_dots.gif) repeat-x 0 100%; }
.gift-message-form .gift-item .number { padding:5px 20px 5px; margin-bottom:0; background:url(../images/bkg_dots.gif) repeat-x 0 100%; color:#adadad; font-weight:bold; }
.gift-message-form .gift-item .product-img-box { float:left; width:150px; margin-right:15px; }
.gift-message-form .gift-item .product-image { text-align:center; margin:20px; }
.gift-message-form .gift-item .product-name { font-size:12px; margin:20px 0 5px; text-align:center; }
.gift-message-form .gift-item .fieldset { float:left; background:url(../images/bkg_dots.gif) repeat-y 0 0; padding:20px 0 10px 10px; margin:0; }
.gift-message-form .gift-item .fieldset label { width:80px; }

.gift-message-link { display:block; background:url(../images/bkg_collapse.gif) 0 4px no-repeat; padding-left:20px; }
.gift-message-link.expanded { background-position:0 -53px; }
.gift-message-row {}
.gift-message-row .btn-close { float:right; width:13px; height:12px; background:url(../images/gift-message-close.gif) 0 0 no-repeat; text-indent:-999em; overflow:hidden; }
.gift-message dt strong { font-weight:bold; }

/* Checkout Agreements */
.checkout-agreements {}
.checkout-agreements li { margin:10px 0; }
.checkout-agreements .agreement-content { border:1px solid #d8cea8; font-size:11px; background-color:#fffdef; padding:15px; height:10em; overflow:auto; }
.checkout-agreements .agree { padding:10px 0; font-size:12px; font-weight:bold; }
.checkout-agreements .agree .checkbox { margin-right:5px; }

/* Centinel */
.centinel { margin:10px 0; }
.centinel p { margin:0 0 10px; }
.centinel .authentication { border:1px solid #ddd; background:#fff; }
.centinel .authentication iframe { width:99%; height:400px; background:transparent !important; margin:0 !important; padding:0 !important; border:0 !important; }

.opc .centinel { margin:0; padding:20px; }

/* Checkout Success */
.checkout-onepage-success .col-main {  }
.checkout-onepage-success .col-main p { margin:0 0 10px; }
/********** Common Checkout Styles > */
/* ======================================================================================= */


/* Invitation page ======================================================================= */
#invitationForm p.required { padding-bottom:20px; }


/* Compare popup ========================================================================= */
.catalog-product-compare-index tr.last a,
.catalog-product-compare-index tr.first a { color:#999; }
.catalog-product-compare-index tr.last p,
.catalog-product-compare-index tr.first p { margin:10px 0; }
.catalog-product-compare-index tr.first .product-name a { color:#333; }

.catalog-product-compare-index.page-popup tr.last a,
.catalog-product-compare-index.page-popup tr.first a{font-size:12px;float:none;clear:both;padding:5px 0;}

/* Account Login/Create Pages ============================================================ */
.account-login { position:relative; min-height:350px; }

.login-only { background:none; }
.login-only .registered-users { float:none; margin:0 auto; }

.account-login .new-users,
.account-login .registered-users { width:381px; padding:30px 32px 30px; background: #eee; }
.account-login .registered-users p { margin-bottom:1.5em; }
.account-login .spacer { position:absolute; top:100%; left:447px; margin-top:-1px; width:16px; height:3px; font-size:0; line-height:0; background:#fff; overflow:hidden; }
.account-login .fieldset { margin:0;}
.account-login .fieldset h2 { margin-bottom:.5em; }
.account-login .form-list label,
.account-login .form-list span.label { width:98px; }
.account-login p.required,
.account-login .btn-only { padding-left:113px; width:230px; text-align:right; }
.account-login .btn-only a { float:left; white-space:nowrap; }
.account-login .form-list input.input-text { width:224px; }
.account-login .form-list p { padding:0 0 0 110px; }


/* My Account ============================================================================= */
/********** < Dashboard */
.dashboard {}
.dashboard .welcome-msg,
.dashboard .col2-set,
.dashboard .recent-orders { padding:0 0 20px; }

/* Block: Tags */
.dashboard .tags { padding:0 0 20px; }
.dashboard .tags p { display:inline; }
.dashboard .tags .product-name { margin:0 0 3px; font-size:11px; line-height:17px; }
.dashboard .tags li { margin:0 0 8px; }
.dashboard .tags .number { float:left; border:1px solid #ddd; text-align:center; line-height:17px; width:15px; margin-right:-20px; }
.dashboard .tags .details { margin-left:25px; }

/* Block: Reviews */
.dashboard .reviews { padding:0 0 20px; }
.dashboard .reviews .product-name { margin:0 0 3px; font-size:11px; line-height:17px; }
.dashboard .reviews p { float:left; margin-right:5px; }
.dashboard .reviews li { margin:0 0 5px; }
.dashboard .reviews .number { float:left; border:1px solid #ddd; text-align:center; line-height:17px; width:15px; margin-right:-20px; }
.dashboard .reviews .details { margin-left:25px; }

/********** Dashboard > */

/* Adress Book */
.addresses-list .box-content { font-size:12px; }
.addresses-list .col-2 li { padding:0 0 10px; }
.addresses-list .col-2 li .actions { border:1px solid #e4e4e4; padding:3px 9px 4px; float:left; }
.addresses-list .col-2 li address { padding:10px; clear:both; }

/* Order View */
.order-page {}
.order-page .order-date { margin:0 0 20px; color:#6c6c6c; font-size:11px; }
.order-page .col2-set { padding-bottom:20px; }

.order-details .link-print { background:none; padding:0; }
.order-details .order-additional,
.order-details .data-table { margin-bottom:20px; }
.order-details .order-additional dt { font-weight:bold; background:url(../images/bkg_dots.gif) repeat-x 0 100%; padding:0 0 5px; margin:0 0 5px; }
.order-details .order-additional dd { padding:10px; margin:0 0 10px; }

.order-details dl.gift-message { margin:0 20px; }
.order-details .gift-message dt { margin:1px 0; padding:2px 10px; background:#e6e6e6; }
.order-details .gift-message dd { border:1px solid #c7c7c7; background:#fff; padding:10px; margin:0 0 10px; }

.order-links { margin:0 0 10px; text-align:right; padding-bottom:5px; background:url(../images/bkg_dots.gif) repeat-x 0 100%; text-align:right; }
.order-info { margin-bottom:5px; border-bottom:1px solid #c4c4c4; }
.order-info dt { padding:6px 20px 0 10px; color:#6c6c6c; float:left; }
.order-info dd { float:left; }
.order-info ul { float:left; }
.order-info li { float:left; position:relative; z-index:2; margin-right:3px; background:#dedede url(../images/bkg_collateral-tabs.gif) no-repeat 0 -39px; color:#222; padding:0 0 0 17px; font-size:12px; }
.order-info li strong,
.order-info li a { text-decoration:none; background:url(../images/bkg_collateral-tabs.gif) no-repeat 100% -39px; padding:0 17px 0 0; line-height:24px; float:left; }
.order-info li.current { margin-bottom:-1px; color:#000; font-weight:bold; z-index:3; border-bottom:0; background:#fff url(../images/bkg_collateral-tabs.gif) 0 0 no-repeat; }
.order-info li.current strong { background-position:100% 0; padding-bottom:1px; }
.order-info dt { font-weight:bold; }

/* Manage Newsletter Subscription */
.newsletter-manage-index .form-buttons .button { float:left; margin:0; }

/* Wishlist */
.my-wishlist textarea { width:99%; height:10em; }
#wishlist-table .price-box { margin:3px 0; }
#wishlist-table button.btn-cart { margin:3px 0; }

/* Store credit */
.storecredit .account-balance { margin:0 0 20px; padding:0 0 10px; font-size:12px; background:url(../images/bkg_dots.gif) repeat-x 0 100%; }
.storecredit .account-balance .price { font-weight:bold; }
.giftcard-redeem { padding-bottom:20px; }
.giftcard-redeem .input-box { margin-right:5px; }

/* Gift card */
.giftcardaccount { position:relative; z-index:1; padding-top:1px; }
.giftcard-status .messages,
.giftcardaccount .messages { margin-bottom:10px; }
.giftcardaccount p { margin:15px 0; font-size:12px; }
.giftcardaccount .fieldset { margin:0; }
.giftcardaccount .btn-only { margin:0 0 15px; }

/* Gift Card Status Check */
.giftcard-status .gift-card-info,
.giftcard-status p.error-msg,
.giftcardaccount #giftcard_balance_lookup .gift-card-info,
.giftcardaccount #giftcard_balance_lookup p.error-msg { margin:10px 0 20px; }
.gift-card-info { margin:10px 0; padding:10px 10px 5px; border:1px dashed #d9d9d9; background:#fcf7f5; }
.gift-card-info p { font-size:12px; margin:0 0 5px; }
.gift-card-info .giftcard-number { font-weight:bold; }
.gift-card-info .form-list label {}
.gift-card-info .btn-only { padding-left:0; }

#gc-please-wait { -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=80)"; -moz-opacity:0.8; opacity:0.8; }

/* Print Pages */
.page-print .print-head {}
.page-print .print-head img { float:left; }
.page-print .print-head address { float:left; margin-left:15px; }
.page-print h2.h2 { margin:15px 0 10px; }
.page-print .col2-set { margin:10px 0 20px; }

/* Price Rewrites */
.page-print .gift-message-link { display:none; }
.page-print .price-excl-tax,
.page-print .price-incl-tax { display:block; white-space:nowrap; }
.page-print .cart-price,
.page-print .price-excl-tax .label,
.page-print .price-incl-tax .label,
.page-print .price-excl-tax .price,
.page-print .price-incl-tax .price { display:inline; }

/* Tagged products */
.my-tag-edit { float:left; }
.my-tag-edit .btn-remove { float:right; margin:-3px 0 0 5px; position:relative; }
#my-tags-table .price-box { margin:5px 0; }
#my-tags-table .last { text-align:center; }
#my-tags-table .last p { margin:10px 0; }

/* Reward Points */
.my-rewards .box { margin:0 0 30px; }
.my-rewards .info-box .box-title { margin:10px 10px; }
.my-rewards .info-box .box-content { padding:0 10px 10px; }
.my-rewards p.error { display:inline; color:#d91a00; }
.my-rewards dl { font-size:12px; }
.my-rewards dl dt { font-weight:bold; font-size:13px; margin-top:10px; }
.my-rewards dl dt span { color:#d12c01; }
.my-rewards dl dd.last { margin:7px 0 0; }
.my-rewards .form-list label { width:auto; }
.my-rewards .form-list .control .input-box { position:static; margin:0 5px 0 0; }
.my-rewards .data-table small { display:block; font-size:1em; color:#808080; }

/* Billing Agreements */
.billing-agreements .info-box { margin:15px 0; }
.billing-agreements .info-box p { margin:0 0 9px; }
.billing-agreements .form-list li button.button { float:left; margin-left:10px; }

/* Gift Registry Functionality */
.btn-gift-registry { background:url(../images/i_gift-registry3.gif) 0 0 no-repeat; padding:3px 0 0 33px; text-align:left; }
.btn-gift-registry .input-box { margin:0 0 7px; }
.btn-gift-registry select { width:100%; }
.gift-registry-name { background:url(../images/i_gift-registry2.gif) 0 0 no-repeat; margin:3px 0 0; padding:0 0 0 20px; line-height:18px; }
/* Block: Gift Registry Search */
.sidebar .block-gr-search .form-alt .input-box { width:148px; margin:3px 0 0; padding:0; }
.sidebar .block-gr-search .form-alt input.input-text { width:142px; }
.sidebar .block-gr-search .form-alt select { width:148px; }
.block-gr-search p.note { margin:0 0 3px; text-align:right; color:#f15c22; }
/* ======================================================================================= */


.contacts-index-index .col-main { margin: 0 0 2em 0; }
.contacts-index-index .page-title { padding-bottom: 0; }
.contacts-index-index fieldset { margin: 2em 0; }
.contacts-index-index .legend { display: none; }
.contacts-index-index .group-select .field-label,
.contacts-index-index .group-select label { float: left; clear: left; width: 175px; text-align: right; margin: 0 .5em .5em 0; }
.contacts-index-index .group-select input,
.contacts-index-index .group-select textarea { float: left; width: 250px; }
.contacts-index-index .note { clear: left; width: 410px; margin-left: 185px; font-size: .9em; line-height: 1.2em; }
.contact-list { margin-top: 7em;  }
.contact-list dt { font-weight: bold;  }

/* products/category page */
img.responsive { display: none; }
.catalog-category-view .col-main { padding-bottom: 0; }



/* Clears ================================================================================ */
.product-options dt:after,
#bundleProduct:after,
.events-slider:after,
.product-image-popup .nav:after,
.gift-item:after,
.block-content:after,
.block-list li.item:after,
.block-poll li:after,
.currently li:after,
.order-info:after,
.top-cart .actions:after,
.header .quick-access:after,
.product-view .box-related li.item:after,
.bundle-summary .product:after,
.collateral-tabs:after,
.block-cart .block-content .item:after,
.clearer:after,
.header-container:after,
.header-container .top-container:after,
.header:after,
.header-panel:after,
#nav:after,
.main:after,
.footer-before-container:after,
.footer-container:after,
.footer:after,
.col-main:after,
.col2-set:after,
.col3-set:after,
.col4-set:after,
.search-autocomplete li:after,
.block li.item:after,
.block .actions:after,
.mini-products-list li:after,
.cms-index-index .block-featured li.item:after,
.title-buttons:after,
.pager:after,
.sorter:after,
.ratings:after,
.add-to-box:after,
.add-to-cart:after,
.products-grid:after,
.products-list li.item:after,
.product-view:after,
.product-view .product-img-box .more-views ul:after,
.product-view .box-tags .form-add-tags:after,
.product-options .options-list li:after,
.options-container-small .product-options:after,
.product-options-bottom:after,
.product-review:after,
.box-collateral:after,
.box-collateral .box-content:after,
.collateral-tabs .tab-content:after,
.account-login:after,
.cart .cart-collaterals:after,
.cart:after,
.opc .step-title:after,
.checkout-progress:after,
.form-list li:after,
.form-list li.fields .field:after,
.clearfix:after,
.buttons-set:after,
.buttons-set2:after,
.page-print .print-head:after,
.new-product-spotlight .more-new .item:after,
.addresses-list .col-2 li:after,
.product-view .product-shop:after { display:block; content:"."; clear:both; font-size:0; line-height:0; height:0; overflow:hidden; }


/* home page specific */
#brandStatement { background: #000; min-height: 125px; overflow: auto; padding: 50px 0;}
#brandStatement h3 { float: left; width: 195px; padding-left: 100px; }
#brandStatement p { float: right; width: 550px; padding-right: 50px; color: #fff; font-size: 14px; line-height: 32px; padding-top: 5px;}
#banners { overflow: auto; padding: 50px 0; }
#banners h4 { text-transform: uppercase; font-size: 26px; margin-bottom: 20px; font-weight: 100; }
#banners img { }
#banners .threeColBanners { margin-bottom: 25px; overflow: auto; }
#banners .threeColBanners h6 { display: none; }
#banners .threeColBanners a.banner { height: 170px; background: url('../images/sprites/allBanners.jpg') no-repeat; display: block; width: 300px; float: left; margin-right: 27px; position: relative; border: 1px solid #000;}
#banners .threeColBanners a.banner.last { margin-right: 0; }
#banners .threeColBanners a.banner:hover { background-position: 0 -170px; }
#banners .threeColBanners a.banner.storm { background-position: -300px 0; }
#banners .threeColBanners a.banner.storm:hover { background-position: -300px -170px; }
#banners .threeColBanners a.banner.street { background-position: -600px 0; }
#banners .threeColBanners a.banner.street:hover { background-position: -600px -170px; }

#banners p.caption { position: absolute; top: 75px; left: 35px; display: none; font-size: 26px; text-transform: uppercase; color: #fff;  font-weight: bold;}
#banners .twoColBanners { clear: both; }
#banners .twoColBanners a { float: left; position: relative; }
#banners .threeColBanners a:hover p.caption { display: block; }
p.caption:hover .threeColBanners img { opacity: 0.4; }
#banners .twoColBanners p.caption { display: block; top: 130px; left: 150px; }
#banners .twoColBanners p.caption.speakers { left: 102px; }

#banners a.banner {  }

/* product page feature image */
.page-bottom-wrapper { padding-top: 375px; }

.product-jam-classic-wireless-bluetooth-speaker .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_Classic.jpg') no-repeat center top; }
.product-jam-touch-wireless-speaker .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_Touch.jpg') no-repeat center top; }
.product-jam-fusion-wireless-headphones .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_Fusion.jpg') no-repeat center top; }
.product-jam-party-wireless-bluetooth-stereo-boombox .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_Party.jpg') no-repeat center top; }
.product-jam-plus-wireless-bluetooth-speaker .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_Plus.jpg') no-repeat center top; }
.product-jam-rewind-wireless-pocket-speaker .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_Rewind.jpg') no-repeat center top; }
.product-jam-splash-shower-speaker .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_Splash_blue.jpg') no-repeat center top; }
.product-jam-storm-wireless-speaker .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_Storm.jpg') no-repeat center top; }
.product-jam-street-rugged-portable-speaker .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_Street.jpg') no-repeat center top; }
.product-jam-transit-wireless-earbuds .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_TransitEB.jpg') no-repeat center top; }
.product-jam-transit-bluetooth-headphones .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_TransitHP.jpg') no-repeat center top; }
.product-jam-xt-extreme-ruggedized-wireless-speaker .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_XT.jpg') no-repeat center top; }
.product-jam-zzz-bluetooth-alarm-clock .page-bottom-wrapper { background: url('../images/product_page_features/Cascade_Zzz_purp.jpg') no-repeat center top; }

.header-promo-bar { background: #00adef; }
.header-promo-bar .content { width: 960px; margin: 0 auto; color: #fff; font-size: 14px; padding: 5px 0; }


/* temporary */

.cms-index-index .fullwidthbanner img { width: 960px; margin: 0 auto; display: block; }



/*   MOBILE NAVIGATION    */
.jamburger { display: none; }


.window-width { display: none; width: 700px; }


/* ======================================================================================= */

/************************************************************************************
MEDIA QUERIES
*************************************************************************************/

/* for 1120px or less */
@media screen and (max-width: 1120px) {
   #social_sidebar { display: none; }
}

// adressing overflow in iPad portrait
.checkout-onepage-index .col1-layout,
.customer-account-index .col2-left-layout,
.customer-address-index .col2-left-layout,
.customer-account-edit .col2-left-layout,
.review-customer-index .col2-left-layout,
.newsletter-manage-index .col2-left-layout,
.sales-order-history .col2-left-layout,
.sales-order-view .col2-left-layout,
.customer-address-form .col2-left-layout,
.checkout-onepage-success .col1-layout { width: 90%; max-width:1000px; padding-right: 5px;}
@media screen and (max-width: 980px) {

  $font-size-table-small: 11px;
  .checkout-onepage-index {

    #checkout-step-login .col2-set {
      background: none;
    }

    .opc .step {
      padding-left: 2px;
      padding-right: 2px;
    }

    fieldset {
      min-width: 0;
      max-width: 350px;
      margin: 0 auto;
    }

    .form-list {
      $labelwidth: 125px;
      label {
        width: $labelwidth !important; // we do what we must because we can
      }
      .control .input-box {
        margin-left: $labelwidth + 40px;
      }
      .add-field .input-box {
        margin-left: 0;
      }
    }

    .sp-methods .form-list .input-box {
      width: auto;
    }
    .sp-methods .form-list .input-text {
      margin-top: 0;
    }

    #checkout-step-review .data-table {
      td{
        padding:5px;
        font-size:$font-size-table-small;
      }
    }

  }

  .customer-account-login {
    .col1-layout {
      width: 100%;
    }

    .account-login .fieldset {
      margin: 0 auto;
      max-width: 90%;
    }

    .col2-set {
      .col-1,
      .col-2 {
        float: none;
        width: 90%;
        max-width: 600px;
        margin: 30px auto;
        padding: 20px;
      }
    }
  }

  .customer-account-index,
  .sales-order-history {
    td,
    th{
      font-size: $font-size-table-small;
      padding: 5px;
    }
  }

  .customer-account-edit {
    p.required,
    .form-buttons {
      width: auto;
    }
  }


  .sales-order-view{
    td{
      font-size: calc(6px + 0.6vw);
    }
  }


  .customer-address-form{
    .form-buttons{
      width: auto;
    }
    p.required{
      width: auto;
    }
    .form-list{
      .add-field .input-box{
        margin-left:0;
      }
      .control .input-box{
        margin-left: 280px;
      }
      label{
        float:none;
      }
      .input-box{
        float:none;
      }
    }


  }


}


/* tablet portrait and below */
@media screen and (max-width: $bp_medium_legacy) {

  .cart .data-table .btn-remove{
    font-size: 1rem;
    height: 1.5rem;
    line-height: 1.5rem;
    text-indent: 0;
    background-image: none;
    width: auto;
    text-align: left;
  }

  #shopping-cart-table{
    td.a-right:nth-child(3),td.a-right:nth-child(5){
      .cart-price{
        .price:before{
          content:"";
          font-size: 1rem;
          vertical-align: top;
          padding: 0 .5rem;
          font-weight: bold;
        }
      }
    }
    td.a-right:nth-child(3){
        .cart-price{
          .price:before{
            content:"Subtotal";
          }
        }
    }
    td.a-right:nth-child(5){
      .cart-price{
        .price:before{
          content:"Total";
        }
      }
    }
  }

  /* fonts */
  h1 { font-size: 1.6em; }
  h2 { font-size: 1.4em; }

  /* set fluid widths and remove floats for main layout divs */
  .header-panel-contents,
  #nav,
  .header,
  .section-content .one-half,
  .footer,
  .col-main { width: 100%; }
  .section-content .one-half { float: none !important; }

  body { height: 100%; width: 100%; }

  .container { width: 100%; }

  .section-content,
  .footer-container,
  .col1-layout,
  .col2-left-layout,
  .col2-right-layout { width: 96%; padding: 2%; }

  /* set appropriate padding */
  .header-panel { padding: 2%; }
  .section-container { padding: 0; }

  /* header */
  .header { margin-top: -5px; }
  .header-panel .welcome-msg {  float: none;  }
  .header .quick-access .links { display: none; }
  .branding { position: static; margin: 0; height: auto; top: auto; left: auto; }
  .branding .logo { float: none; }
  .branding .logo a { width: 94px; height: 43px; }
  .branding h1 { margin: 0; line-height: 0; }
  .branding h1 img, .branding img { display: block;  margin: 0 auto; }
  .header .quick-access { position: absolute; width: 90%; top: 90px; right: 0; }
  .top-cart { position: absolute; top: -86px; float: none; right: 10px;; height: 51px; }
  img.desktopLogo { display: none !important; }
  img.responsiveLogo { display: block !important; }
  #countrySelect { display: none; }
  .branding { width: auto; float: none; height: auto; margin-top: 5px; }
  ul.links { display: none; }
  .branding { padding-top: 0; }
  .header #search_mini_form { float: none; display: block; margin: 0; box-sizing: border-box; width: 100%; }
  .header .form-search { position: initial; float: none; width: 100%; box-sizing: border-box; }
  .topActions { width: 100%; padding: 10px 20px 0px 20px; float: none; box-sizing: border-box; }
  .header .form-search input { height: 24px; width: 100%; box-sizing: border-box; }
  .header .form-search input.input-text { width: 100%; box-sizing: border-box; }
  .header .form-search button { line-height: 20px; }

  /* navigation */
  .nav-container { margin-top: -68px; z-index: auto; width: 100%; margin-left: 0!important; }

  /*   MOBILE NAVIGATION */
  .jamburger { font-size:90px; display:block; }
  a.jamburger { position: absolute; top:-38px; left:7px; }
  a.jamburger:hover { text-decoration:none; cursor:pointer;  }


  /* responsive side navigation */
  #simple-menu { display: block; }
  #sidr ul li { text-align: left; float: none; }
  a#simple-menu { display: block; width: 39px; height: 27px; background: url('../images/navIcon_small.png') no-repeat center; position: relative; padding: 20px 2% 0; z-index: 5; }
  #sidr ul li { background: url('../images/backgrounds/rightarrow.png') no-repeat 95% center; }

  /* footer */
  .footer .footer-nav { float: none; width: 100%; }
  .form-subscribe input.input-text { width: 180px; }
  .footer .footer-links1, .footer .footer-links2, .footer .footer-links3 { width: 48%; }
  .footer .footer-links3 { float: none; padding-top: 0; clear: both; }
  .form-subscribe button.button { float: left; }
  address.copyright { text-align: center; }
  .footer-social { margin-left: -50px; }
  select.country { width: 80%; margin-left: 10%; }

  /* home page */
  .cms-index-index .one-half img { width: 100%; height: 100%; }
  .cms-index-index .col1-layout { padding: 0; }
  .cms-index-index .section-container.on-dark { background-image: none !important; }
  .section-content.highlight { min-height: 1px !important; margin: 0 -2%; width: 102%; padding: 7% 0 !important; margin-bottom: -75px; }
  .cms-index-index img.responsive { margin-top: -24px; margin-bottom: 12px; }
  #brandStatement h3 { display: none; }
  #brandStatement h3.resp { display: block !important; width: 96%; padding-left: 2%; float: none; text-align: center; font-size: 26px; }
  #brandStatement p { width: 90%; margin-left: 5%; padding-right: 0; float: none; }
  #banners { padding: 3% 0; }
  #banners h4 { text-align: center; }
  #banners .threeColBanners a.banner { float: none; margin-right: 0; margin: 0 auto 10px; }
  #banners .threeColBanners a.banner.last { margin-right: auto; }
  #banners .twoColBanners a { float: none; }
  #banners .twoColBanners img { width: 96%; margin-left: 2%; margin-bottom: 5px; }
  #banners .twoColBanners p.caption.speakers { left: 65px !important; }
  .cms-index-index .fullwidthbanner img { width: 100%; }
  #banners .threeColBanners h6 { display: block; position: absolute; bottom: 6px; left: 100px; color: #000; font-weight: 100; text-align: center; font-size: 20px; }
  #banners .threeColBanners a.banner:hover { background-position: 0 0; }
  #banners .threeColBanners a.banner.storm:hover { background-position: -300px 0; }
  #banners .threeColBanners a.banner.street:hover { background-position: -600px 0; }
  #banners .twoColBanners p.caption { left: 60px !important; top: 75px; }

  /* products page */
  .catalog-category-view .col1-layout { padding: 0; }
  .product-shop { padding: 2%; }
  .category-view .section-content .two-thirds,
  .category-view .section-content .one-third { float: none; width: 100%; text-align: center; }
  .category-view .section-content .two-thirds .a-center,
  .category-view .section-content .one-third .a-center { border-bottom: 1px solid #eee; padding: 2% 0; margin-bottom: 2%; }
  p.mobile-name { display: block; font-size: 24px; font-weight: 100; padding: 4% 2%; text-align: center; text-transform: uppercase; }
  .product-view .product-shop h1 { display: none; }
  .product-view .product-shop .product-ids { font-weight: bold; }
  .category-view .one-half img, .category-view .one-third img { margin-left: 20%; width: 60%; }
  .category-view .one-half img.swatch, .category-view .one-third img.swatch { width: 30%; margin: 0 0 0 35%; }
  .category-view .one-half img.swatch.small, .category-view .one-third img.swatch.small { width: 10%; margin: 0 0 0 45%; }
  .category-view .one-half,
  .category-view .one-third,
  .category-view .one-half p,
  .category-view .one-third p,
  .category-view .two-thirds h2 { text-align: center; width: auto; }
  .category-view h2 { border-bottom: none; width: 100%; }
  p.classic-price { padding-left: 0 !important; }
  .products-highlight { display: none; }
  .window-width { width: 699px; }


  /* real category pages */
  .products-list { width: 100%; }
  .products-list .product-image { width: 100%; float: none; }
  .products-list .f-fix { width: 100%; float: none; }

  /* pdp */
  .catalog-product-view .col1-layout { padding: 0; }
  .product-view .product-img-box,
  .page-bottom { width: 100%; float: none; }
  .section-content .two-thirds { width: 100%; height: 100%; }
  .product-essential { float: none; width: 100%; }
  .collateral-tabs .tab-content .feature-icon { display: none; }
  .collateral-tabs .tab-content .feature-desc { padding-left: 0; }
  .product-collateral .section-content .one-third { display: none; }
  .product-collateral .section-content .two-thirds img { display: none; }
  .product-view .product-shop { clear: both; }
  .product-view .product-shop { width: 96%; }
  .product-view .product-img-box .more-views ul { padding: 0 2%; }
  .product-view .product-shop .buy-info { width: 100%; }
  .catalog-product-view .tab-content h5 { display: block; margin-bottom: 10px; font-weight: 100; text-transform: uppercase; }
  .product-tabs { display: none; }
  .catalog-product-view .tab-content { display: block; }
  .tab-content { width: 96%; float: none; min-height: 1px; }
  .catalog-product-view .tab-content.features ul { padding-left: 0; }
  #titleBar h5 { font-size: 22px; }
  .product-review .review-form { display: none; }
  .page-bottom-wrapper { padding-top: 0; }
  .product-collateral { margin-top: 0; }
  .product-collateral .reviews { display: none; }
  .product-view .box-up-sell .product-name a { color: #000; }

  /* product reviews page */
  .product-review .review-summary { float: none; }
  .review-product-list .breadcrumbs { display: none; }
  .product-review .box-reviews { width: 100%; float: none; }
  .product-review .buttons-set { padding: 0; margin: 0; }
  .product-review .product-img-box { float: none; width: 100%; }
  .product-review .product-img-box .product-image img { width: 100%; }

  /* videos page */
  .section-content .one-third { width: 100%; float: none; }
  .category-jam-videos .section-content .one-third img { display: none; }
  iframe.desktop { display: none; }
  iframe.mobile { display: block; margin: 0 auto; }
  .video-highlight { display: none; }

  /* cms page global styles */
  .col3-layout { width: 96%; padding: 2%; }
  .col3-layout .col-wrapper,
  .col3-layout .col-wrapper .col-main,
  .col-left { float: none; width: 100%; }
  .sidebar { min-height: 1px; }
  .cms-menu { background: #eee; border-top: 1px solid #d1d1d1; padding: 2%; }

  /* forms */
  div.field { float: none; width: 100%; }
  .form-list .input-box { float: none; }
  .form-list label { float: none; width: 100% !important; }
  .form-list .add-field .input-box { margin-left: 0; }
  .form-list textarea { width: 96%; height: auto; }

  /* customer account section */
  .account-login .registered-users { border-bottom: 1px solid #ccc; margin-bottom: 3%; }
  .col2-set .col-1 { float: none; }
  .account-login .form-list input.input-text,
  .account-login .btn-only,
  .form-list input.input-text,
  .fieldset.personal li.control label,
  .fieldset.contactInfo .form-list input.input-text,
  .fieldset.address .form-list input.input-text,
  .fieldset.address .form-list select { width: 96%; }
  .fieldset.personal,
  .fieldset.login,
  .col-left,
  .col2-left-layout .col-main,
  .col2-set .col-1,
  .col2-set .col-2,
  .fieldset.contactInfo,
  .fieldset.address { float: none; width: 100%;  }
  .sidebar, .sidebar .block-account { min-height: 10px; width: 100%; }
  .col-left { margin-left: 0; border-right: none; }
  .sidebar .block { padding: 2%; }
  .sidebar .block-account { background: #eee; }
  .sidebar .block-account .block-title { padding: 0; }
  .sidebar .block-account li a, .sidebar .block-account li.current { padding: 5px 0; }
  .info-box .box-title { margin: 0; }
  .info-box .box-content { padding: 0; }
  .info-box { margin-bottom: 20px; }
  .col2-set { margin-bottom: 0; }
  .dashboard .welcome-msg { padding: 0 0 15px }
  .account_login_container,
  .account-create-main, .dashboard { padding: 2%; }
  .form-buttons { width: 100%; }
  .col-left.sidebar .block { padding: 0; }
  .sidebar .block-account li.current { padding: 5px 12px; }
  .account .form-list input.input-text, .form-list select { width: 100% !important; }
  .account-login p.required,
  .account-login .btn-only { padding: 0; width: 100%; }
  .account-login { background: none; }
  p.required { width: 100%; }
  li.control label { float: left; width: auto !important; }
  .info-box { border: none; border-bottom: 1px solid #ccc; }

  /* shopping cart page */
  .cart .cart-collaterals { clear: both; width: auto; }
  .cart .deals, .cart .shipping { width: 100% !important; float: none; }
  .cart .shipping { display: block; margin: 0; }
  .cart .shipping select,
  .cart .shipping .input-text { width: 97%; }
  .cart .totals { width: 82%; margin-left: 0; background: #eee; margin-top: 3%; float: none; border: 1px solid #ccc; }
  .cart .data-table td { display: block; }
  .cart .data-table thead { display: none; }
  img.cartImage { width: auto; height: auto; }
  .data-table .btn-remove { margin: 0; }
  .cart .checkout-types { float: none; text-align: left; clear: both; }
  .cart .totals .checkout-types { float: right; }
  .btn-update { margin-bottom: 7px !important; }
  #shopping-cart-table { width: 320px; /* margin: 0 auto; */ border: 1px solid #eee; margin-top: 10px; }
  #shopping-cart-table td.a-center.last { border-bottom: 1px solid #eee; }
  .data-table tfoot { background: #eee; }
  .cart .data-table tr.last th,
  .cart .data-table tr.last td { border-bottom: none; }
  .cart .totals .checkout-types { float: none; }
  .crosssells { display: none; }
  .cart { background: none; }
  #checkout-step-login .col2-set { background: none; }
  .checkout-cart-index .shipping-form .buttons-set button.button { float: none; margin-left: 0px; }

  /* checkout */
  .opc { width: 100%; float: none; }
  .opc .step { background: #fff; padding: 0; }
  .opc-progress-container { display: none; }
  #checkout-step-login .col-1, #checkout-step-login .col-2 { width: 98% !important; float: none; }
   #checkout-step-login .col-2 { margin-top: 3%; }
  #checkout-step-login .form-list .input-text { max-width: 85%; }
  #checkout-step-login .col2-set { padding: 2%; }
  #checkout-step-login .btn-only { width: auto !important; }
  .checkout-onepage-index .form-list input.input-text, .checkout-onepage-index .form-list select { width: 100%; }
  div.field { float: none; width: 100%; }
  .form-list .input-box { float: none; }
  .checkout-onepage-index .form-list label { width: auto; }
  .checkout-tip { display: inline; }
  #checkout-step-login .form-list .input-text,
  .checkout-onepage-index .input-address,
  .checkout-onepage-index .form-list select { width: 95%; max-width: inherit; }
  .checkout-onepage-index .form-list input.input-text { width: 98%; }
  div.field { width: 100% !important; margin-right: 0 !important; }
  .opc .step { padding: 2% 0 !important; }
  .sp-methods .form-list { padding-left: 0; }
  #payment_form_cybersource_soap label { display: block; text-align: left; }
  div.validation-passed { background: none; padding-right: 0; }
  .checkout-onepage-index #register-customer-newsletter label { width: 220px !important; text-align: left; }
  .sp-methods .form-list#payment_form_paypal_direct .input-box { width: 300px;  }
  .checkout-onepage-index .form-list .add-field .input-box { margin-left: 0; }
  .checkout-onepage-index .sp-methods .form-list .input-text{margin-top:0;}

  /* contact us page */
  .contacts-index-index .note { width: 100% !important; margin-left: 0; font-style: italic; margin-bottom: 30px; }
  .contacts-index-index .group-select textarea { width: 96% !important; margin-top: -20px; }
  .contacts-index-index .group-select .field-label, .contacts-index-index .group-select label { width: auto; text-align: left; display: block; float: none; }
  .contacts-index-index .group-select input { margin-bottom: 10px; }
  .contacts-index-index .group-select input#address1 { margin-bottom: -25px; }

  /* search page */
  .catalogsearch-result-index .breadcrumbs { padding: 5px 0px; width: 100%;}
  .header-promo-bar .content { width: 96%; text-align: center; padding: 2%; }


  .customer-account-index,
  .sales-order-history {
    td,
    th{
      font-size: 8px;
      padding: 2px;
    }
  }


}

/* for 480px or less */
@media screen and (max-width: 480px) {

  /* Product page: Reviews tab */
  .reviews .grid-left { width: 300px; overflow-x: scroll; float: none; padding-right: 40px; }
  .reviews .grid-right { float: none; width: auto; margin-top: 30px; }
  .reviews.tab-content { background: none; border: 10px solid #767676; width: auto; color: #292929 !important; font-size: 90%; height: auto; overflow-y: visible; overflow-x: auto; }

  .header .form-search button.button span span { background: url(../images/search-arrow.png) no-repeat 4px 6px; }

  .window-width { width: 479px; }

}
