.catalog-product-view {
  .main.col1-layout { 
    padding-left:0px;
    
    .col-main { padding-bottom:0px; }
  }

  .product-shop label{
    font-family:$font1_normal;
    color:#333;
    text-transform:uppercase;
    &[for="qty"] {
      width: 48%;
      display: inline-block;
      vertical-align: middle;
      text-align: left;
    }
  }

  .header-container { background:#f7f7f7; }
  
  .product-view {
    background:#e5e5e5;

    .page-top {
      background:none transparent;  
      padding-bottom: 4rem;
    }
    
    .product-shop {
      h1 {
        font-size: 3vw;
        line-height: 100%;
        font-weight: bold;
        font-weight: 100;
        text-transform: uppercase;
        letter-spacing: 0px;
        font-family:Oswald-Light;
        margin-bottom: .4vw;
        
        strong{
          font-family:Oswald;
          font-size:inherit;
        }
      
        span { 
          font-size: 60%;
          line-height: 110%;
        }
      }
      
      h3.productpage_tagline{
        font-family:Oswald;
        font-size: 1.5vw;
        line-height:100%;
        color:#333;
      }

      .where-to-buy-exclusive {
        font-weight: bold;
        text-align: right;
        margin: 1em 0;

        img {
          max-height: 36px;
          max-width: 100%;
          vertical-align: middle;
        }

      }

      .price,.reviews{
        margin-top:0.5rem;
      }
      
      .sku{text-align:left;}
      
      .price {
        font-size: 1.6rem;
        font-family:Oswald;
      }
      
      .reviews {
        float:right;
        text-transform:uppercase;
        font-weight:500;
        
        li.link { margin-top:5px; }
        
        a#review-link { 
            color:$jam_lightblue; 
            text-decoration:none; 
            font-size: 1.1rem;
            font-family:Oswald;
        }
      } 
      
      .short-description {
        font-size: .9rem;
        padding: 1em 0;
        margin-bottom: 0px;
        margin-top:10px;
        clear: right;
        border-top:1px solid #b5b6ba;
        border-bottom:1px solid #b5b6ba;
        
        > *:last-child{
          margin-bottom:0;
          padding-bottom:0;
        }
      }
      
      .options-container-small {
        float: none;
        
        
        .product-options-bottom {
          width:100%;
          border-top:1px solid #b5b6ba;
          padding-top:12px;
        }
      }
       
    }

    p.alert-stock {
      display: inline-block;
      margin: 1em 0em;

      a {
        text-decoration: underline;
      }
    }

    .product-shop.for-display-only {
        .price {
          display: none;
        }
        .add-to-box {
          display: none;
        }
        .product-options-bottom {
          display: none;
        }
    }

    .page-bottom-wrapper { 
      background:none;
      padding-top:0px;
    }
    
    .video.row {
      position: relative;
      width: 100%;
      padding-bottom: 50%;
      float: left;
      height: 0;
      margin: 0;
      
      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
      }
    }
    
    .features.row {
      background:#FFF;
      display:block;
      max-height:475px;
      
      .features-inner {
        font-family:$font1_normal;
        max-width:960px;
        margin:0 auto;
        &:after {
          content: " ";
          display: block;
          height: 0;
          clear: both;
        }

        
        .lft {
          width:30%;
          float:left;
          padding-top: 30px;
          
          h5 { font-size:20px; color:#adadad; text-transform:uppercase; font-weight:normal; margin-bottom:15px; } 
          
          .std ul {
            list-style: none;
            padding-left: 0;
            
            li { 
              margin-top:10px;
              font-family: 'Open Sans', sans-serif;
              font-size:12px;
              line-height:20px;
              
              span { 
                font-family:$font1_normal;
                text-transform:uppercase; 
                color:#000; 
                font-size:22px; 
                font-weight:500;
                display:block;
              }
            }
            
          }
  
        }
        .rght {
          width:60%;
          float:left;
          
          img { max-height:500px; }
        }
         
      } 
    }
    
    .features.clearer { background:#FFF; }
    
    .bannerslider.row {
      display:block;
    }
    
    .in-the-box.row {
      padding:15px 0px;
      background:#FFF;
      display:block;
      
      .in-the-box-inner {
        font-family:'Open Sans', sans-serif;
        display:block;
        max-width:960px;
        margin:0 auto;
        padding:15px 0px;
        
        h5 { font-family:$font1_normal; font-size:20px; color:#adadad; text-transform:uppercase; font-weight:normal; margin-bottom:15px; }  
        
        .lft {
            width:60%;
            float:left;
            
            img { width:85%; margin: 20px 0px; }
        }
        .rght {
            width:40%;
            float:left; 
            padding-top: 30px; 
            
            .box-detail { }
            .box-detail-links li { font-family:'Open Sans', sans-serif; }
        }
        
      }
      
    }
    
    .techspecs.row {
      background:#2c2c2c;
      color:#FFF;
      display:block; 
      
      .lft {
          width:40%;
          float:left;
          padding-top: 10%;
          box-sizing:border-box;
          -moz-box-sizing:border-box;
      }
      .rght {
          width:50%;
          float:left;
          
          img { width:100%; margin: 20px 0px; }
      }
      
      .techspecs-inner {
        font-family: 'Open Sans', sans-serif; 
        max-width:960px;
        margin:0 auto;
        padding:15px 0px;
        
        h5 { font-family:$font1_normal; font-size:20px; color:#FFF; text-transform:uppercase; font-weight:normal; margin-bottom:15px; } 
      }
           
    }
    
    .reviews.row {
      
    }
    
  }
  .catalog-product-view .product-view .features.row .features-inner .lft,
  .catalog-product-view .product-view .features.row .features-inner .rght {
    flex: 1 1 100%;
    display: table-cell;
    vertical-align: bottom;
  }

  #colorbox #cboxLoadedContent { 
    overflow: hidden !important; 
    overflow-y: auto !important;
  }

}

/* Product View ========================================================================== */

.product-view select { font-size: .9em; 
height: 34px;
padding: 3px 10px;
border: 1px solid #c7c7c7;
border-radius: 0;

}

.catalog-product-view .col1-layout .col-main { padding-top:0; }

.col2-left-layout .product-view,
.col2-right-layout .product-view { background:none; width:755px; }
.col2-left-layout .product-view .product-shop,
.col2-left-layout .product-view .product-collateral,
.col2-right-layout .product-view .product-shop,
.col2-right-layout .product-view .product-collateral { float:none; width:695px; }
.col2-left-layout .product-view .product-img-box,
.col2-right-layout .product-view .product-img-box { float:none; width:auto; }
.col2-left-layout .product-view .product-img-box .product-image,
.col2-right-layout .product-view .product-img-box .product-image { margin:0 auto; }

.col3-layout .product-view { background:none; width:572px; }
.col3-layout .product-view .product-shop,
.col3-layout .product-view .product-collateral { float:none; width:512px; }
.col3-layout .product-view .product-img-box { float:none; width:auto; }
.col3-layout .product-view .product-img-box .product-image { margin:0 auto; }

.product-view {  width: 100%; float:left; }

#messages_product_view { margin:0 -20px; position:relative; z-index:1;  background-color: #e5e5e5; }
#messages_product_view .messages { margin:0 auto; text-align: center; }   
#messages_product_view .messages li { margin:0; position:relative; z-index:1; }

.page-top { width: 100%; position: relative; overflow: hidden; background: #fff; }
.product-essential { width: 975px; margin: 0 auto; }

.page-bottom { width: 975px; margin: 0 auto; overflow: hidden; }
.product-collateral { float:left; width:100%; margin-top: 3em;  }
.product-collateral .emailto-link { font-size:11px; margin:0; background:url(../images/bkg_arrow-bullet.gif) no-repeat 0 4px; padding-left:12px; }

.box-collateral { background:#fff; }
.box-collateral .box-title { text-align: center; }
.box-collateral .box-title h2 { background: #000; color: #fff; text-transform: uppercase; font-size: 24px; display: inline-block;
padding: 10px 25px; margin-bottom: 0; }
.box-collateral .box-content { padding:20px; }

#titleBar { background: #000; clear: both; margin-top: 60px; text-align: center; }
#titleBar h5 { color: #fff;  font-weight: 100; font-size: 48px; text-transform: uppercase; padding: 15px 0; }
#titleBar h5 span { color: $jam_lightblue; font-weight: bold; }

.tab-list { position:relative; }
.tab-list .tab { float:left; cursor:pointer; }
.tab-list .last {}
.tab-list .active { cursor:default }
.tab-list .tab-container { position:absolute; left:0; width:100%; }
.tab-list .tab-content {}

.collateral-tabs { padding-bottom:20px; margin:0; }
.collateral-tabs .tab { position:relative; z-index:2; margin-right:3px; background:#dedede url(../images/bkg_collateral-tabs.gif) no-repeat 0 -39px; color:#222; padding:0 0 0 1.5em; }
.collateral-tabs .tab span { background:url(../images/bkg_collateral-tabs.gif) no-repeat 100% -39px; padding:0 1.5em 0 0; line-height:24px; float:left; }
.collateral-tabs .last {}
.collateral-tabs .active { color:#000; font-weight:bold; z-index:3; border-bottom:0; background:#fff url(../images/bkg_collateral-tabs.gif) 0 0 no-repeat; }
.collateral-tabs .active span { background-position:100% 0; padding-bottom:1px; }
.collateral-tabs .tab-container { z-index:1; top:24px; background:#fff; }
.collateral-tabs .tab-content { padding:30px 0; background:#fff; border-top:1px solid #c4c4c4; }
.collateral-tabs .tab-content object,
.collateral-tabs .tab-content embed { width:100% !important; height:auto !important; }
p.mobile-name { display: none; }

.collateral-tabs .tab-content ul { padding: 0; }
#long-description .feature { clear: both; list-style: none; padding-top: 2em; }
#long-description .feature.first { padding-top: 0; }
#long-description  .feature-icon { float: left; width: 25%;  }
#long-description .feature-desc { padding-left: 175px; }

.catalog-product-view .tab-content h5 { display: none; }
.catalog-product-view .tab-content a { color: #fff; text-decoration: underline; }
.catalog-product-view .tab-content.features ul { list-style-type: none; overflow: auto; }
.catalog-product-view .tab-content.features ul li { float: left; margin: 0 12px 40px 0; width: 140px; min-height: 90px; padding-left: 95px; font-size: 13px; }
.catalog-product-view .tab-content.features ul li span { display: block; text-transform: uppercase; padding-top: 15px; }

/* Product Shop */
.product-view .product-shop { float:right; width:38%; padding-top: 2em; position: relative;  }
.product-view .product-shop h1 { font-size: 45px; line-height: 1.3em; font-weight: bold; font-weight: 100; text-transform: uppercase; letter-spacing: 5px;}
.product-view .product-shop h1 span { font-size: 24px; display: block; line-height: 20px; letter-spacing: 0; margin-top: 5px; }
.product-view .product-shop .product-main-info {  }
.product-view .product-shop p { }
.product-view .product-shop .ratings {  width: 175px; text-align: right; float: left; }
.product-view .product-shop p.no-rating {  font-size: .9em; line-height: 1.3em; float: left; }
.product-view .product-shop .ratings p { line-height: 1.3em; }
.product-view .product-shop p.emailto-link { display: none; }
.product-view .product-shop .buy-info { width: 100%; overflow: auto;  }
.product-view .product-shop .product-ids { float:none;text-align:right;color:#999; font-size: .9em; margin-bottom: 10px; }
.product-view .product-shop .availability { float: right; clear: right; font-size: .9em; display: none; }
.product-view .product-shop .price-box { float: left; }
.product-view .product-shop .price-box .price-label {  }
.product-view .product-shop .price-box .price-as-configured .price-label {  }
.product-view .product-shop .price-box .price-as-configured .price { color:#e55100;  }
.product-view .product-shop .price-box .price-including-tax .price,
.product-view .product-shop .price-box .regular-price .price { color:#111; font-size: 26px; }
.product-view .product-shop .price-box .special-price .price-label,
.product-view .product-shop .price-box .special-price .price { color:#c73615; }
.product-view .product-shop .price-box .special-price .price-label {display:none;}
.product-view .product-shop .price-box .price-as-configured .price-including-tax .price { color:#e55100; }
.product-view .product-shop .price-box .price-as-configured .price-excluding-tax .price { color:#222;  }
.product-view .product-shop .price-box p { margin:4px 0; }
.product-view .product-shop .add-to-links { display:none; }
.product-view .product-shop .add-to-box { text-align: center; padding-top: 12px;}

.product-view .product-name h1 { font-size:22px; line-height:1.2; color:#d12c01; font-weight:normal; }
.product-view .product-ids { text-transform:uppercase; }
.product-view .short-description { padding: 2em 0 0 0; margin-bottom: 15px; clear: right; }
.product-view .short-description h2 { margin:0 0 5px; font-size:13px; }
.product-view .product-shop .add-to-cart { margin-top: 15px; }
.add-to-cart button.button { background:$jam_lightblue; width: 100%; }

.promo-block { float: right; width: 185px; background: #3f3f3f; padding: 10px; margin-right: 35px; color: #fff; clear: both; }
.promo-block p a { display: block; color: #A9E900; text-decoration: underline; }

.product-action { clear: both; margin-top: 10px; }

.product-view .add-to-cart button.btn-cart {
  height: auto;
  padding:.8rem 0;
  background-color:#40b4e5;
  text-shadow:none !important;
  @include transition(background-color .3s linear);

  &:hover{
    background-color:#006C88;
  }
  
  &.disable-out-of-stock{
    background-color:#C0C0C0;
    cursor:text;
    span{
      cursor:text;
      span{
        cursor:text;
      }
      span:before{
        display:none;
      }
    }
  }
}
.product-view .add-to-cart button.btn-cart span {
  padding:0;
  height: auto;
  background-color:transparent;
}
.product-view .add-to-cart button.btn-cart span span {
  padding:0;
  height: auto;
  font-family:Oswald-Light;
  letter-spacing:1px;
  background-color:transparent;
  font-size:1.2rem;
  
  &:before{
    content: "+";
    display: inline-block;
    font-size: 110%;
    font-family: Arial, sans-serif;
    margin-right: 2px;
    font-weight: normal;
  }
}




/* RESPONSIVE STYLES */

@include breakpoint($bp_max){
  .catalog-product-view {
    .product-view {
      .product-shop {
        h1 {
          font-size: 4vw;
        }
      }
    }
  }
}

@include breakpoint($bp_largest){
  .catalog-product-view {
    .product-view {
      .product-essential{width:auto;}
      .page-top{
        padding-right:1rem;
        padding-left:1rem;
        box-sizing:border-box;
        -moz-box-sizing:border-box;
      }
      .product-shop {
        h1 {
          font-size: 4.5vw;
        }
        .price {
          font-size: 1.3rem;
        }
      }
    }
  }
}

@include breakpoint($bp_large){
  
  .catalog-product-view {

    .product-view {
      
      .add-to-cart button{
        padding:1vw;
        line-height: 100%;
        
        span{
          span{
            font-size: 2.2vw !important;
            line-height: 100% !important;
            vertical-align: middle;
          }
        }
      }
      
     .product-shop {
        h1 {
          font-size: 5vw;
        }
        .price {
          font-size: 1.3rem;
        }
      }
      
      .product-shop {width: 48%; }
      .product-img-box { 
        width: 50%;
      }
        
      .features.row {
        background:#FFF;
        display:block;
        max-height:475px;
        
        .features-inner {
          max-width:700px;
          margin:0 auto;
        } 
      }
      
      .in-the-box.row {
        padding:15px 0px;
        background:#FFF;
        display:block;
        
        .in-the-box-inner {
          display:block;
          max-width:700px;
          margin:0 auto;
          padding:15px 0px;
        }
      }
      
      .techspecs.row {
        background:#2c2c2c;
        color:#FFF;
        display:block;
        
        .techspecs-inner {
          max-width:85%;
          margin:0 auto;
          padding:15px 0px;
        }
      }
    }
  }
    
}

@include breakpoint($bp_medium){
  .catalog-product-view {
    
    .product-view {
      
     .product-shop {
        h1 {
          font-size: 6.2vw;
        }
        .price {
          font-size: 1.3rem;
        }
        .short-description{
          font-size: .8rem;
        }
      }
        
      .features.row {
        background:#FFF;
        display:block;
        max-height:475px;
        
        .features-inner {
          max-width:700px;
          margin:0 auto;
          
          .lft {
            width:80%;
            float:left;
            padding-top: 30px;
            padding:30px;
            
            h5 { } 
            
            .std ul {
              list-style: none;
              padding-left: 0;
              
              li { 
                font-size:14px;
                
                span { 
                  text-transform:uppercase; 
                  color:#000; 
                  font-size:22px; 
                  font-weight:500;
                  display:block;
                  line-height: 24px;
                }
              }
              
            }
    
          }
          .rght {
            display:none; 
            visibility:hidden; 
            
            img { display:none; visibility:hidden; }
          }        
          
        } 
      }
      
      .in-the-box.row {
        padding:15px 0px;
        background:#FFF;
        display:block;
        
        .in-the-box-inner {
          display:block;
          max-width:700px;
          margin:0 auto;
          padding:0px;
          
          .lft {
            display:none; 
            visibility:hidden;
              
              img { display:none; visibility:hidden;  }
          }
          .rght {
              width:80%;
              float:left; 
              padding-top: 30px; 
              padding:30px;
          }        
        }
      }
      
      .techspecs.row {
        background:#2c2c2c;
        color:#FFF;
        display:block;
        
        .techspecs-inner {
          max-width:100%;
          margin:0 auto;
          padding:0px;
          
        .lft {
            width:100%;
            float:left;
            padding-top: 30px;
            padding:30px;
        }
        .rght {
            display:none; 
            visibility:hidden;
            
            img { display:none; visibility:hidden; } 
        }        
          
        }
      }
    }
  }
}

@include breakpoint($bp_medium_legacy){
  .catalog-product-view {
    
    .product-view {
      
      p.mobile-name{  
        display: block;
        font-family: Oswald-Light;
        font-size: 2.3rem;
        letter-spacing: 0;
        
        strong{
          font-family:Oswald-Bold;
        }

      }
      
      .add-to-cart button{
        padding:.6rem;
        line-height: 100%;
        
        span{
          span{
            font-size: 1.2rem !important;
            letter-spacing:0;
          }
        }
      }
      
      .product-shop {
        padding: 0;
        
        h3.productpage_tagline{
          font-size:1.7rem;
          text-align:center;
        }
        
        h1 {
          font-size: 5vw;
        }
        .price {
          font-size: 1.3rem;
        }
      }
      
      .product-shop {width: 100%; }
      .product-img-box { 
        width: 100%; 
      }
    }
  }
}
