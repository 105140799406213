.product-image-gallery {
  position: relative;

  .gallery-image {
    display: none;

    &.visible {
      display: block;

      &.hidden {
        visibility:hidden;
      }
    }
  }

  //@include loadingOverlay();
}

.product-image-thumbs {
  li {
    display: inline-block;
  }
  li:first-child {
    margin-left: -1px;
  }
  a {
    display: inline-block;
    border: 1px solid transparent;
  }
}

.no-touch .product-image-thumbs {
  a:hover {
    border-color: #c7c7c7;
  }
}


/* ============================================ *
 * Configurable Swatches
 * ============================================ */
/* Clears */
.clearfix:after,
.configurable-swatch-list:after,
.product-view .product-options .swatch-attr:after {
  content: '';
  display: table;
  clear: both;
}

dt.swatch-attr {
  #colors_label em {
    display: none;
  }
}

dd.swatch-attr {
  float: right;
}

/* General Swatch Styling */
.swatch-link,
.swatch-label {
  display: block;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
  color: #636363;
  text-decoration: none;
  box-sizing: content-box;
}

.swatch-link {
  border: 1px solid #cccccc;
  margin: 0 0 3px;
  border-radius: 10px;
}
.swatch-link img {
  border-radius: 10px;
}
.swatch-link:hover {
  cursor: pointer;
  text-decoration: none;
}
.swatch-link .x {
  display: none;
  text-indent: -999em;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(../images/bg_x.png) center no-repeat transparent;
  z-index: 10;
}
.swatch-link.has-image .swatch-label {
  position: relative;
}
.swatch-link.has-image img {
  position: absolute;
  top: 0;
  left: 0;
}

.swatch-label {
  border: 1px solid #fff;
  border-radius: 10px;
  margin: 0;
  white-space: nowrap;
  background: #f4f4f4;
}

.configurable-swatch-list {
  margin-left: -3px;
  zoom: 1;
  clear: both;
  -webkit-transform: translateZ(0px);
}
.configurable-swatch-list li {
  float: left;
  zoom: 1;
  margin: 0 0 0 3px;
}
.products-grid .configurable-swatch-list li {
  display: inline-block;
  float: none;
  margin: 0;
  vertical-align: top;
}
.configurable-swatch-list .not-available .x {
  display: block;
}
.configurable-swatch-list .not-available .swatch-link {
  border-color: #ededed;
  position: relative;
}
.configurable-swatch-list .not-available .swatch-link.has-image img {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.configurable-swatch-list .not-available .swatch-label {
  color: #aaa;
  background: #fff;
}
.configurable-swatch-list .wide-swatch .swatch-label {
  padding: 0 6px;
}
.configurable-swatch-list .not-available a:focus {
  outline: 0;
}

#narrow-by-list dd .configurable-swatch-list li {
  margin: 0 0 0 3px;
  width: 47%;
}
#narrow-by-list dd .swatch-link {
  border: none;
  line-height: 25px;
  margin-right: 2px;
  text-align: left;
}
#narrow-by-list dd .swatch-link.has-image {
  line-height: inherit;
}
#narrow-by-list dd .swatch-link:hover .swatch-label {
  border-color: #3399cc;
}
#narrow-by-list dd .swatch-label {
  background: #f4f4f4;
  border: 1px solid #cccccc;
  border-radius: 3px;
  display: block;
  float: left;
  line-height: 1.5em;
  margin: 0 5px 0 0;
  padding: 1px 5px;
  white-space: nowrap;
}
#narrow-by-list dd .swatch-label img {
  border: 1px solid #fff;
  border-radius: 3px;
  box-sizing: content-box;
}
#narrow-by-list dd .has-image .swatch-label {
  padding: 0;
}

@media only screen and (max-width: 770px) {
  #narrow-by-list dd .configurable-swatch-list li:nth-child(odd) {
    clear: left;
  }
}
.currently .swatch-current {
  position: relative;
}
.currently .swatch-current .btn-remove {
  margin-top: -10px;
  position: absolute;
  right: 0;
  top: 50%;
}
.currently .swatch-current span {
  display: block;
  float: left;
}
.currently .swatch-link {
  display: inline-block;
  margin: 0 0 0 3px;
}
.currently .swatch-link:hover {
  border-color: #cccccc;
  cursor: default;
}

/* Other Swatch States */
.configurable-swatch-list .hover .swatch-link,
.configurable-swatch-list .selected .swatch-link,
.swatch-link:hover {
  border:solid 1px #fff;
  box-shadow: 0 0 4px rgba(0,0,0,.9);
}

.configurable-swatch-box {
  background: none !important;
}
.configurable-swatch-box select.swatch-select {
  display: none;
}
.configurable-swatch-box .validation-advice {
  margin: 0 0 5px;
  background: #df280a;
  padding: 2px 5px !important;
  font-weight: bold;
  color: #fff !important;
  float: left;
  display: block;
  border-radius: 3px;
}
.product-view .product-img-box .product-image img {
  max-width: 100%;
  max-height: 750px;
  margin: 0px auto;
}
.product-view .product-img-box .product-image-zoom {
  z-index: 0;
}
.product-view .product-image-thumbs img {
  max-width: 100%;
  max-height: 100%;
}