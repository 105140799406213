ul.messages{
  
  background-color:transparent;display:none;width:100%;@include borderbox();text-align:center;clear:both;float:none;
  
  > li{
    display:inline-block;text-align:center;margin:0 auto;padding:1rem;font-family:Oswald;font-size:1.1rem;line-height:1.5rem;color:#A5A5A5;
    > ul{
      border:solid 1px #A5A5A5;padding:.5rem;background-color:#fff;font-family:'Open Sans', sans-serif;font-weight:100;
    }
  }
  > li.error-msg{
    
  }
  > li.success-msg{
    
  }
  > li.note-msg{
    
  }
  > li.notice-msg{
    
  }
}

/*product page*/
.catalog-product-view{
  
  ul.messages{   
    background-color:#E5E5E5;
    
    > li{

    }
    > li.error-msg{
      
    }
    > li.success-msg{
      
    }
    > li.note-msg{
      
    }
    > li.notice-msg{
      
    }
  }
}

ul.messages.not-add-cart{
  display:block;
}
