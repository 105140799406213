/*
 * USAGE EXAMPLES: 
 * 
 * @include transition(width .2s linear);
 * @include transition(color .2s linear, background-color .3s linear);
 * @include transition(color .2s linear, background-color .3s linear, width .5s linear);
 * etc...
 * 
 * */
@mixin transition($defs...) {
  -webkit-transition: $defs; -moz-transition: $defs; -ms-transition: $defs; -o-transition: $defs; transition: $defs;
}
 