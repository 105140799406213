/*-----------------------------------------------------------------------------

	Custom styles for Jam - Revolution Slider

-----------------------------------------------------------------------------*/

.banner-container {
padding:0px;
background-color:#fff;
width:975px;
position:relative;
position:relative;
margin-left:auto;
margin-right:auto;
}
 
.banner{
width:975px;
height:350px;
position:relative;
overflow:hidden;
}

	 .fullwidthbanner-container{
		width:100% !important;
		position:relative;
		padding:0;
		max-height:500px !important;
		overflow:hidden;
		z-index: 1;
	}

.fullwidthbanner-container.category { max-height: 400px !important; margin-bottom: 25px; }
 
@media only screen and (min-width: 768px) and (max-width: 974px) {
      .banner, .banner-container { width:760px; height:395px;}
}
 
 
@media only screen and (min-width: 480px) and (max-width: 767px) {
      .banner, .banner-container { width:480px; height:250px; }
}
 
@media only screen and (min-width: 0px) and (max-width: 479px) {
      .banner, .banner-container { width:320px;height:166px; }
}