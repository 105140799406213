.product-view,.cms-index-index{
  .fullwidthbanner-wrapper{
    .flexslider{
      text-align:center;
      

        
      &:hover{
        .flex-direction-nav{
          
          .flex-prev { opacity: 0.7; left: 10px; }
          .flex-next { opacity: 0.7; right: 10px; }
        }
      } 
      
      .flex-direction-nav{
        display:block;
        visibility:visible;
        margin-bottom:0; 
        
        .flex-prev { opacity: 0.7; left: 10px; }
        .flex-next { opacity: 0.7; right: 10px; }
        
        a{
          font-size:0;
          color:transparent;
        }
        
        a:before{
          font-size:40px;
          color:#000;
        }
      }
      
      .flex-control-nav{right:0;left:0;margin:auto;position:absolute;bottom:2vw;padding:0;width:100%;}
      .flex-control-paging li{ 
        a{
          background:#CBCBCB;
          border-color:transparent;
          box-shadow:none;
        }
        a.flex-active{
          border-color:transparent;
          background:#303435; 
        }
      }
    }
  }
}

@include breakpoint($bp_large){
  .product-view,.cms-index-index{
    .fullwidthbanner-wrapper{
      .flexslider{
        .flex-direction-nav a:before{
          font-size:35px;
        }
      }
    }
  }
}

@include breakpoint($bp_medium){
  .product-view,.cms-index-index{
    .fullwidthbanner-wrapper{
      .flexslider{
        
        .flex-control-paging li{ 
          a{
            width:2.5vw;
            height:2.5vw;
          }
          a.flex-active{}
        }
        
        .flex-direction-nav a:before{
          font-size:30px;
        }
      }
    }
  }
}

@include breakpoint($bp_medium_legacy){
  .product-view,.cms-index-index{
    .fullwidthbanner-wrapper{
      .flexslider{
        .flex-direction-nav a:before{
          font-size:25px;
        }
      }
    }
  }
}

@include breakpoint($bp_smallest){
    .cms-index-index{
    .fullwidthbanner-wrapper{
      .flexslider{
        
        .flex-control-paging li{ 
          a{
            width:3vw;
            height:3vw;
            margin: 0 1vw;
          }
          a.flex-active{}
        }
      }
    }
  }
  .product-view{
    .fullwidthbanner-wrapper{
      .flexslider{
        .flex-control-nav li {
          margin: 0 5px;
        }
        .flex-control-paging li{ 
          a{
            width:4vw;
            height:4vw;
            margin: 0;
          }
          a.flex-active{}
        }
      }
    }
  }
}