.instructionbooks-wrapper {

  margin: 0 1em;

  h3 {
    margin: 1em 0 .5em 0;
  }

  p {
    margin: 1em 0;
  }

}