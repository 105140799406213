$social-div-pad:2px;

.lets_get_social{
  text-align:center;
  max-width:100%;
  margin-top: 3rem;
  
  &:before,&:after{content:"";display:block;clear:both;}
  h2{
    font-family:Oswald;
    display:block;
    width:100%;
    text-align:center;
    text-transform:uppercase;
    color:#333;
    margin-top:.5em;
    letter-spacing:1px;
  }
  .social_grid{
    display:inline-block;
    width:100%;
    font-size:0;
    white-space:nowrap;
    background-color:#fff;
    
    > div{
      font-size:1rem;
      display:inline-block;
      width:20%;
      padding:$social-div-pad;
      box-sizing:border-box;
      -moz-box-sizing:border-box;
      
      &:first-child{
        padding-left:0;
      }
      &:last-child{
        padding-right:0;
      }
      
      a{
        display:block;
        width:100%;
        height:100%;
      }
      img{
        display:block;
        width:100%;
        max-width:100%;
      }
    }
  }
}

/*@include breakpoint($bp_medium){
  .lets_get_social{
    .social_grid{
      white-space:normal;
      text-align:left;
      
      > div{
        width:33.33333%;
        
        &:nth-child(4n+4){
          padding-left:0;
        }
        &:nth-child(3n+3){
          padding-right:0;
        }
      }
      
    }
  }
}*/

@include breakpoint(900px){
  .lets_get_social{
    .social_grid{
      white-space:normal;
      > div{
        width:50%;
        
        &:nth-child(even){
          padding-right:0;
        }
        &:nth-child(4n+4){
          padding-left:$social-div-pad;
        }
        &:nth-child(3n+3){
          padding-right:$social-div-pad;
          padding-left:0; 
        }
      }
      > div:last-child:nth-child(odd){
        width:100%;
        padding-right:0;
        padding-left:0;
        display:none;/*remove this if you want the last image to display in mobile*/
      }
    }
  }
}