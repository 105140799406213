.toggle-compare-btn{
  position:relative;padding:0;margin:0;cursor:pointer;@include noselect();font-family:$font1_light;font-size:1rem;line-height:1.8rem;color:#333;
  
  > span{
    padding-left:1rem;display:none;font-family:inherit;font-size:inherit;line-height:inherit;color:inherit;
  }
  
  > span.active{display:block;}
  
  > span:before{
    position:absolute;left:0;top:0;bottom:0;margin:auto;@include borderbox();content:"";display:inline-block;font-family:$font1_light;font-size:.8rem;line-height:.8rem;text-align:center;border:solid 1px #333;background-color:#fff;height:.8rem;width:.8rem;
  }
  > .remove-from-compare:before{
    background-color:#333;color:#fff;content:"✔";
  }
  
  > .ajax-is-loading{
    opacity:.8;display:none;
  }
  
  > .ajax-is-loading:before{display:none;}
  > .ajax-is-loading:after{
    position:absolute;left:0;top:0;bottom:0;margin:auto;content:"";background-image:url(../images/loading_compare.svg);background-position:center;background-repeat:no-repeat;display:block;background-size:.8rem;height:.8rem;width:.8rem;
  }
  
  &.loading{
    > span.active{display:none;}
    > .ajax-is-loading{display:block;}
  }
  
}

.toggle-compare-btn:hover{
  > span{
    color:#00ADEF;
  }
  
  > span:before{
    border:solid 1px #00ADEF;
  }
  
  > .remove-from-compare:before{
    background-color:#00ADEF;
  }
}

.toggle-compare-btn.error{
  > span:before{
    content:"✘";
  }
}

.catalog-category-view .main{position:relative;}

.compare-loading-message{
  position:fixed;
  left:0;
  top:0;
  width: 100%;
  height:100%;
  background-color:rgba(0,0,0,.2);
  z-index:9999;
  
  .content-bounds{
    @include borderbox();
    border-radius:.5rem;
    width:300px;
    height:107px;
    min-width:180px;
    max-width:100%;
    max-height:100%;
    padding:1rem;
    position:fixed;
    top: 50%;
    left: 50%;
    margin-left: -150px;
    margin-top: -53.5px;

    display:block;
    background-color:#333;
    color:#fff;
    box-shadow: inset 0 0 20px rgba(0,0,0,.9), 0px 0px 10px rgba(0,0,0,.5);
    border:solid 2px rgba(255,255,255,.5);
    
    .load-msg{
      padding-left:3rem;
      position:relative;
      display:block;
      color:inherit;
      text-shadow:-2px 2px 4px rgba(0,0,0,1);
      font-family:$font1_light;
      strong{
        font-family:$font1_normal;
      }
    }
    .load-msg:before{
      background-size:2.5rem;
      height:2.5rem;
      width:2.5rem;
      position:absolute;
      left:0;
      top:5px;
      margin:auto;
      content:"";
      background-image:url(../images/loading_compare_popup.svg);
      background-position:center;
      background-repeat:no-repeat;
      display:block;
    }
  }
}

.product-compare-info{
  display:block;color:#333;@include borderbox();
  
  .row{
    padding-top:1rem;padding-bottom:1rem;display:block;font-size:0;white-space:nowrap;border:solid 1px #eee;box-shadow:inset 0 0 20px #F1F1F1;@include borderbox();
    
    .col{
      display:inline-block;font-size:1rem;vertical-align:middle;@include borderbox();
    }
    
    .col.label{
      width:25%;white-space:normal;
      
      h3{
        font-family:$font1_normal;font-size:1.5rem;line-height:1.5rem;
      }
      
      p{
        font-size:.9rem;line-height:1.2rem;
      }
    }
    
    .col.compare-products-list{
      padding:0 1rem;width:50%;font-size:0;white-space:nowrap;
      
      .none-msg{
        display:none;font-size:1rem;text-align:center;color:#ccc;white-space:normal;
      }
      
      .compare-item{
        position:relative;display:inline-block;font-size:1rem;vertical-align:top;@include borderbox();padding-left:.5rem;width:100px;max-width:20%;
        
        .tileimage{
          @include borderbox();width:100%;display:block;background-color:#fff;padding:.5rem;border:solid 1px #eee;box-shadow:-2px 2px 14px rgba(0,0,0,.1);
          
          img{
            @include borderbox();max-width:100%;
          }
        }
        
        .remove-btn{
          position:absolute;right:-4px;top:-4px;display:block;color:#bbb;border:solid 1px #bbb;background-color:#fff;width:18px;height:18px;line-height:18px;font-size:10px;font-weight:bold;border-radius:9px;text-align:center;cursor:pointer;
        
          &:hover{
            background-color:#333;color:#fff;border-color:#333;
          }
        }
        .remove-btn:after{
          content:"X";
        }
      }
    }
    
    .col.buttons{
      width:25%;white-space:normal;font-size:0;text-align:center;
      
      > div{
        display:inline-block;font-size:1rem;cursor:pointer;margin-right:1.5rem;font-family:$font1_light;
      }
      
      .clear-all-compare-btn{
        
        &:hover{
          text-decoration:underline;
        }
        
        &.disabled, &.disabled:hover{
          color:#ccc;cursor:text;text-decoration:none;
        }
      }
      
      .compare-all-btn{
        padding:.7rem 1.5rem;background-color:#00ADEF;color:#fff;text-transform:uppercase;@include transition(background-color .2s linear);
        
        &:hover{
          background-color:#0099D4;
        }
        
        &.disabled, &.disabled:hover{
          background-color:#ccc;cursor:text;
        }
      }
    }
  }
}

.product-compare-info.no-compare-items{
  display:none;
  .row{
    .col.compare-products-list{
      .none-msg{display:block;}
    }
  }
}

.max-num-in-compare{
  .toggle-compare-btn{
    .add-to-compare.active{
      opacity:.3;
    }
  }
}

@include breakpoint($bp_largest){
  .product-compare-info .row .col.label h3{font-size:1.9vw;}
  .product-compare-info .row .col.label p{font-size:1.2vw;}
  .product-compare-info .row .col.compare-products-list .compare-item{width:90px;}
  .product-compare-info .row .col.compare-products-list .compare-item .remove-btn{width:16px;height:16px;line-height:16px;font-size:8px;border-radius:9px;}
  .product-compare-info .row .col.buttons > div{font-size:1.3vw;}
  .product-compare-info .row .col.buttons .clear-all-compare-btn{}
  .product-compare-info .row .col.buttons .compare-all-btn{}
}
@include breakpoint($bp_large){
  .product-compare-info .row .col.label{width:25%;}
  .product-compare-info .row .col.compare-products-list{width:55%;}
  .product-compare-info .row .col.buttons{width:20%;}
  .product-compare-info .row .col.label h3{font-size:2.2vw;}
  .product-compare-info .row .col.label p{font-size:1.4vw;}
  .product-compare-info .row .col.compare-products-list .compare-item{width:90px;}
  .product-compare-info .row .col.compare-products-list .compare-item .remove-btn{width:16px;height:16px;line-height:16px;font-size:8px;border-radius:9px;}
  .product-compare-info .row .col.buttons{}
  .product-compare-info .row .col.buttons > div{display:block;margin-right:0;font-size:1.5vw;}
  .product-compare-info .row .col.buttons .clear-all-compare-btn{}
  .product-compare-info .row .col.buttons .compare-all-btn{margin-top:10px;}
}
@include breakpoint($bp_medium){
  .product-compare-info .row .col.compare-products-list .compare-item{width:100px;}
  .product-compare-info .row{white-space:normal;}
  .product-compare-info .row .col.label h3{font-size:3.2vw;}
  .product-compare-info .row .col.label p{font-size:2.4vw;}
  .product-compare-info .row .col.label{width:100%;text-align:center;margin-bottom:1rem;}
  .product-compare-info .row .col.compare-products-list{width:100%;text-align:center;margin-bottom:1rem;}
  .product-compare-info .row .col.buttons{width:100%;text-align:center;}
  .product-compare-info .row .col.buttons > div{font-size:2.5vw;display:inline-block;}
  .product-compare-info .row .col.buttons .compare-all-btn{margin-left:5.5vw;}
}
@include breakpoint($bp_small){
  .toggle-compare-btn {display:inline-block;}
  .product-compare-info .row .col.buttons > div {font-size:3.2vw;}
  .product-compare-info .row .col.buttons > .clear-all-compare-btn{display:block;margin-top:20px;}
  .product-compare-info .row .col.buttons > .compare-all-btn{margin-left:0;margin-top:20px;}
  .product-compare-info .row .col.label p {font-size:2.8vw;}
  .product-compare-info .row .col.label h3{font-size:4.2vw;}
  .product-compare-info .row .col.compare-products-list .compare-item .tileimage{box-shadow:none;}
  .product-compare-info .row{box-shadow:none;border:none;}
}
@include breakpoint($bp_smallest){
  .product-compare-info .row .col.label h3 {font-size: 4.5vw;}
  .product-compare-info .row .col.label p {font-size: 3.2vw;}
  .product-compare-info .row .col.buttons > div {font-size: 3.5vw;}
  .product-compare-info .row .col.label{text-align:left;}
  .product-compare-info .row .col.buttons{text-align:left;}
  .product-compare-info .row{padding-left:0;padding-right:0;}
  .product-compare-info.no-compare-items .row .col.compare-products-list .none-msg{text-align:left;}
  .product-compare-info .row .col.compare-products-list{text-align:left;margin-top:1rem;padding-left:0;padding-right:0;white-space:normal;}
  .product-compare-info .row .col.compare-products-list .compare-item{width:auto;max-width:100%;padding:.5rem;}
  .product-compare-info .row .col.compare-products-list .compare-item .remove-btn{width:5vw;height:5vw;line-height:5vw;font-size:3vw;border-radius:4vw;right:0;top:0;}
}
@include breakpoint(400px){
  .product-compare-info.no-compare-items .row .col.compare-products-list .none-msg{text-align:center;}
  .product-compare-info .row .col.label{text-align:center;}
  .product-compare-info .row .col.compare-products-list{text-align:center;}
  .product-compare-info .row .col.buttons{text-align:center;}
  .product-compare-info .row .col.label h3 {font-size:1.2rem;}
  .product-compare-info .row .col.label p {font-size:.8rem;}
  .product-compare-info .row .col.buttons > div {font-size:1rem;}
  .product-compare-info .row .col.compare-products-list .compare-item .remove-btn {width:18px;height:18px;line-height:18px;font-size:13px;border-radius:9px;right:0;top:0;}
}