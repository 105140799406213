/*
 * jQuery FlexSlider v2.2.0
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 */


/* Browser Resets
*********************************/
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus  {outline: none;}
.slides,
.flex-control-nav,
.flex-direction-nav {margin: 0; padding: 0; list-style: none;}

/* Icon Fonts
*********************************/
/* Font-face Icons */
@font-face {
    font-family: 'flexslider-icon';
    src:url('fonts/flexslider-icon.eot');
    src:url('fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'),
        url('fonts/flexslider-icon.woff') format('woff'),
        url('fonts/flexslider-icon.ttf') format('truetype'),
        url('fonts/flexslider-icon.svg#flexslider-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {margin: 0; padding: 0;}
.flexslider .slides > li {display: none; -webkit-backface-visibility: hidden;} /* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {width: 100%; display: block;}
.flex-pauseplay span {text-transform: capitalize;}

/* Clearfix for the .slides element */
.slides:after {content: "\0020"; display: block; clear: both; visibility: hidden; line-height: 0; height: 0;}
html[xmlns] .slides {display: block;}
* html .slides {height: 1%;}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {display: block;}

/* FlexSlider Default Theme
*********************************/
.flexslider { margin: 0; background: #fff; position: relative; zoom: 1; }
.flex-viewport { max-height: 2000px; -webkit-transition: all 1s ease; -moz-transition: all 1s ease; -o-transition: all 1s ease; transition: all 1s ease; }
.loading .flex-viewport { max-height: 300px; }
.flexslider .slides { zoom: 1; }
.carousel li { margin-right: 5px; }

/* Direction Nav */
.flex-direction-nav {*height: 0; display:none; visibility:hidden;}
.flex-direction-nav a  { text-decoration:none; display: block; width: 40px; height: 40px; margin: -20px 0 0; position: absolute; top: 50%; z-index: 10; overflow: hidden; opacity: 0; cursor: pointer; color: rgba(0,0,0,0.8); text-shadow: 1px 1px 0 rgba(255,255,255,0.3); -webkit-transition: all .3s ease; -moz-transition: all .3s ease; transition: all .3s ease; }
.flex-direction-nav .flex-prev { left: -50px; }
.flex-direction-nav .flex-next { right: -50px; text-align: right; }
.flexslider:hover .flex-prev { opacity: 0.7; left: 10px; }
.flexslider:hover .flex-next { opacity: 0.7; right: 10px; }
.flexslider:hover .flex-next:hover, .flexslider:hover .flex-prev:hover { opacity: 1; }
.flex-direction-nav .flex-disabled { opacity: 0!important; filter:alpha(opacity=0); cursor: default; }
.flex-direction-nav a:before  { font-family: "flexslider-icon"; font-size: 40px; display: inline-block; content: '\f001'; }
.flex-direction-nav a.flex-next:before  { content: '\f002'; }

/* Pause/Play */
.flex-pauseplay a { display: block; width: 20px; height: 20px; position: absolute; bottom: 5px; left: 10px; opacity: 0.8; z-index: 10; overflow: hidden; cursor: pointer; color: #000; }
.flex-pauseplay a:before  { font-family: "flexslider-icon"; font-size: 20px; display: inline-block; content: '\f004'; }
.flex-pauseplay a:hover  { opacity: 1; }
.flex-pauseplay a.flex-play:before { content: '\f003'; }

/* Control Nav */
.flex-control-nav {width: 100%; position: absolute; bottom: -40px; text-align: center;}
.flex-control-nav { position: absolute; right: 10%; bottom: 1%; z-index: 9999; width: 30%; }
.flex-control-nav li {margin: 0 6px; display: inline-block; zoom: 1; *display: inline; }
.flex-control-paging li a {width: 18px; height: 18px; display: block; background: #FFF; cursor: pointer; text-indent: -9999px; border: 1px solid #666; -webkit-border-radius: 20px; -moz-border-radius: 20px; -o-border-radius: 20px; border-radius: 20px; -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); -moz-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); -o-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); box-shadow: inset 0 0 3px rgba(0,0,0,0.3); }
.flex-control-paging li a:hover { background: $jam_lightblue; border: 1px solid $jam_lightblue; }
.flex-control-paging li a.flex-active { background: $jam_lightblue; cursor: default; border: 1px solid $jam_lightblue; }

.flex-control-thumbs {margin: 5px 0 0; position: static; overflow: hidden;}
.flex-control-thumbs li {width: 25%; float: left; margin: 0;}
.flex-control-thumbs img {width: 100%; display: block; opacity: .7; cursor: pointer;}
.flex-control-thumbs img:hover {opacity: 1;}
.flex-control-thumbs .flex-active {opacity: 1; cursor: default;}

.flexslider ul { list-style:none; }
.flexslider ul.slides { margin-bottom:0; width: 100%; padding-left: 0px; }


/* FLEXSLIDER BANNER TEXT (Jam Audio USA only) */
.jamaudio_usa .slider-text { font-size: 24px; position:absolute; top:36%; text-align: right; }
.jamaudio_usa .slider-text.left { left:8%; right: auto; }
.jamaudio_usa .slider-text.right { right:8%; left: auto; }
.jamaudio_usa .slider-text .line1 { text-transform:uppercase; font-size: 65px; font-weight:bold; line-height: 65px; }
.jamaudio_usa .slider-text .line2 { text-transform:uppercase; font-size:104px; line-height: 104px; display: block; }
.jamaudio_usa .slider-text .linkline1 { text-transform:uppercase; color:#fff; margin: 0px 0px 20px 0px; float: right; background-color: #00bcef; padding-top: 2px; padding-bottom: 3px; text-align: center; width: 100%;  }
.jamaudio_usa .slider-text a { color: #fff; text-decoration: none; }
.jamaudio_usa .slider-text a:hover { color: #fff; }
.jamaudio_usa .slider-text .linkline1:hover { background-color: #33d5f1; }
.jamaudio_usa .slider-text .linkline2 { font-weight: bold; }

/* jam specific  (Jam Audio USA only) */

.jamaudio_usa .slider-text.rewind a { color: #000; text-decoration: none; }
.jamaudio_usa .slider-text.rewind .linkline1 { color: #fff; }

.jamaudio_usa .slider-text.transitOE .line2 { font-size: 174px; line-height: 134px; }
.jamaudio_usa .slider-text.transitOE .line1 { font-size: 76px; }
.jamaudio_usa .slider-text.transitOE .linkline1 { font-size: 18px; }

.jamaudio_usa .slider-text.touch .line2 { font-size: 136px; line-height: 124px; }
.jamaudio_usa .slider-text.touch .line1 { font-size: 76px; }
.jamaudio_usa .slider-text.touch .linkline1 { font-size: 18px; }

.jamaudio_usa .slider-text.rewind .line2 { font-size: 148px; line-height: 118px; }
.jamaudio_usa .slider-text.rewind .line1 { font-size: 86px; }
.jamaudio_usa .slider-text.rewind .linkline1 { font-size: 18px; }

.jamaudio_usa .slider-text.transitIE .line2 { font-size: 194px; line-height: 148px; height: 166px; }
.jamaudio_usa .slider-text.transitIE .line1 { font-size: 76px; }
.jamaudio_usa .slider-text.transitIE .linkline1 { font-size: 18px; position: relative; top: -20px; }


/* FLEXSLIDER BANNER TEXT (Jam Audio - all non-US stores ) */
.jamaudio_international_all .slider-text { font-size: 24px; position:absolute; top:20%; right:8%; text-align: right; }
.jamaudio_international_all .slider-text .line1 { text-transform:uppercase; font-size: 65px; font-weight:bold; line-height: 65px; }
.jamaudio_international_all .slider-text .line2 { text-transform:uppercase; font-size:104px; line-height: 104px; }
.jamaudio_international_all .slider-text .linkline1 { text-transform:uppercase; color:#000; margin: 20px 0 0; float: right; }
.jamaudio_international_all .slider-text a { color: #000; text-decoration: none; }
.jamaudio_international_all .slider-text .linkline2 { font-weight: bold; }

/*text position with "left", "right", "top", "bottom" classes*/
.jamaudio_international_all .slider-text{
  &.left,
  &.right{
    margin:auto;
    height:30%;
    top:0;
    bottom:27%;
    right:0;
    left:0;
  }
  &.left{
    left:8%;
    text-align:left;
  }
  &.right{
    right: 40px;
    left: auto;
    text-align: center;
    width: 50%;
  }
}

.jamaudio_international_all .slider-text.oswald{
  font-family:Oswald-Bold; 
  font-size:100%;
  @include transition(font-size .2s linear);
  
  .line1{
    font-size: 48px;
    max-width: 40%;
    display:block;
    line-height: 100%;
  }
  .line2{
    font-family:Oswald-Light;
    font-size: 22px;
    max-width: 50%;
    margin-top:.2vw;
    display:block;
    line-height: 130%;
    letter-spacing:4px;
  }
  &.right{
    .line1,.line2{
      margin:auto;
    }
  }
  a.button{
    border-radius: 0;
    -moz-border-radius: 0;
    padding:1vw 1.3vw;
    height: auto;
    font-size:1.3vw;
    margin-top:1vw;
    @include transition(font-size .2s linear);
    text-transform: uppercase;
    font-family: Oswald;
    text-shadow: none !important;
    letter-spacing: 1px;
    
    @include transition(background-color .2s linear);
    
    &:hover{
        background-color: #00caee;
    }
  }
}

$dark-banner-text-color:#000;
$light-banner-text-color:#fff;

.jamaudio_international_all .slider-text{
  color:$dark-banner-text-color;
  .linkline1:hover,.linkline2:hover{
    color:$dark-banner-text-color;
  }
  span,.linkline1,.linkline2{
    color:$dark-banner-text-color;
  }
  a{
    color:$dark-banner-text-color;
    span,.linkline1,.linkline2{
      color:$dark-banner-text-color;
    }
  }
  a:hover{
    span,.linkline1,.linkline2{
      color:$dark-banner-text-color;
    }
  }
  a:hover,span:hover{
    color:$dark-banner-text-color;
  }
}

.jamaudio_international_all .slider-text.white{
  color:$light-banner-text-color;
  .linkline1:hover,.linkline2:hover{
    color:$light-banner-text-color;
  }
  span,.linkline1,.linkline2{
    color:$light-banner-text-color;
  }
  a{
    color:$light-banner-text-color;
    span,.linkline1,.linkline2{
      color:$light-banner-text-color;
    }
  }
  a:hover{
    span,.linkline1,.linkline2{
      color:$light-banner-text-color;
    }
  }
  a:hover,span:hover{
    color:$light-banner-text-color;
  }
}

/* jam specific (Jam Audio - all non-US stores ) */
.jamaudio_international_all .slider-text.transitOE .line2 { font-size: 124px; }
.jamaudio_international_all .slider-text.transitOE .line1 { font-size: 55px; }
.jamaudio_international_all .slider-text.transitOE .linkline1 { font-size: 18px; }

.jamaudio_international_all .slider-text.touch .line2 { font-size: 92px; }
.jamaudio_international_all .slider-text.touch .line1 { font-size: 50px; }
.jamaudio_international_all .slider-text.touch .linkline1 { font-size: 18px; }

.jamaudio_international_all .slider-text.rewind .line2 { font-size: 92px; }
.jamaudio_international_all .slider-text.rewind .line1 { font-size: 56px; }
.jamaudio_international_all .slider-text.rewind .linkline1 { font-size: 18px; }

.jamaudio_international_all .slider-text.transitIE .line2 { font-size: 110px; }
.jamaudio_international_all .slider-text.transitIE .line1 { font-size: 44px; }
.jamaudio_international_all .slider-text.transitIE .linkline1 { font-size: 18px; }


@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev { opacity: 1; left: 10px;}
  .flex-direction-nav .flex-next { opacity: 1; right: 10px;}
}

@media screen and (max-width: $bp_medium_legacy) {
  .jamaudio_international_all .slider-text.oswald{
    &.left,&.right{
      top:5%; bottom:0;
    }
    &.right{
      right:20px;
    }
    .line1{
      font-size: 9vw !important;
      max-width:none;
    }
    .line2{
      font-size: 6.5vw !important;
      letter-spacing: 1px;
      max-width:none;
    }
  }

    /* FLEXSLIDER BANNER TEXT (Jam Audio USA only) */
    .jamaudio_usa .slider-text { font-size: 12px !important; }
    .jamaudio_usa .slider-text .line1 { text-transform:uppercase; font-size: 30px !important; font-weight:bold; line-height: 38px !important; }
    .jamaudio_usa .slider-text .line2 { text-transform:uppercase; font-size:48px !important; line-height: 25px !important; }
    .jamaudio_usa .slider-text .linkline1 { text-transform:uppercase; color:#fff; margin: 20px 0 0; float: right; padding-top: 2px; padding-bottom: 3px; padding-left: 4px; padding-right: 4px; text-align: center; width: 100%; }
    .jamaudio_usa .slider-text .linkline2 { font-weight: bold; }
    
    .jamaudio_usa .slider-text.transitIE .line2 { font-size: 194px; line-height: 148px; height: auto; margin-bottom: 10px; }

    /* FLEXSLIDER BANNER TEXT (Jam Audio - all non-US stores ) */
    .jamaudio_international_all .slider-text { font-size: 12px !important; }
    .jamaudio_international_all .slider-text .line1 { text-transform:uppercase; font-size: 30px !important; font-weight:bold; line-height: 38px; }
    .jamaudio_international_all .slider-text .line2 { text-transform:uppercase; font-size:48px !important; line-height: 25px; }
    .jamaudio_international_all .slider-text .linkline1 { text-transform:uppercase; color:#000; margin: 20px 0 0; float: right; }
    .jamaudio_international_all .slider-text .linkline2 { font-weight: bold; }
    
    
    /*text position with "left", "right", "top", "bottom" classes*/
    .jamaudio_international_all .slider-text{
      &.left,&.right{
        height:100%;
      }
      &.left{
        left:3%;
      }
      &.right{
        right:9%; 
      }
    }
}

@media screen and (max-width: 500px) {

    /* FLEXSLIDER BANNER TEXT (Jam Audio USA only) */
    .jamaudio_usa .flexslider ul.slides { width: 100%; }
    
    .jamaudio_usa .slider-text { font-size: 9px !important; position:absolute; top:17%; right:3%; }
    .jamaudio_usa .slider-text .line1 { text-transform:uppercase; font-size: 20px !important; font-weight:bold; line-height: 38px !important; }
    .jamaudio_usa .slider-text .line2 { text-transform:uppercase; font-size:32px !important; line-height: 6px !important; }
    .jamaudio_usa .slider-text .linkline1 { text-transform:uppercase; color:#fff; margin: 5px 0 0; float: right; font-size: 14px !important; padding-top: 2px; padding-bottom: 3px; padding-left: 4px; padding-right: 4px; text-align: center; width: 100%; }
    .jamaudio_usa .slider-text .linkline2 { font-weight: bold; }
    
    .jamaudio_usa .flex-control-paging li a { width: 5px; height: 5px; }
    .jamaudio_usa .flex-control-nav { position: absolute; right: 0%; bottom: 0%; }
    .jamaudio_usa .flex-control-nav li { margin: 0 1px; } 
    
    .jamaudio_usa .slider-text.transitIE .line2 { margin-bottom: 20px; }
    
    /* FLEXSLIDER BANNER TEXT (Jam Audio - all non-US stores ) */
    .jamaudio_international_all .flexslider ul.slides { width: 100%; }
    
    .jamaudio_international_all .slider-text { font-size: 9px !important; position:absolute; top:17%; right:3%; }
    .jamaudio_international_all .slider-text .line1 { text-transform:uppercase; font-size: 20px !important; font-weight:bold; line-height: 38px; }
    .jamaudio_international_all .slider-text .line2 { text-transform:uppercase; font-size:32px !important; line-height: 6px; }
    .jamaudio_international_all .slider-text .linkline1 { text-transform:uppercase; color:#000; margin: 5px 0 0; float: right; font-size: 14px !important; }
    .jamaudio_international_all .slider-text .linkline2 { font-weight: bold; }
    
    .jamaudio_international_all .flex-control-paging li a { width: 5px; height: 5px; }
    .jamaudio_international_all .flex-control-nav { position: absolute; right: 0%; bottom: 0%; }
    .jamaudio_international_all .flex-control-nav li { margin: 0 1px; }


}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
   /* IE10+ specific styles go here */  
   /* FLEXSLIDER BANNER TEXT */
    .slider-text { font-size: 24px; position:absolute; top:24%; text-align: right; }
    .slider-text.left { left:7%; }
    .slider-text.right { right:7%; }
   
}

