/*
 * 
 * JamAudo 2015 SCSS Variables
 * 
 */

/*
 * PALLETTE
 */
$jam_lightblue: #40b4e5;
$jam_lightgray: #f2f2f2;
$jam_nearblack: #201d1e;

/*
 * JAMAUDIO SPECIFIC
 */
$btn_color1: $jam_lightblue;

$header-text-color: $jam_nearblack;

/*font family*/
$font1_bold: Oswald-Bold;
$font1_normal: Oswald;
$font1_light: Oswald-Light;

/*BREAKPOINTS*/

$bp_max: 1300px;
$bp_largest: 1120px;
$bp_large: 980px;
$bp_medium: 740px;
$bp_medium_legacy: 700px;
$bp_small: 540px;
$bp_smallest: 480px;