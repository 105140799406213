/* Category Page ========================================================================= */
.category-view {  }

.category-title { margin-bottom:0; border:1px solid #cfcfcf; border-bottom:0; text-align:left; }
.category-title .rss-feed { float:right; margin:7px 0 0; padding-left:17px; line-height:12px; background:url(../images/icon_rss.gif) 0 0 no-repeat; white-space:nowrap; text-decoration:none; }
.category-title .rss-feed:hover { text-decoration:underline; }

.category-view .note-msg,
.category-description { width: 975px; margin: 0 auto; padding: 20px; overflow: visible; }

.results-view { margin:0 -20px -20px; }
.results-view .recommendations { padding:20px 20px 0; }
.catalogsearch-result-index .page-title { margin-bottom:0; }
.catalogsearch-result-index .note-msg { margin:20px 0; }
.catalogsearch-result-index .breadcrumbs { padding-top: 20px; width: 975px; display: none;}
.search-results { padding-top: 10px; }
.search-results h1 { font-size: 2em; }

.sidebar .category-title { margin:0; }
.category-image { text-align:center; margin:0 0 1px; }
.category-image img { display:block; margin:0 auto; }

div.category-products { padding: 10px 1rem 0 1rem; }
.container div.category-products { border-top: 1px solid #000; }
.category-products .price-box {  }



/* View Type: Grid */
.products-grid,.category-intro,.product-compare-info { padding:0; margin: 0 auto; width: 100%; max-width:1319px; position: relative; z-index: 999; margin-bottom: 0; }
ul.products-grid { border-bottom: 1px solid #eee; padding: 20px 0; }
.category-intro,.product-compare-info{padding:1rem;}
.category-intro h1,.product-compare-info > .row{padding:0 1rem;}
.category-intro{
  padding-bottom:0;}

.category-intro{
   h1{
      font-family: Oswald; 
      font-size: 2.2rem;
      line-height: 110%;
      text-transform:uppercase;
      padding-top:1rem;
    }
}

.products-grid li.item { float:left; width:25%; padding: 0 4vw; box-sizing:border-box; -moz-box-sizing:border-box; text-align: left; position: relative; }
.products-grid li.item:last-child{}
.products-grid li.item:first-child{}
.products-grid .actions {}
.products-grid .product-image { display:block; text-decoration:none !important; margin:10px auto; position:relative; }
.products-grid .product-name { color: #333; }
.products-grid .product-name a:hover { color: #828282; }
.products-grid .ratings { text-align:left; line-height:13px; }
.box-up-sell .products-grid .ratings { display: none; }
.products-grid .rating-links { display:block; padding:2px 0 0; clear:both; }
.products-grid .price-box { color: #333; font-family:$font1-light;font-size:1vw; }
.products-grid .special-price .price-label,
.products-grid .special-price .price{font-family:$font1-light;font-size:1vw;}
.category-products .product-name a { color:#333; font-family:$font1-light; font-weight:normal; font-size:1.2vw; text-transform:none;}
.category-products .product-name a strong{font-family:$font1-bold;}
.category-products .product-name a strong:first-child{text-transform:uppercase;}
.products-grid .regular-price .price{font-weight:normal;}
.products-grid .prices-wrap{position:relative;margin-top:1rem;}
.products-grid .prices-wrap .buy-now{font-family:$font1-bold;text-transform:uppercase;font-size:.8vw;}
.products-list .buy-now{font-family:$font1-bold;text-transform:uppercase;font-size:.8vw; display:block; margin-top: 10px; }
.products-grid .product-image img{max-width:100%;height:auto;}
.products-grid .tagline{font-family:$font1-normal;font-size:1rem;}


img.newIcon, img.saleIcon { position: absolute; top: 0; left: 50px; }
.new-feature { position: absolute; top: 0; }
.new-feature span { background: #00adef; color: #fff; padding: 0 15px; text-transform: uppercase; }

.fullwidthbanner-container.category img { width: 100%; }
.category-view  .container h3 { background: #000; color: #fff; text-transform: uppercase; font-size: 24px; display: inline-block; padding: 10px 25px; margin-bottom: 0; }

/*1300*/
@include breakpoint($bp_max){
  .category-intro{
    h1{
      padding:0;
      padding-top:0;
    }
  }
  .category-products .product-name a{font-size:1.4vw;}
  .products-grid .tagline{font-size:1.1vw;}
  .products-grid .price-box,
  .products-grid .special-price .price-label,
  .products-grid .special-price .price{font-size:1.2vw;}
  .products-grid .prices-wrap .buy-now{font-size:1vw;}
}
/*1120*/
@include breakpoint($bp_largest){
  .category-products .product-name a{font-size: 1.7vw;}
  .products-grid .tagline{font-size:1.4vw;}
  .products-grid .price-box,
  .products-grid .special-price .price-label,
  .products-grid .special-price .price{font-size:1.5vw;}
  .products-grid .prices-wrap .buy-now{font-size:1.3vw;}
}
/*980*/
@include breakpoint($bp_large){
  .category-products .product-name a{font-size: 1.9vw;}
  .products-grid .tagline{font-size:1.6vw;}
  .products-grid .price-box,
  .products-grid .special-price .price-label,
  .products-grid .special-price .price{font-size:1.7vw;}
  .products-grid .prices-wrap .buy-now{font-size:1.5vw;}
}
/*740*/
@include breakpoint($bp_medium){
  .category-intro{
    h1{
      font-size:4.5vw;
    }
  }
  .products-grid{margin-bottom:0;}
  .products-grid li.item{width:50%;margin-bottom:4vw;height:58vw;}

  .category-products .product-name a{font-size: 2.7vw;}
  .products-grid .tagline{font-size:2.2vw;}
  .products-grid .price-box,
  .products-grid .special-price .price-label,
  .products-grid .special-price .price{font-size:2.4vw;}
  .products-grid .prices-wrap .buy-now{font-size:2vw;}

  ul.products-grid { border-bottom: none; }

}
/*540px*/
@include breakpoint($bp_small){
  .products-grid li.item{width:100%;text-align: center;height:auto;  margin-bottom: 20vw;}
  .products-grid .prices-wrap .buy-now{position:relative;}

  .category-products .product-name a{font-size:4.4vw;}
  .products-grid .tagline{font-size:3.5vw;}
  .products-grid .price-box,
  .products-grid .special-price .price-label,
  .products-grid .special-price .price{font-size:4.5vw;}

  .products-grid .special-price .price-label{display:none;}
  .products-grid .prices-wrap .price-box > .old-price{display:none;}
  .products-grid .special-price .price{background-image:url(../images/icons/sale-icon.png);background-repeat:no-repeat;background-position:left center;background-size:24px;padding-left:31px;}

  .products-grid .prices-wrap .buy-now{font-size:4vw;}
  .products-list .buy-now{font-size:4vw;}

  .products-grid{
    .prices-wrap{
      text-align:center;

      > *{
        display:inline-block;
        padding-right:2vw;
      }
      > *:last-child{
        padding-right:0;
      }
      .price-box{
        white-space:nowrap;
        > *{
          display:inline-block;
        }
      }
      a.buy-now{

      }
    }
  }
}
