
.account-order-history-notification {
  padding-bottom: 2em;
  color: #ff6464;
}

.sales-order-view{

  h2{
    margin-bottom:0;
    font-size:1.5em;
  }

  .order-info-box {
    margin-bottom: 10px;
  }

  .title-buttons{
    a{
      float:none;
      background-position-y: 50%;
    }
  }


}

@media screen and (max-width: $bp_medium_legacy) {

  .sales-order-view {
    .order-items {

      h2.table-caption{
        font-size: 1em;
        padding: 2px;
        min-width: 290px;
      }

      h3{
        font-size: calc(8px + 0.6vw);
      }

      th,
      td{
        padding:2px;
      }

    }

  }




}
