.checkout-onepage-index{
    .sp-methods {
        .form-list .input-text{
            margin-top:0px;
            width:auto;
        }
        .form-list select{
            margin-top:0px;
        }
        .input-box{
            width:auto;
            float:none;
        }
    }
}


.sales-order-print{
    button.button{
        line-height: normal;
        span{
            padding:10px;
            span{
                padding:0;
            }
        }
    }

}

.paypal-express-review {

    $vertical-spacing-inner: 30px;

    .info-set.col2-set {
        background: $jam_lightgray;
        margin-bottom: 30px;
        padding:20px;
        padding-top: 10px;
    }

    h1{
        font-family:$font1_normal;
        font-size:32px;
    }

    h2.legend {
        color: $jam-lightblue;
        margin-bottom: $vertical-spacing-inner;
    }

    .info-items h2.legend,
    .col-1 h3 {
        color: black;
        margin-bottom: $vertical-spacing-inner;
        a {
            color: $jam-lightblue;
        }
    }

    .col-1 h3 {
        font-size: 20px;
    }

    .box-content{
        font-size:14px;
    }

    .info-shipping .col-2 {
        margin-top: -70px;  // forgive me, father, for I have sinned
        h3{
            font-size: 2.0em;
            margin-bottom: $vertical-spacing-inner;
        }
    }

}



@media screen and (max-width: $bp_medium_legacy) {

    .paypal-express-review {
        border:10px dashed lime;

        .info-shipping .col-2 {
            margin-top: 20px;
            h3{
                margin-bottom: 8px;
            }
        }

    }


}



