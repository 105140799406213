body{ /* CSS for BODY transition when menu is set to push BODY content. */ 
	-moz-transition: left 100ms ease-in-out, right 100ms ease-in-out;
	-webkit-transition: left 100ms ease-in-out, right 100ms ease-in-out;
	transition: left 100ms ease-in-out, right 100ms ease-in-out;
}

@media screen and (max-width: $bp_medium_legacy) {
.nav-container{ /* shared class for side toggle menus */
	border-right:1px solid #C8C8C8;
	background-color: white;
	width: 170px; /* default menu width */
	height: 100%;
	position: fixed;
	top: -100%;
	clear: both;
	display: block;
	visibility: hidden;
	-moz-transition: all 100ms ease-in-out; /* change 100ms to slide in animation time */
	-webkit-transition: all 100ms ease-in-out;
	transition: all 100ms ease-in-out;
}


.nav-container ul{
	padding: 0;
	margin: 0;
	list-style: none;
}

.nav-container a{

	padding: 10px;
	display: block;
	color: #595959;
	text-decoration: none;
}


.nav-container a:hover{
	background: red;
	color: white;
}

/* #######  Additional CSS for toggle menu #togglemenu2  ####### */

#togglemenu2{
	width: 250px;
	border-width: 0;
	background: rgb(53,106,160);
	box-shadow: -5px 0 5px rgba(174, 174, 174, .8);
}

#togglemenu2 ul a{
	color: white;
	border-bottom: 1px solid #eee;
	font: bold 14px;
	text-transform: uppercase;
}

#togglemenu2 a:hover{
	background: #162a50;
	color: white;
}

}

/* #######  Responsive Menu related CSS  ####### */
/*  CSS for small screen menus toggler, shown when device width is below specified   */
div#smallscreentoggler{  
	width: 1.5em;
	z-index: 10000;
	color: white;
	position: relative;
	float: left;
	overflow: hidden;
	background: gray;
	font: normal 1.8em Arial;
	margin-bottom: 0.5em;
	text-align: center;
	box-shadow: -3px 3px 5px gray;
	cursor: pointer;
	border-radius: 2px;
	display: none;
	-moz-transition: all 200ms ease-in-out;
	-webkit-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
}

div#smallscreentoggler:hover{
	background: #eee;
	color: black;
	-moz-transition: all 200ms ease-in-out;
	-webkit-transition: all 200ms ease-in-out;
	transition: all 200ms ease-in-out;
}


@media screen and (max-width: $bp_medium_legacy) {


body{
  .nav-container {
    left: 0;
    top: 0;
    width: 250px;
    background: rgba(236, 235, 232, 1);
    position: fixed;
    height: 100%;
    height:2000px;
    overflow-y: auto;
    z-index: 1000;
    color: #fff;
    font-weight: 100;
    font-size: 1.25em;
  }
}
#nav {
position: absolute;
top: 61px;
width: 100%;
list-style: none;
margin: 0;
padding: 0;
}
#nav li {
line-height: 50px;
text-indent: 20px;
width: 100%;
border-bottom: 1px solid #C8C8C8;
}
#nav a.level-top {
padding: 5px 25px;
}
#nav li a {
color: #454545;
display: block;
padding: 10px 0;
font-family:Oswald;
text-decoration: none;
-webkit-transition: all .3s ease-out;
-moz-transition: all .3s ease-out;
-o-transition: all .3s ease-out;
transition: all .3s ease-out;
}
#nav li a:hover {
color: $jam_lightblue;
background: none;
text-decoration: none;
}
#nav li a:active, #nav li a:focus {
text-decoration: none
}

#nav li#close { position:absolute; left:180px; width: 35px;}
#nav li#close a { text-align: right; margin-right: 10px; }
#nav li#close a:hover {
color: #fff;
background: none;
text-decoration: none;
padding-left: 0px;
}

#nav li.nav-1 { margin-top:9px; }

}

