$dropdown-top-border:'data:image/gif;base64,R0lGODlhAQAKAKIAAOfn5/z8/PLy8tjY2P///wAAAAAAAAAAACH/C1hNUCBEYXRhWE1QPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMwMTQgNzkuMTU2Nzk3LCAyMDE0LzA4LzIwLTA5OjUzOjAyICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjAxODAxMTc0MDcyMDY4MTE4MDgzRTU3REYzNEQwNDI5IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjExOEY1Q0ZBREZBQTExRTRCRUE1QzI4NTFFOUI0RjE1IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjExOEY1Q0Y5REZBQTExRTRCRUE1QzI4NTFFOUI0RjE1IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDQyAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOmYxMGQ3ZGYxLThlODMtNDIwZS1iNzQyLTgwYmM4YjVhZmQ2ZSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowMTgwMTE3NDA3MjA2ODExODA4M0U1N0RGMzREMDQyOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PgH//v38+/r5+Pf29fTz8vHw7+7t7Ovq6ejn5uXk4+Lh4N/e3dzb2tnY19bV1NPS0dDPzs3My8rJyMfGxcTDwsHAv769vLu6ubi3trW0s7KxsK+urayrqqmop6alpKOioaCfnp2cm5qZmJeWlZSTkpGQj46NjIuKiYiHhoWEg4KBgH9+fXx7enl4d3Z1dHNycXBvbm1sa2ppaGdmZWRjYmFgX15dXFtaWVhXVlVUU1JRUE9OTUxLSklIR0ZFRENCQUA/Pj08Ozo5ODc2NTQzMjEwLy4tLCsqKSgnJiUkIyIhIB8eHRwbGhkYFxYVFBMSERAPDg0MCwoJCAcGBQQDAgEAACH5BAAAAAAALAAAAAABAAoAAAMFOCBB/gkAOw==';
$mobile-nav-height: 76px;

html,body{height:auto;overflow:hidden;overflow-y:auto;}

.header-wrapper {
  width:inherit;
  position:absolute;
  z-index: 9999;
}

.topbar-container { 
  height:20px;
  background:#000;
  font-size:10px;
  text-transform:uppercase;
  padding-top:4px;
  
  .topbar { 
    width:960px; 
    margin:0 auto; 
  }
  
  .top-bar-inner {
    width:auto;
    float:right;
    white-space:nowrap;
    
    > *{
      display:inline-block;
    }
    
      ul.links, 
      ul.links li {
        float: none;
        white-space:nowrap;
        display:inline-block;
      }
      ul.links li a {
        padding-left:23px;
        text-align: right;
        background:url('../images/icon_account.png') no-repeat left;
      }

      /* country selector */
      #countrySelect { float: none; vertical-align:top; width: 142px; background: url(../images/downarrow.png) no-repeat right; color: #fff; }
      #countrySelect .countrySelectMenu { 
        background: transparent;
        width: 175px;
        position: absolute;
        top: 10px;
        display: none;
        z-index: 999;
        > ul{
          background-color: #fff;
          padding: 10px;
          border: 1px solid #eee;
          margin-top: 14px;
          box-shadow: -1px 2px 3px rgba(0,0,0,.5);
        }
       }
      #countrySelect:hover .countrySelectMenu { display: block; }
      .countrySelectMenu li {  }
      .countrySelectMenu li a { display: block; padding: 0 10px;  }
      .countrySelectMenu li a:hover { background: #eee; color: #111; }      
      .countrySelectButton {
        text-align: right;
        background:url('../images/icon_world.png') no-repeat left;
      }
      
  }  
}

.header-container {
  background:rgba(236, 235, 232, 0.95);
  color:$header_text_color;
  border-bottom:solid 1px rgba(200,200,200,0.95);
  
  .header {
    padding: 0;
    position: relative;
    z-index: 5;
    width: 960px;
    margin: 0 auto;
  }
  
  .branding {
    height: 56px;
    width: 130px;
    float: left;
    padding-top: 10px;
    
    h1 {margin-bottom:0px;}
  }
  
  .nav-container {
    position: relative;
    z-index: 4;
    font-size: 14px;
    line-height: 480%;
    font-weight: normal;
    float: left;
    margin-top: 0px;
    margin-left: 0px;
  }
  
  .top-cart {
    display: block;
    float: left;
    height: 62px;
    width: 65px;
    padding-top: 5px;
    
    .block-title {
      white-space: nowrap;
      cursor: pointer;
      background-image: url('../images/icon_cart.svg');
      background-position:2px center;
      background-repeat:no-repeat;
      background-size:31px;
      color: #A9E900;
      font-weight: bold;
      font-size: 11px;
      text-transform: uppercase;
      height: 45px;
      padding-top: 17px;
      margin-left:10px;
      position:relative;
    }
  }
  
}


/* push content down coz of static sticker header */
.main.col1-layout, .col2-left-layout, .col2-right-layout, .col3-layout {
  padding-top:91px;
  padding-left: 5px; 
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.cms-index-index, .catalog-category-view, .catalog-product-view{ 
  .main.col1-layout, .col2-left-layout {
    padding-left:0;
  }
}

.cms-index-index .main.col1-layout {
  padding-top:24px;
}
.cms-index-index .messages.not-add-cart + .main.col1-layout{
  padding-top:0;
}


/* Navigation */
@media screen and (min-width:$bp_medium_legacy) {
  
.header-search:hover { background:#FFF; }
  
#nav { 
  margin: 0 auto; 
  text-align:left; 

  /* 1st Level (Top Level) */
  a.level-top { 
    float:left; 
    padding:0 20px 0 20px; 
    color:#454545; 
    transition: color 125ms ease-in 0s;
    text-transform:uppercase; 
    font-weight:normal;
    font-family:Oswald;
  }
  a.level-top:hover { color:#454545; text-decoration:none; }
  li.level-top { 
    float:left; 
    position:relative; 
    z-index:1; 
    border-left: 1px solid #D9D1CE;   
  }
  li.level-top:last-child { border-right: 1px solid #D9D1CE; position:relative; }

  li.level-top.over { background-color:rgba(255,255,255,1); opacity:1; transition: background 125ms ease-in 0s; }
  li.level-top:hover { background-color:rgba(255,255,255,1); opacity:1; transition: background 125ms ease-in 0s; }
  
  li.level-top.over.parent:before{content:"";display:block;position:absolute;top:91%;left:0;right:0;margin:auto;
    width:30px; 
    height:30px;
    background-image:url(../images/arrow_down.svg);
    background-repeat:no-repeat;
    background-position:top center;
    background-size:100%;
    z-index:96;
  }
  li.level-top.over.parent:after{content:"";display:block;position:absolute;bottom:0;left:0;width:100%;height:10%;background-color:#fff;z-index:99;}
  
  li.level-top.parent{
     > a{ 
       padding-right:27px;
       
       > span{
         position:relative;
       }
       > span:after{
        content:"";
        display:block;
        position:absolute;
        right:-15px;
        top:0;
        bottom:0;
        margin:auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 5.5px 0 5.5px;
        border-color: #bababa transparent transparent transparent;
        vertical-align: middle;
        margin-left: .3rem;
      }
    }
  }
  
  a { color:#fff; transition: color 125ms ease-in 0s; }
  a:hover { color : #A9E900; transition: color 225ms ease-out 0s; }

  /* 2nd Level */
  ul { position:fixed; width:100%; top:65px; left:-10000px; background:#fbfbfb; padding:5px 11px 10px; border:1px solid #aeaeae; border-top:0; opacity:1.0; } 
  ul.shown-sub, ul.shown-sub-hiding { top:91px; left:0px; width:100%; right:0; opacity:1; box-shadow: 0 2px 3px rgba(0,0,0,.3);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  background-image:url($dropdown-top-border);
  background-repeat:repeat-x;
  background-position:top left;}
  
  ul li { width:100%; opacity:1;  height: auto;line-height: 100%; }
}
}

/* Nav Dropdowns */

.dropdown-nav {
  width:100%;
  margin:0 auto;
  opacity:1;
  text-align: center;
  
  .item {
    max-width:30%;
    padding-right:3%;
    
    display: inline-block;
    margin:0 auto;
    
    .prod-image {
      max-width:100%;
      width:6vw;
      margin:0 auto;
      height: auto;
      display: block;
      line-height: 100%;
  
      img{
        width:100%; 
      }
    }
    .prod-title {
      font-weight: bold;
      text-align: center;
      height: auto;
      line-height: 100%;
    }
    .prod-desc {
      text-align:center;
      color:#333;    
      line-height: 100%;
      padding: .5rem 0;  
    }
    
  }
  
}

/* Top Shopping Cart */
.top-cart { 
  float:none; 
  height: 67px; 
  width: 70px; 

  .block-title { 
    white-space:nowrap; 
    cursor:pointer; 
    background: url(../images/btn-topcart.png) 5px 15px no-repeat; 
    color:#A9E900; 
    font-weight:bold; 
    font-size:11px; 
    text-transform:uppercase; 
    height:45px; 
    padding-top: 17px; 
   }
  .block-title strong { 
    display:block; 
    font-size:1.5em; 
    color:#454545; 
    text-decoration:none; 
    text-align:right;
    border-radius: 11px;
  }
  .block-content { position:absolute; right:0; top:68px; width:100%; background:#fff; z-index: 2; border: 1px solid #eee;}
  .inner-wrapper { padding-bottom:1rem;float:left;width:100%; }

  .block-content .block-subtitle { font-size:2em; color:#454545; font-weight:bold; line-height:1.25em; padding:5px 10px; font-family: Oswald-Light; border-bottom: 1px solid #eee; }
  .block-content .block-subtitle .close-btn { 
    float:right; 
    width:13px; 
    height:0; 
    padding-top:12px; 
    text-align:left; 
    overflow:hidden; 
    cursor:pointer; 
    background:url('../images/icon_close.png') no-repeat 0 0; 
    position:relative; 
    z-index:1; 
    margin-top: 10px;
  }
  .cart-empty .close-btn { 
    float:right; 
    width:13px; 
    height:0; 
    padding-top:12px; 
    text-align:left; 
    overflow:hidden; 
    cursor:pointer; background:url('../images/icon_close.png') no-repeat 0 0; 
    position:relative; 
    z-index:1; 
  }
  .cart-empty { padding:10px 10px 10px 20px; }
  .block-content ol { padding-bottom:7px; }
  .block-content .item { padding:8px 5px 8px 11px; }
  .block-content .item .product-name { font-size:1.5em; line-height:14px; margin: 10px 0; line-height: 20px; }
  .block-content .item .product-name a { text-decoration:none; color:#444; }
  .block-content table { margin:5px 0 0 0; }
  .block-content table th { padding:1px 8px; color:#8f8f8f; text-align:right; }
  .block-content table td { text-align:left; padding:1px 0; }
  .block-content .subtotal { padding:2px 5px; text-align:center; color: #000; font-weight: bold; }
  
  .block-content ol { width: 65%; float: left;max-height:498px;overflow-y:auto;border-bottom:1px solid #eee; }
  .actions-wrapper { width: 30%; float: right; margin-top: 5%; border-left: 1px solid #eee; }

  .actions {  text-align:center; padding:5px 11px; }
  .actions a { line-height:23px; }
  .actions li{list-style: none;}
  .actions button.button {
        height: 30px;
        line-height:30px !important;
        background-color: #00ccff;
        text-shadow: none !important;
        border-radius:0;
        padding: 0 1.5rem;
        text-decoration: none;
        font-weight: normal;
    
        @include transition(background-color .3s linear);
        
        &:hover{
          background-color:#00A0C8;
        }
      
        > span{
          padding: 0;
          height: inherit;
          background-color: transparent;
          display:block;
          line-height:inherit;
          text-decoration:inherit;
          font-weight:inherit;
          background-image: none !important;
          
          > span{
            padding: 0;
            height: inherit;
            font-family: Oswald-Light;
            font-size:1rem;
            letter-spacing: 1px;
            background-color: transparent;
            display:block;
            line-height:inherit;
            color: #fff;
            text-decoration:inherit;
            font-weight:inherit;
            text-transform:uppercase;
            background-image: none !important;
          }
        }
      } 
  .actions button.button span,
  .actions button.button span span { background-image:url(../images/bkg_btn-onbg.gif); background-repeat:no-repeat; }
  .paypal-logo .paypal-or { clear:both; display:block; margin: 4px auto; }


}

#menutoggle{top:-99999px !important;position:fixed; }


/* Search */
.header-search {
  float:left;
  height: 67px;
  width:60px;
  border-right: 1px solid #D9D1CE;  
  
  .search-icon {
    width:60px;
    height:58px;
    padding-top: 9px;
    
    .search {
      padding-top: 15px;
      padding-left: 18px; 
      width: 24px;
    }
  }
  #search_mini_form {
    display:none;
    background:rgba(200,200,200,0.95);
    position:absolute;
    left: 0;
    padding:15px;
    padding-top:25px;
    padding-bottom:10px;
    width: 95%;
    .close-btn { background:url('../images/icon_close.png') no-repeat 0 0; float:right; height:15px; width:15px; }
    .close-btn:hover { cursor:pointer; }
  } 
  
  .close-btn { 
    position: absolute;
    right: 15px;
    top: 35px;
  }
 
  .form-search {  padding: 0; margin-right: 30px; margin-left: 20px; white-space:nowrap; position: relative; top: -6px; }
  .form-search label { display:none; }
  .form-search input { border: 0; height: 26px; }
  .form-search input.input-text { 
    background-color: #fff; 
    padding: 8px; 
    width:88%; 
    color: #454545; 
    font-family: 'Oswald-Light', sans-serif;
    text-transform: uppercase;
    font-size: 20px;
  }
  .form-search input.input-text::-ms-clear { display: none; }
  .form-search a { display:none; }
  
  .form-search button { line-height: 26px; font-family: 'Oswald', sans-serif; text-transform: uppercase; }
  .form-search button:hover { background: none; background-color: $jam_lightblue; }
  .form-search button:active { bottom: initial; right: initial; position: initial; z-index: initial; }
  .form-search button.button { margin:0px; height: 40px; line-height: 40px; }
  .form-search button.button span { height: 40px; padding: 0px 10px; line-height: 40px; }
  .form-search button.button span span { padding: 0px; background: none; height: 40px; line-height: 40px; font-size: 16px; text-shadow: none; letter-spacing: 1px; }
  
  .form-search .search-autocomplete { z-index:999; position:relative!important; top:0 !important; left:0 !important; height:0 !important; overflow:visible !important; }
  .form-search .search-autocomplete ul { border:1px solid #c7c7c7; border-top:0; padding-left:0; width:210px; background-color:#fcf8f4; position:absolute; top:-1px; }
  .form-search .search-autocomplete li { padding:4px 6px 4px 24px; border-bottom:1px solid #fff; font-size:12px; cursor:pointer; }
  .form-search .search-autocomplete li .amount { float:right; font-weight:bold; }
  .form-search .search-autocomplete li.selected {}
  .form-search .search-autocomplete li.last { border-bottom:0; } 
 
 
   
}

.header .quick-access {
  height:67px;
  width: 150px;
  float: right;
}

/*
 * 
 * Messaging
 * 
 */


#messages_product_view {
  margin:0;
  
  .messages {
      margin: 10px auto 10px;
  }
}

@include breakpoint(1020px) { 
  .topbar-container .top-bar-inner{margin-right:1.5rem;}
  .header-container {background: rgba(236, 235, 232, 1);}
  .cms-index-index .main.col1-layout{padding-top: 92px;}
}


@include breakpoint(990px) {
  .topbar-container .topbar,
  .header-container .header {
    width: 100%;
    .branding {
      width:110px;
    }
    .logo {
      margin-left: 3px;
    }
    .nav-container{
      #nav a.level-top{
        padding:0 12px;
      }
    }
  }
  .dropdown-nav {
    width: 100%;
  }
}

@include breakpoint(800px) { 

  .header-search .close-btn { 
    position: absolute;
    right: 15px;
    top: 35px;
  }
  
  .header-search .form-search input.input-text { 
    width:86%; 
   }
  
}

@include breakpoint(740px) { 
  
  .header-search {  }  
  .header .quick-access {  }
  
}
@include breakpoint($bp_medium_legacy) {
  
  .topbar-container{
    height:5px;
    
    .topbar{
      display:none;
    }
  }
  
  #menutoggle{top:0px !important;}
  #desktopmenu{display:none;}

  /* push content down coz of static sticker header */
  .main.col1-layout {
    padding-top:$mobile-nav-height;
  }
  
  .cms-index-index .main.col1-layout {
    padding-top:$mobile-nav-height;
  }    

  .header-container .branding {
    margin: 0 auto;
    height: 56px;
    width: 150px;
    padding-top: 10px;
    float: none;
    
    .logo { 
      float:left;
     }
  }
  
  a.jamburger { 
    width:26px; height:22px; 
    color:#454545;
    position: absolute;
    margin: auto;
    top: 0;
    left: 14px;
    bottom: 0;
    
    > span{
      background-color:#454545;
      display:block;
      height:22%;
      margin:auto;
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
      
      @include transition(all .3s linear);
      
      &:first-child{
        bottom:auto;
      }
      &:last-child{
        top:auto;
      }
    }
    
    &.open-mode{
      > span{
        &:first-child, &:nth-child(2){
          width:100%;
          height:5px;
        }
        &:first-child{
          @include rotate(135deg);
          left: auto;
          top: auto;
          right: 0;
          bottom: 8px;
        }
        &:nth-child(2){
          @include rotate(45deg);
        }
        &:last-child{
          width:0;
        }
      }
    }
    
  }
    
  .header-container .top-cart .block-title {  
      margin-top: 19px;
      margin-left: 4px;
      background-image: url('../images/icon_cart.svg');
      background-repeat: no-repeat;
      background-position: 7px center;
      background-size: 26px;
   }
   .top-cart .block-title strong {
      display: block;
      font-size: 1.5em;
      color: #454545;
      text-decoration: none;
      text-align: right;
      border-radius: 11px;
   }

  ul.level-top-drop,
  .header-search #search_mini_form .close-btn  { display:none; visibility:hidden; }
  
  #nav li.nav-1 { margin-top: 0px; }
  #nav li.level-top { margin-top: 10px; }
  
  .header-search { 
    display:block;
    float: left;
    height: 67px;
    border-right: none;
    padding-right: 10px; 
    .search-icon { display:none; visibility:hidden; }
    #search_mini_form {
      display:block;
      position:absolute;
      top:0; right:0; bottom:0; left:0;
      margin:auto;
      z-index:9999;
      padding:0;
      padding-top:5px;
    }
  .form-search {
    float: none;
    padding: 0;
    white-space: nowrap;
    position: relative;
    top: -6px;
    width: 70%;
    margin: 0 auto;
  }  
  .form-search input {
    border: 1px solid #454545;
    height: 25px;
  }    
  } 
  .header .quick-access { width: 100%; height:30px; top: 65px;}
  .top-cart .block-content {
    position: absolute;
    right: 15px;
    top: ($mobile-nav-height+10);
    width: 300px;
    background: #fdfdfd;
    z-index: 9999;
  }
  
  .top-cart .block-content ol { width: 100%; float: none; }
  .top-cart .actions-wrapper { width: 100%; float: none; margin-top: 5%; border-left: none; border-top: 1px solid #eee; }
  
  .header-search .form-search input.input-text { width: 100%; }
  .header-search .form-search button.button span span {  background: url('../images/search-arrow.png') no-repeat 4px 5px; }
  .header-search .form-search input.input-text { background: #EEE url('../images/search-mag-glass.png') no-repeat 4px 3px; }
  
  .header-container {  opacity:1; background:#FFF; }
  .header-search #search_mini_form { background: #FFF; display:none; }  
  
}

