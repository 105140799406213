/* line 3, ../../src/scss/sidr/_base.scss */
.sidr {
  /* Default Settings */
  display: none;
  position: absolute;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 999999;
  width: 260px;
  overflow-x: none;
  overflow-y: auto;
  /* Theme Settings */
  font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
  font-size: 15px;
  background: #333333;
  color: white;
  -webkit-box-shadow: inset 0 0 5px 5px #222222;
  -moz-box-shadow: inset 0 0 5px 5px #222222;
  box-shadow: inset 0 0 5px 5px #222222;
}
/* line 15, ../../src/scss/sidr/_base.scss */
.sidr .sidr-inner {
  padding: 0 0 15px;
}
/* line 18, ../../src/scss/sidr/_base.scss */
.sidr .sidr-inner > p {
  margin-left: 15px;
  margin-right: 15px;
}
/* line 24, ../../src/scss/sidr/_base.scss */
.sidr.right {
  left: auto;
  right: -260px;
}
/* line 29, ../../src/scss/sidr/_base.scss */
.sidr.left {
  left: -260px;
  right: auto;
}
/* line 41, ../../src/scss/sidr/_base.scss */
.sidr h1, .sidr h2, .sidr h3, .sidr h4, .sidr h5, .sidr h6 {
  font-size: 11px;
  font-weight: normal;
  padding: 0 15px;
  margin: 0 0 5px;
  color: white;
  line-height: 24px;
  background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #4d4d4d), color-stop(100%, #1a1a1a));
  background-image: -webkit-linear-gradient(#4d4d4d, #1a1a1a);
  background-image: -moz-linear-gradient(#4d4d4d, #1a1a1a);
  background-image: -o-linear-gradient(#4d4d4d, #1a1a1a);
  background-image: linear-gradient(#4d4d4d, #1a1a1a);
  -webkit-box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.2);
}
/* line 52, ../../src/scss/sidr/_base.scss */
.sidr p {
  font-size: 13px;
  margin: 0 0 12px;
}
/* line 55, ../../src/scss/sidr/_base.scss */
.sidr p a {
  color: rgba(255, 255, 255, 0.9);
}
/* line 60, ../../src/scss/sidr/_base.scss */
.sidr > p {
  margin-left: 15px;
  margin-right: 15px;
}
/* line 65, ../../src/scss/sidr/_base.scss */
.sidr ul {
  display: block;
  margin: 0 0 15px;
  padding: 0;
  border-top: 1px solid #1a1a1a;
  border-bottom: 1px solid #4d4d4d;
}
/* line 72, ../../src/scss/sidr/_base.scss */
.sidr ul li {
  display: block;
  margin: 0;
  line-height: 48px;
  border-top: 1px solid #4d4d4d;
  border-bottom: 1px solid #1a1a1a;
}
/* line 81, ../../src/scss/sidr/_base.scss */
.sidr ul li:hover, .sidr ul li.active, .sidr ul li.sidr-class-active {
  border-top: none;
  line-height: 49px;
}
/* line 85, ../../src/scss/sidr/_base.scss */
.sidr ul li:hover > a, .sidr ul li:hover > span, .sidr ul li.active > a, .sidr ul li.active > span, .sidr ul li.sidr-class-active > a, .sidr ul li.sidr-class-active > span {
  -webkit-box-shadow: inset 0 0 15px 3px #222222;
  -moz-box-shadow: inset 0 0 15px 3px #222222;
  box-shadow: inset 0 0 15px 3px #222222;
}
/* line 90, ../../src/scss/sidr/_base.scss */
.sidr ul li a, .sidr ul li span {
  padding: 0 15px;
  display: block;
  text-decoration: none;
  color: white;
}
/* line 97, ../../src/scss/sidr/_base.scss */
.sidr ul li ul {
  border-bottom: none;
  margin: 0;
}
/* line 100, ../../src/scss/sidr/_base.scss */
.sidr ul li ul li {
  line-height: 40px;
  font-size: 13px;
}
/* line 104, ../../src/scss/sidr/_base.scss */
.sidr ul li ul li:last-child {
  border-bottom: none;
}
/* line 110, ../../src/scss/sidr/_base.scss */
.sidr ul li ul li:hover, .sidr ul li ul li.active, .sidr ul li ul li.sidr-class-active {
  border-top: none;
  line-height: 41px;
}
/* line 114, ../../src/scss/sidr/_base.scss */
.sidr ul li ul li:hover > a, .sidr ul li ul li:hover > span, .sidr ul li ul li.active > a, .sidr ul li ul li.active > span, .sidr ul li ul li.sidr-class-active > a, .sidr ul li ul li.sidr-class-active > span {
  -webkit-box-shadow: inset 0 0 15px 3px #222222;
  -moz-box-shadow: inset 0 0 15px 3px #222222;
  box-shadow: inset 0 0 15px 3px #222222;
}
/* line 119, ../../src/scss/sidr/_base.scss */
.sidr ul li ul li a, .sidr ul li ul li span {
  color: rgba(255, 255, 255, 0.8);
  padding-left: 30px;
}
/* line 128, ../../src/scss/sidr/_base.scss */
.sidr form {
  margin: 0 15px;
}
/* line 132, ../../src/scss/sidr/_base.scss */
.sidr label {
  font-size: 13px;
}
/* line 146, ../../src/scss/sidr/_base.scss */
.sidr input[type="text"],
.sidr input[type="password"],
.sidr input[type="date"],
.sidr input[type="datetime"],
.sidr input[type="email"],
.sidr input[type="number"],
.sidr input[type="search"],
.sidr input[type="tel"],
.sidr input[type="time"],
.sidr input[type="url"],
.sidr textarea, .sidr select {
  width: 100%;
  font-size: 13px;
  padding: 5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 0 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.6);
  display: block;
  clear: both;
}
/* line 160, ../../src/scss/sidr/_base.scss */
.sidr input[type=checkbox] {
  width: auto;
  display: inline;
  clear: none;
}
/* line 167, ../../src/scss/sidr/_base.scss */
.sidr input[type=button],
.sidr input[type=submit] {
  color: #333333;
  background: white;
}
/* line 171, ../../src/scss/sidr/_base.scss */
.sidr input[type=button]:hover,
.sidr input[type=submit]:hover {
  background: rgba(255, 255, 255, 0.9);
}
