.jamaudio_international_all{
  .fullwidthbanner{
    img.desktop{
    }
    img.mobile{
      display:none;
    }
    img.desktop,
    img.mobile{
      max-width:100%;
      max-height:650px;
      width:auto;
    }
  }
}

@include breakpoint($bp_medium_legacy){
  .jamaudio_international_all{
    .fullwidthbanner{
      img.desktop{
        display:none;
      }
      img.mobile{
        display:block;
      }
      .slider-text.mobile-v-align{
        height:auto;
        position:absolute;
        top:35px;
        bottom:0;
        margin:auto;
      }
      .slider-text.mobile-h-align{
        position:absolute;
        left:0;
        right:0;
        margin:auto;
        width:100%;
        
        > *{
          text-align:center;
          width:100%;
          
          > span{
            display: block;
            text-align: center;
            width: auto;
            margin: 0 auto;
          }
        }
        
        > a.button{
          width:85px;
          display:block;
          margin:0 auto;
          max-width:40%;
          min-width:85px;
        }
      }
    }
  }
}
