/* ratings stars */
.reviews {
  .rating-box { 
    width:85px; 
    height:18px; 
    font-size:0; 
    line-height:0; 
    background:url(../images/star_sprite.svg) 0 100% repeat-x; 
    overflow:hidden; 
    margin-top: 5px; 
  }
  .rating-box .rating { 
    float:left; 
    height:18px; 
    background:url(../images/star_sprite.svg) 0 0 repeat-x; 
  }
}


/*reviews section*/
.reviews.row { 
  .reviews {
    
    .reviews-header { 
      background:#FFF;
      font-family:$font1_normal;
      text-transform:uppercase; 
      color:#000; 
      font-size:22px; 
      font-weight:500;
      display:block;
      border-bottom:3px solid #b5b6ba;
  
      
      .reviews-header-inner {
        max-width:960px;
        margin:0 auto;
        padding:20px;
        
        .ratings .rating-box {
          float: right;
          margin-right: 5px;
        }
        
        .reviews-header-title {
          width:48%;
          float:left;
          padding-left: 10px;
          
          a {
            background-color: #00ccff;
            text-shadow: none !important;     
            text-transform:uppercase;
            padding:10px 20px;    
            line-height:20px;   
          } 
        }
        .reviews-header-count {
          width:48%;
          float:left;          
          text-align:right;
          
          li.link a#review-link { display:none; visibility:hidden; }
        }  
        h3 {
          color:#000;
          text-transform:uppercase;
        }              
      }
    }
    .review-form {
      max-width:960px;
      margin:0 auto;      
    }
  }
  .box-collateral.box-reviews {
    .box-content {
      max-width:960px;
      margin:0 auto;
      
      .review-item {
        padding-top:30px;
        padding-bottom:1px solid #b5b6ba;-bottom:30px;
        display:block;
        border: none;
        
        .lft {
          font-family:$font1_normal;
          color:#000;
          width:30%;
          float:left; 
 
          .reviewer {  
            font-size:20px;
            text-transform:uppercase; 
            margin-bottom:5px;
          }
          
          .date {
            margin-bottom:5px;
          }
        }
        .rght {
          width:70%;
          float:left;
          
          h5 { 
            font-size:20px;
            font-family:$font1_normal;
            text-transform:uppercase;
            margin-bottom:5px; 
          }
        }
      }
      .see-reviews {
        padding:10px 0px;
        a {
            background-color: #00ccff;
            text-shadow: none !important;     
            text-transform:uppercase;
            padding:10px 20px;    
            line-height:20px;   
          } 
      }
      .see-less{
        padding:10px 0px;
        a {
            background-color: #00ccff;
            text-shadow: none !important;     
            text-transform:uppercase;
            padding:10px 20px;    
            line-height:20px;   
          } 
      }              
    }
    .no-reviews { text-align:center; padding: 15px 0px; margin-bottom:0px; }
  }
}

#inline_reviewform {
  
}

.catalog-product-view {
  #product-review-table {
    width:100%;
    
    .user-ratings {
      width:100%;
      float:none;
      
      label{
        font-weight:bold;
        display:block;
      }
      
      .rating {
        width:100px;
        float:left;
        
        .value {
          height:25px;
          padding-left:15px;
        }
        
        .rating-box {
          margin-top:0px;
          background:none;
        }        
      }
      input.radio { margin-left:5px; }
    }
    
  }
      
  .form-list {
    label {
      float:none;
      width:95%;
      text-align:left;
    }
    input.input-text {
      float:none;
      width:95%;
      text-align:left;                  
    }
    textarea {
      float:none;
      width:95%;
    }
  }
  
  .form-buttons { 
    width:100%;
    
    button.button {
      float:right;
      background-color: #00ccff;
      text-shadow: none !important;     
      text-transform:uppercase;
      padding:10px 20px;    
      line-height:12px; 
    }
  }

  .note {
    font-size:10px;
  }

  #recaptcha_widget_div {
    margin-bottom:0px;
  } 
  
}


@media screen and (max-width: $bp_large) {
  .reviews.row { 
    .reviews {
      .reviews-header { 
        padding:0px 30px;
      }      
      .review-form {
        max-width:100%;
        margin:0 auto;      
      }
    }
    .box-collateral.box-reviews {
      .box-content {
        max-width:100%;
        margin:0 auto; 
        padding:0px 30px;    
      }
    }
  }
}

@media screen and (max-width: $bp_medium) {
  .reviews.row { 
    .reviews {
      .reviews-header { 
        .reviews-header-title {
          h3 { font-size:22px; }
        }
        .reviews-header-inner {
          h3 { font-size:22px; }
        }
      }      
    }
    .box-collateral.box-reviews {
      .box-content {  
      }
    }
  }
}

@media screen and (max-width: $bp_small) {
  .reviews.row { 
    .reviews {
      #product-reviews-list {
        .rating-box {
          margin:0 auto;
        }         
      }
    }
    .box-collateral.box-reviews {
      .box-content {
        
        .review-item {
          
          .lft {
            width:100%;
            float:none; 
            text-align:center;
   
            .reviewer {  
              font-size:18px;
            }
            
            .date {
              margin-bottom:5px;
            }
          }
          .rght {
            width:100%;
            float:none;
            
            h5 { 
              font-size:18px;
              margin-top: 12px;
            }
          }
        }       
      }
      .no-reviews {  }
    }
  }
}