.lets_get_social {
  text-align: center;
  max-width: 100%;
  margin-top: 0;
  margin-bottom: 40px;
}

.lets_get_social h2 {
  margin-bottom: 1em;
  margin-top: 1em;
}

.form-subscribe button.button{
  float: left;
}

.footer-container{
  padding-bottom: 0;

  .footer{
    padding: 30px 0rem 0px 0rem;
    background-image: none;

    .form-subscribe label {
      font-family: $font1_bold;
    }
    .footer-nav h3 {
      font-family: $font1_bold;
    }

    .footer-links1, .footer-links2, .footer-links3{
      text-align: left;
      width: 33%;
    }

    .footer-top{
      max-width: 1319px;
      width: 100%;
      display: inline-block;
      box-sizing: border-box;
      padding-right: 10px;
      padding-left: 10px;
      text-align: center;
      .footer-content{
        display: inline-block;
        float: right;
        width: 40%;
        vertical-align: top;
      }
      .footer-nav{
        display: inline-block;
        float: right;
        width: 40%;
        vertical-align: top;

      }
      .footer-social{
        display: inline-block;
        float: right;
        width: 19%;
        vertical-align: top;
      }

    }



    .footer-bottom{
      width: 100%;
      display: inline-block;
      font-weight: bold;
      background-color: $jam_lightgray;
      .footer-bottom-content{
        max-width: 1319px;
        width: 100%;
        display: inline-block;
        padding-top:2em;
        padding-bottom:2em;
        .phone{
          float: left;
          font-size: .8rem;
          border-right: 1px solid #999999;
          margin-right: 1em;
          padding-right: 1em;
          padding-left: 10px;
          font-weight: bold;
          color: #333;
          a{
            font-weight: bold;
            color: #0099cc;
            &:hover {
              color: #00BFFF;
            }
          }
        }
        .retailer-link{
          float: left;
          font-size: .8rem;
          a{
            font-weight: bold;
            color: #0099cc;
            &:hover {
              color: #00BFFF;
            }
          }
        }
        .copyright{
          font-size: .8rem;
          font-weight: bold;
          color: #333;
          background-image: url($jam-icon-img);
          background-position: right center;
          background-repeat: no-repeat;
          padding-right: 3.5rem;
        }

      }
    }

  }
}


@media screen and (max-width: $bp_largest){
  .footer-container {
    .footer {
      .footer-top {
        .footer-content{
          width: 40%;
        }
        .footer-nav{
          width: 49%;
        }
        .footer-social{
          width: 10%;
        }
      }
    }
  }
}


@media screen and (max-width: $bp_large){
  .footer-container {
    .footer {
      .footer-top {
        .footer-content{
          width: 100%;
          float: none;
          padding-bottom: 20px;
          form{
            display:inline-block;
            padding-top: 0px;
          }

        }
        .footer-nav{
          width: 70%;
          float: left;
          padding-bottom: 20px;
        }
        .footer-social{
          width: 29%;
          float: right;
          padding-bottom: 20px;
        }
      }
    }
  }
}



@media screen and (max-width: $bp_medium_legacy){
  .footer .footer-links3 {
    clear: none;
  }
}


@media screen and (max-width: $bp_medium){

  .form-subscribe{
    .v-fix {
      width: 100%;
    }
    button.button {
      width: 100%;
      float: none;
    }
  }

  .page .footer-container{
    padding-bottom:0px;
  }

  .footer-container{
    width: 100%;
    padding: 0px;
    .footer{
      .footer-links1, .footer-links2, .footer-links3{
        text-align: center;
      }
      .footer-top {
        .footer-content{
          width: 100%;
        }
        .footer-nav{
          width: 100%;
          float: none;
        }
        .footer-social{
          width: 100%;
        }
      }
      .footer-bottom{
        margin-top:20px;
        .footer-bottom-content{
          .phone{
            float: none;
            display: block;
            border-right: 0px solid #999999;
            border-bottom: 1px solid #999999;
            width: 100%;
            padding-bottom: 20px;
          }
          .retailer-link{
            padding-top: 20px;
            float: none;
            display: block;
            width: 100%;
          }
          .copyright {
            background-position: center bottom;
            padding-right: 0px;
            padding-bottom: 2.5rem;
          }
          address.copyright{
            text-align: center;
            padding-top: 20px;
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $bp_small) {
  .footer-container .footer .footer-top {
    .footer-nav {
      padding-bottom: 0px;
      .footer-links1, .footer-links2, .footer-links3 {
        width: 100%;
      }
    }
    .footer-content .form-subscribe button.button {
      margin: 0;
      margin-top: 10px;
    }

  }

}
