$tile-div-pad:4px;

.home-cms-bar{
  display:block;position:relative;@include borderbox();background-color:#000;font-family:Oswald-Light;padding:3vw 1rem;text-align:center;
  
  .container{
    display:block;@include borderbox();width:100%;
    
    > div{
      display:block;@include borderbox();color:#fff;
      
      h3{
        display:block;@include borderbox();color:#fff;font-family:Oswald-Light;margin:0;padding:0;font-size:2.4vw;
        
        a.more, a.more:visited{
          @include transition(color .3s linear);color:#fff;font-family:Oswald-Light;font-size:1.8vw;line-height:1.8vw;vertical-align:top;text-decoration:none;position:relative;top:.6vw;margin-left:8px;display:inline-block;
          
          &:before{
            @include transition(background-color .3s linear);content:"";display:block;position:absolute;top:100%;left:0;width:100%;height:2px;background-color:#fff;
            
            &:hover{
              background-color:#00caee;
            }
          }
          
          &:after{
            content:" > ";display:inline-block;vertical-align:top;padding-left:3px;font-size:90%;
          }
          
          &:hover{
            text-decoration:none;color:#00caee;
          }
        }
        a.more:hover{
          &:before{
            background-color:#00caee;
          }
        }
      }
    }
  }
}

/*home cms columns below the banner*/
.home-cms-columns{
  
  @include borderbox;border:solid $tile-div-pad #fff;border-left:0;border-right:0;
   
  > .container{

    font-size:0;box-sizing:inherit;width: 100%;
    
    > div{
      
      display:inline-block;font-size:1rem;width:33.3333333%;box-sizing:inherit;text-align:center;position:relative;position:relative;vertical-align:top;
      
      &:after{
        content:"";display:block;position:absolute;right:-$tile-div-pad;top:0;height:100%;width:$tile-div-pad;z-index:3;background-color:#fff;
      }
      
      &:before{display:none;}
      
      &:last-child:after{
        display:none;
      }
      
      > * {
        z-index:1;
      }
      
      > a{
        display:block;box-sizing:inherit;text-align:center;
        
        &:hover{
          
          .line1,.line2{
            color: #fff;
          }
          
        }
      }
      
      img{
        width:100%;box-sizing:inherit;display:inline-block;
      }
      
      .text{
        position:absolute;right:0;top:0;bottom:0;margin:auto;display:block;width:49%;height: 47%;text-align:left;font-size: 100%;padding-right: 1vw;@include borderbox();
        
        &.left{
          right:auto;left:2vw;padding-right:0;
        }
        
        .line1,.line2{
          @include transition(color .3s linear);
        }
        
        .line1{
          font-size:3vw;letter-spacing: 2px;line-height:100%;display:block;font-family:Oswald;color: #fff;text-transform: uppercase;
        }
        .line2{
          display:block;line-height: 130%;color: #fff;font-family: Oswald-Light;text-transform: uppercase;font-size: 1.3vw;letter-spacing: 1px;margin-top: .3rem;
        }
        .button{
          display: inline-block;
          line-height: 100%;
          font-size: 1.1vw;
          letter-spacing: 1px;
          background-color: $jam_lightblue;
          padding:1vw 1.4vw;
          height: auto;
          font-family: Oswald-Light;
          text-transform: uppercase;
          text-shadow: none !important;
          margin-top:.9vw;
          border-radius: 0;
        }
      }
    }

  }
}

/*no button - text falls below the image squares*/
.home-cms-columns.stand-alone-text{
  > .container{
     > div{
       
        a, a:hover{
          text-decoration:none !important;
        }
        .text{
          position:static;left:0;width:100%;height:auto;text-align:center;padding-right:0;padding-left:0;padding-bottom:1.7rem;padding-top:1.7rem;
          
          .line1{color:#989898;font-size:1.3rem;font-family:Oswald-Light;background-color:transparent;text-decoration:none !important;}
          .line2{color:#333;font-size:1.5rem;font-family:Oswald-Light;letter-spacing:0;background-color:transparent;text-decoration:none !important;}
          .button{display:none;}
        }
     }
  }
}

/*media queries*/
@include breakpoint($bp_large){
  .home-cms-bar{
    .container{
      > div{
        h3{
          a.more, a.more:visited{
            &:before{
              height:1px;
            }
          }
        }
      }
    }
  }
  /*no button - text falls below the image squares*/
  .home-cms-columns.stand-alone-text{
    > .container{
       > div{
          .text{
            .line1{font-size:2.2vw;}
            .line2{font-size:2.5vw;}
          }
       }
    }
  }
}
@include breakpoint($bp_medium){
  .home-cms-bar{
    .container{
      > div{
        h3{
          font-size:3.7vw;
          
          a.more, a.more:visited{
            font-size:3.1vw;line-height:3.1vw;margin-left:5px;
          }
        }
      }
    }
  }
  
  .home-cms-columns{
    border-top:none;
    
    > .container{
      > div{
        width:100%;border-bottom:solid $tile-div-pad #fff;
        
        &:first-child{
          border-top:solid $tile-div-pad #fff;
        }
        
        &:after{
          display:none;
        }
      
        .text{
          .line1{
            font-size:8.5vw;
          }
          .line2{
            font-size: 4vw;
          }
          .button{
            font-size: 3vw !important;padding:2vw 3vw;margin-top:2vw;
          }
        }
      }
    }
  }
  
  /*no button - text falls below the image squares*/
  .home-cms-columns.stand-alone-text{
    > .container{
       > div{
          .text{
            .line1{font-size:1.3rem;}
            .line2{font-size:1.5rem;}
          }
       }
    }
  }
}

@include breakpoint($bp_medium_legacy){
  .home-cms-columns{
    border-bottom:none;
    
    > .container{
      > div{
        .text{
          &.left{
            left:4vw;
          }
        }
      }
    }
  }
}
@include breakpoint($bp_small){
  .home-cms-bar{
    .container{
      > div{
        h3{
          font-size:4.2vw;
          
          a.more, a.more:visited{
            font-size:3.6vw;line-height:3.6vw;margin-left:5px;
          }
        }
      }
    }
  }
  /*no button - text falls below the image squares*/
  .home-cms-columns.stand-alone-text{
    > .container{
       > div{
          .text{
            .line1{font-size:4.2vw;}
            .line2{font-size:4.5vw;}
          }
       }
    }
  }
}
