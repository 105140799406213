#qty-input-product{
  display:inline-block;
  white-space:nowrap;
  float:none;
  width: 49%;
  text-align: right;
  
  .up-btn,.down-btn{
    display:inline-block;
    background-position:center;
    background-repeat:no-repeat;
    background-size:100%;
    vertical-align: middle;
    width:21px;
    height:21px;
    cursor:pointer;
    
    @include noselect();
    
    @include transition(opacity .2s linear);
    
    &:hover{
      opacity:.6;
    }
  }
  .up-btn{
    background-image:url(../images/icon_plus.svg);
  }
  .down-btn{
    background-image:url(../images/icon_minus.svg);
  }
  input.qty{
      text-align: center;
      font-family: Oswald;
      font-size: .8rem;
  }
}
