.catalog-product-view {
  .product-img-box .more-views{
    position:relative;
    text-align:center;
    
    @include noselect();
    
    .thumb-slider-wrap{
      display:inline-block;
      overflow: hidden;
      position:relative;
      
        &.swipe{
          cursor:grabbing;
        }
      
      > *{
        display:block;
        white-space:nowrap;
        width: auto;
        max-width: 100% !important;
        min-width: 100% !important;
        left:0;
        top:0;
        bottom:0;
        margin:auto;
        position:relative;
        
        @include transition(left .3s linear);
        
        > *{
          display:inline-block;
          float:none;
          margin: 0;
          padding: 0 3px;
          box-sizing:border-box;
          -moz-box-sizing:border-box;
        }
      }
    }
    
    .slide-l-btn,.slide-r-btn{
      position:absolute;
      display:block;
      top:0;
      bottom:0;
      margin:auto;
      background-repeat:no-repeat;
      background-position:center;
      background-size:16px 36px;
      width:30px;
      height:100%;
      cursor:pointer;
      
      @include noselect();
      
      @include transition(opacity .3s linear);
      
      &:hover{
        opacity:.6;
      }
    }
    .slide-l-btn{
      left:0;
      background-image:url(../images/left_arrow.svg);
    }
    .slide-r-btn{
      right:0;
      background-image:url(../images/right_arrow.svg);
    }
  }
  .product-img-box .more-views.no-slide{
    .slide-l-btn,.slide-r-btn{
      display:none;
    }
  }
  .product-img-box .more-views.at-last{
    .slide-r-btn{
      display:none;
    }
  }
  .product-img-box .more-views.at-first{
    .slide-l-btn{
      display:none;
    }
  }
}