.video {
  .overlay-container { 
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    color: #DFDFDF;
    text-transform: uppercase;
    font-size: 6.5vw;
    font-family: Oswald;
    line-height: 8vw;
    letter-spacing: 5px;
    padding-top: 10%;
    .overlay-text {
      span {
        letter-spacing:1px;
        clear:both;
        font-weight:bold;
      }     
    }
  }
  .video-play { 
  } 
  video {
    
  }
.video-controls { 
  text-align: right;
  position: absolute;
  bottom: 25px;
  z-index: 2500;
  right: 15px;

  $buttcolor: rgba(0,0,0,0.8);
  $buttcolor-dark: darken($buttcolor, 25%);
  $size: 4em;
  
  menu {
    margin: 0em auto;
    position: relative;
  }
 
    button {
      position: relative;
      display: inline-block;
      padding: .6em;
      // background: linear-gradient(#fff, #ccc), #c9c5c9;
      background:rgba(0,202,238,0.8);
      border-radius: 10em;
      border: 0 solid rgba(208,208,208,1.000);
      width: $size;
      height: $size;
      margin-right: 5px;
      box-shadow: 0 ($size * .06) ($size * .07) rgba(0,0,0,.4);
    }
    
    button:hover {
        // background: linear-gradient(#f5f5f5, #b9b9b9), #c9c5c9 ;
        background:$jam_lightblue; 
    }
    
    button:before, button:after {
      position: absolute;
      content: " ";
      
    }
      
    button:active {
      top: ($size * .05);
      box-shadow: 0 ($size * .02) ($size * .03) rgba(0,0,0,.4);
    }
    
    /* Play */
    .play:before {
      z-index: 2;
      top: $size * .22;
      left: $size * .36;
      width: 0;
      height: 0;
      border: $size * .3 solid transparent;
        border-left-color: $buttcolor;
      border-left-width: ($size * .45);
    }
    .play:hover:before {
      border-left-color: $buttcolor-dark;
    }
    
    /* Pause */
    .pause:before, .pause:after {
      height: ($size * .47);
      width: ($size * .19);
      left: ($size * .29);
      top: ($size * .28);
      display: block;
      background-color: #FFF;
    }
    
    .pause:after {
     left: ($size * .54);
    }
      
    .pause:hover:before, .pause:hover:after {
        background-color: $buttcolor-dark;
      }
    
  } 
}
.video{
  position:relative;
  
.video-play { 

  $overlaybuttcolor: rgba(0,0,0,0.8);
  $overlaybuttcolor-dark: darken($overlaybuttcolor, 25%);
  
  @media screen and (min-width: $bp_medium_legacy) {
    $overlaysize: 10em;
    button {
      display: inline-block;
      padding: .6em;
      background: rgba(255,255,255,.8);
      border-radius: 10em;
      border: 0 solid rgba(208,208,208,1.000);
      width: $overlaysize;
      height: $overlaysize;
      margin-right: 5px;
      box-shadow: 0 ($overlaysize * .06) ($overlaysize * .07) rgba(0,0,0,.4);
    }
    
    button:hover {
        background:$jam_lightblue;
    }
    
    button:before, button:after {
      position: absolute;
      content: " ";
      
    }
      
    button:active {
      top: ($overlaysize * .05);
      box-shadow: 0 ($overlaysize * .02) ($overlaysize * .03) rgba(0,0,0,.4);
    }
    
    /* Play */
    .play:before {
      z-index: 2;
      top: $overlaysize * .22;
      left: $overlaysize * .36;
      width: 0;
      height: 0;
      border: $overlaysize * .3 solid transparent;
      border-left-color: $overlaybuttcolor;
      border-left-width: ($overlaysize * .45);
    }
    .play:hover:before {
      border-left-color: $overlaybuttcolor-dark;
    }
    
    /* Pause */
    .pause:before, .pause:after {
      height: ($overlaysize * .47);
      width: ($overlaysize * .19);
      left: ($overlaysize * .29);
      top: ($overlaysize * .28);
      display: block;
      background-color: $overlaybuttcolor;
    }
    
    .pause:after {
     left: ($overlaysize * .54);
    }
      
    .pause:hover:before, .pause:hover:after {
        background-color: $overlaybuttcolor-dark;
      }    
  }
  
  @media screen and (max-width: $bp_medium_legacy) {
    $overlaysize: 5em;
    button {
      display: inline-block;
      padding: .6em;
      background:rgba(255,255,255,.8);
      border-radius: 10em;
      border: 0 solid rgba(208,208,208,1.000);
      width: $overlaysize;
      height: $overlaysize;
      margin-right: 5px;
      box-shadow: 0 ($overlaysize * .06) ($overlaysize * .07) rgba(0,0,0,.4);
    }
    
    button:hover {
        background:$jam_lightblue;
        cursor:pointer;
    }
    
    button:before, button:after {
      position: absolute;
      content: " ";
      
    }
      
    button:active {
      top: ($overlaysize * .05);
      box-shadow: 0 ($overlaysize * .02) ($overlaysize * .03) rgba(0,0,0,.4);
    }
    
    /* Play */
    .play:before {
      z-index: 2;
      top: $overlaysize * .22;
      left: $overlaysize * .36;
      width: 0;
      height: 0;
      border: $overlaysize * .3 solid transparent;
      border-left-color: $overlaybuttcolor;
      border-left-width: ($overlaysize * .45);
    }
    .play:hover:before {
      border-left-color: $overlaybuttcolor-dark;
    }
    
    /* Pause */
    .pause:before, .pause:after {
      height: ($overlaysize * .47);
      width: ($overlaysize * .19);
      left: ($overlaysize * .29);
      top: ($overlaysize * .28);
      display: block;
      background-color: $overlaybuttcolor;
    }
    
    .pause:after {
     left: ($overlaysize * .54);
    }
      
    .pause:hover:before, .pause:hover:after {
        background-color: $overlaybuttcolor-dark;
      }    
  }
  
   left:50%;
   top:60%;
   -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
   transform: translate(-50%, -50%);
   position:absolute;
   z-index:50;

 
    
    
}
}

// Hide iPhone HTML5 video play button
// http://stackoverflow.com/questions/8911247/hide-iphone-html5-video-play-button
*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

@include breakpoint($bp_small){
  .video {
    .overlay-container {
      font-size: 5vw;
      padding-top: 5%;
    }
  }    
}
