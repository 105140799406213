.main{
  a.button, button.button{
    background-color:$jam_lightblue;
    background-image:none;
    @include transition(background-color .3s linear);
    line-height:100%;
    padding:0 1rem;
    border-radius: 0;
    
    &:hover{
      background-color:#00A8C7;
    }
    
    > span{
      background-color:transparent;
      height:auto;
      padding:0;
      line-height:100%;
      vertical-align:middle;
      
      > span{
        background-color:transparent;
        height:auto;
        padding:0;
        line-height:100%;
        text-shadow: none;
        font-family: Oswald-Light;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        vertical-align: middle;
      }
    }
  }
}