.category_banner{
  width:100%;
  text-align:center;
  
  .img{
    display: inline-block;
    width: 100%;
    max-width: 2000px;
    position:relative;
    
    img{
      width:100%;
    }
    
    img.desktop{
      min-width:740px;
    }
    img.mobile{
      display:none;
    }
    
    .text{
      position:absolute;
      left:0;
      top:0;
      bottom:0;
      right:0;
      margin:auto;
      height:40%;
      padding: 0 1rem;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      
      .line1,.line2{
        display:block;
        text-transform:uppercase;
      }
      .line1{
        font-family:$font1-bold;
        font-size: 6.5vw;
        line-height:110%;
      }
      .line2{
        font-family:$font1-light;
        font-size: 3.3vw;
        
        strong{font-family:$font1-normal;}
      }
    }
    .text.white{
      .line1,.line2{color:#fff;}
      .line1{}
      .line2{}
    }
    .text,.text.left{
      width:100%;
      max-width:1319px;
      text-align:left;
    }
    .text.right{}
  }
}

@include breakpoint($bp_medium_legacy){
  .category_banner{
    .img{
      img.desktop{
        display:none;
      }
      img.mobile{
        display:block;
      }
      .text.mobile-v-align{
        height:36%;
        position:absolute;
        top:0;
        bottom:0;
        margin:auto; 
      }
      .text.mobile-h-align{
        text-align:center;
      }
    }  
  }
}

@include breakpoint($bp_small){
  .category_banner{
    .img{
      
      img{
      }
      
      .text{
        bottom:auto;
        margin:0;
        padding-top:1rem;

        .line1,.line2{
        }
        .line1{
          font-size: 10vw;
        }
        .line2{
          font-size: 4.8vw;
        }
      }
      .text.left{
      }
      .text.right{
      }
    }
  }
}
